import { normRouteName } from '~~/utils/utils'

export const NAVIGATION = {
  SET_NEXT_ROUTE: 'SET_NEXT_ROUTE',
  CLEAR_NEXT_ROUTE: 'CLEAR_NEXT_ROUTE',
  SET_LAST_LISTING_PAGE: 'SET_LAST_LISTING_PAGE',
  SET_LAST_VISITED_PAGE: 'SET_LAST_VISITED_PAGE'
}

export const state = () => ({
  nextRoute: null,
  lastVisitedPage: {},
  lastListingPage: 'rubies'
})

export const getters = {
  lastListingPage: (store) => store.lastListingPage,
  lastVisitedPage: (store) => store.lastVisitedPage
}

export const actions = {
  setNextRoute({ commit }, route) {
    commit(NAVIGATION.SET_NEXT_ROUTE, route)
  },

  clearNextRoute({ commit }) {
    commit(NAVIGATION.CLEAR_NEXT_ROUTE)
  },

  setLastListingPage({ commit }, route) {
    const { name, params } = route
    commit(NAVIGATION.SET_LAST_LISTING_PAGE, {
      name: normRouteName(name),
      params
    })
  },

  setLastVisitedPage({ commit }, params) {
    commit(NAVIGATION.SET_LAST_VISITED_PAGE, params)
  }
}

export const mutations = {
  [NAVIGATION.SET_NEXT_ROUTE](state, route) {
    state.nextRoute = route
  },

  [NAVIGATION.CLEAR_NEXT_ROUTE](state) {
    state.nextRoute = null
  },

  [NAVIGATION.SET_LAST_LISTING_PAGE](state, path) {
    state.lastListingPage = path
  },

  [NAVIGATION.SET_LAST_VISITED_PAGE](state, path) {
    // array of route names
    const pagesForbiddenToRedirect = ['account-reset-password-token']
    if (path.path && path.path.name) {
      if (pagesForbiddenToRedirect.includes(normRouteName(path.path.name))) {
        state.lastVisitedPage = {
          isDetails: false,
          path: {
            name: 'index'
          }
        }
        return
      }
    }
    state.lastVisitedPage = path
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
