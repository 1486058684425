export const lists = {
  stonesListItems: [],
  stonePairsListItems: [],
  braceletsListItems: [],
  earringsListItems: [],
  necklacesPendantsListItems: [],
  ringsListItems: [],
  broochesListItems: [],
  cufflinksListItems: [],
  weddingBandsItems: [],
  weddingBandsPlainItems: [],
  ringSettingsItems: [],
  earringSettingsItems: [],
  braceletSettingsItems: [],
  pendantSettingsItems: [],
  necklaceSettingsItems: [],
  customItems: []
}

export default {
  _namespace: '',
  shareHash: '',
  fetchingItems: false,
  /**
   * param: sharedItemsFetched
   * if list items loading from shared link with hash code. (related to rejoiner)
   * By default we don't need to fetch items, and use only items added to list by user
   * But if on reload page we noticed "p" parameter, this means we should refetch items from the server
   */
  sharedItemsFetched: true,
  ...lists
}
