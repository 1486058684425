import Vue from 'vue'
import round from 'lodash/round'
import mutations from '../_base/mutations'
import { lists } from '../_base/state'
import LIST from './constants'
import { getLocalPrice } from '~~/utils/utils'
import { productListTypeByCategory } from '~~/utils/definitions/defaults'
import { capitalize } from '~~/utils/formatters'
export default {
  ...mutations,

  [LIST.UPDATE_CARD_PRICE](state, { card, amount, totalPrice }) {
    const cardIndex = state.allCreditCards.findIndex(
      (c) => c.cardNumber === card.cardNumber
    )

    if (cardIndex > -1) {
      if (amount > totalPrice) {
        state.allCreditCards[cardIndex].splitSum = totalPrice
        state.allCreditCards[1 - cardIndex].splitSum = 0
        return
      }
      state.allCreditCards[cardIndex].splitSum = round(amount, 2)
      if (state.allCreditCards.length <= 1 - cardIndex) return
      state.allCreditCards[1 - cardIndex].splitSum = round(
        totalPrice - amount,
        2
      )
    }
  },

  [LIST.SET_SHIPPING_BILLING_ADDRESS](state, countryCode) {
    const { shippingCountryId } = state.addressForm
    if (shippingCountryId !== countryCode) {
      state.addressForm.shippingCountryId = countryCode
      state.addressForm.billingCountryId = countryCode
      state.addressForm.shippingState = ''
      state.addressForm.billingState = ''
    }
  },

  [LIST.SET_TAX](state, data) {
    state.tax = data.tax?.amount_to_collect || 0
  },

  [LIST.REMOVE_TAX](state) {
    state.tax = 0
    state.taxHash = ''
  },

  [LIST.BACKEND_TAX_CALC_MODE](state, mode) {
    state.backendTaxCalcMode = mode
  },

  [LIST.SET_TAX_HASH](state, hash) {
    state.taxHash = hash
  },

  [LIST.FLUSH_CART](state) {
    Object.keys(lists).forEach((key) => {
      state[key] = []
    })
  },

  [LIST.ADD_EXTEND](state, { item, plan }) {
    const listType = productListTypeByCategory[item.category]
    const index = state[`${listType}Items`].findIndex(
      (i) => i.guid === item.guid
    )
    if (index > -1) {
      Vue.set(state[`${listType}Items`][index], 'extendPlan', plan)
      // state[`${listType}Items`][index].extendPlan = normalizeExtendPlan(plan)
    }
  },

  [LIST.REMOVE_EXTEND](state, { item }) {
    const listType = productListTypeByCategory[item.category]
    const index = state[`${listType}Items`].findIndex(
      (i) => i.guid === item.guid
    )
    if (index > -1) {
      state[`${listType}Items`][index].extendPlan = null
    }
  },

  [LIST.CLEAR_SHIPPING_RATES](state) {
    state.metaData.shippingRates = null
  },

  [LIST.SET_DOCUMENTATION_TYPE](state, type) {
    state.paymentForm.docType = type
  },

  [LIST.SET_DIRTY](state, value) {
    state.isDirty = value
  },

  [LIST.CONFIRM_CONVERSION](state) {
    state.conversionConfirmed = true
  },

  [LIST.SET_FORM_VALUE](state, data) {
    Object.keys(data).forEach((key) => {
      state.addressForm[key] = data[key]
    })
  },

  [LIST.SET_PAYMENT_FORM_VALUE](state, data) {
    Object.keys(data).forEach((key) => {
      state.paymentForm[key] = data[key]
    })
  },

  [LIST.SHOW_EXPANDED_CART](state, bool) {
    state.showExpandedCart = bool
  },

  [LIST.FETCH_CHECKOUT](state, bool) {
    state.fetchCheckout = bool
  },

  [LIST.SET_ORDER_CONFIRMED_STATE](state, bool) {
    state.isOrderConfirmed = bool
  },

  [LIST.SET_ORDER_RECEIVED](state, bool) {
    state.isOrderReceived = bool
  },

  [LIST.RESET_CARD](state) {
    Object.keys(state.paymentForm).forEach((field) => {
      if (['paymentOptionId', 'docType'].includes(field)) return
      state.paymentForm[field] = ''
    })
    state.backendTaxCalcMode = false
    state.taxHash = ''
  },

  [LIST.SET_META_DATA](state, metaData) {
    state.metaData = metaData
  },

  [LIST.UPDATE_ADDRESS_FORM_VALUE](state, { formType, formData }) {
    Object.keys(formData).forEach((key) => {
      if (formType === 'shipping' && key === 'phone') {
        state.addressForm.phone = formData[key]
        return
      }
      state.addressForm[`${formType}${capitalize(key)}`] = formData[key]
    })
  },

  [LIST.UPDATE_FORM_VALUE](state, formData) {
    state[formData.formName][formData.fieldName] = formData.value
  },

  [LIST.ORDER_SUCCESS](state, orderSummary) {
    state.orderSummary = orderSummary
  },

  [LIST.SET_SHIPPING](state, { id, subTotal }) {
    let rates =
      state.addressForm.shippingCountryId === 'US'
        ? state.metaData.shippingRates.filter((i) => i.domestic)
        : state.metaData.shippingRates.filter((i) => !i.domestic)

    rates = rates.filter(
      (i) =>
        (i.maxOrderTotal >= subTotal || i.maxOrderTotal === null) &&
        i.minOrderTotal <= subTotal
    )

    const shippingPriceObj = rates.find((r) => r.id === id)

    if (shippingPriceObj) {
      const shippingPrice = shippingPriceObj.price

      state.shipping = shippingPrice

      const { localRate, usdRate } = this.state.app.localCurrency
      state.shippingLocal = getLocalPrice(shippingPrice, usdRate, localRate)
    }
  },

  [LIST.CLEAR_ALL_CREDIT_CARDS](state) {
    state.allCreditCards = []
  },

  [LIST.ADD_REPLACE_EDITED_CREDIT_CARD](state, { card, totalPrice }) {
    const removeSpacesFromCardNumber = (number) => number.replace(/\s/g, '')

    card.cardNumber = removeSpacesFromCardNumber(card.cardNumber)

    const findCardByNumber = (cards, number) =>
      cards.findIndex((c) => c.cardNumber === number)

    const findCardById = (cards, id) => cards.findIndex((c) => c.id === id)

    const setSplitSumForTwoCards = (cards, total) => {
      if (cards.length === 2) {
        cards[0].splitSum = total
        cards[1].splitSum = 0
      }
    }

    // Find the index of the existing card by number and ID
    const existingCardNumberIndex = findCardByNumber(
      state.allCreditCards,
      card.cardNumber
    )
    const existingCardIdIndex = findCardById(state.allCreditCards, card.id)

    // If a card with the same number already exists
    if (existingCardNumberIndex >= 0) {
      // Find another card with the same number but different ID
      const otherCardWithSameNumber = state.allCreditCards.find(
        (c) => c.cardNumber === card.cardNumber && c.id !== card.id
      )
      const otherCardIndex = state.allCreditCards.indexOf(
        otherCardWithSameNumber
      )

      // Find the index of a double card (same number and ID)
      const doubleCardIndex = state.allCreditCards.findIndex(
        (c) => c.id === card.id && c.cardNumber === card.cardNumber
      )

      // If we found another card with the same number and different ID, replace it
      if (otherCardIndex !== -1 && otherCardIndex !== existingCardNumberIndex) {
        state.allCreditCards.splice(otherCardIndex, 1, card)
        setSplitSumForTwoCards(state.allCreditCards, totalPrice)
      } else if (
        (otherCardIndex < 0 || otherCardIndex === undefined) &&
        doubleCardIndex !== -1
      ) {
        // If there is no other card with the same number, and a double card is found, update it
        card.splitSum = totalPrice
        state.allCreditCards.splice(doubleCardIndex, 1, card)
        setSplitSumForTwoCards(state.allCreditCards, totalPrice) // ?
      } else {
        // Update splitSum and replace the card in the array
        card.splitSum = totalPrice
        state.allCreditCards.splice(existingCardNumberIndex, 1, card)
        // Remove the second card as they have the same number
        if (state.allCreditCards.length > 1) {
          state.allCreditCards.splice(existingCardIdIndex, 1)
        }
      }
    } else if (existingCardIdIndex >= 0) {
      // If cards have the same ID, replace the card by ID
      state.allCreditCards.splice(existingCardIdIndex, 1, card)
      setSplitSumForTwoCards(state.allCreditCards, totalPrice)
    } else {
      // If there is no card with the same number or ID, create a new card with the correct splitSum and add it to the array
      const newCard = {
        ...card,
        splitSum: state.allCreditCards.length === 1 ? 0 : totalPrice
      }
      state.allCreditCards.push(newCard)

      // Update splitSum for the two cards in the array based on the total amount
      setSplitSumForTwoCards(state.allCreditCards, totalPrice)
    }
  },

  [LIST.CREDIT_CARD_ADD_BTN_STATE_CHANGE](state, value) {
    state.activeBtnConfirm = value
  },

  [LIST.DELETE_CREDIT_CARD](state, { card, totalPrice }) {
    const index = state.allCreditCards.findIndex(
      (i) => i.cardNumber === card.cardNumber
    )
    if (index > -1) {
      state.allCreditCards.splice(index, 1)
      state.allCreditCards[0].splitSum = totalPrice
    }
  }
}
