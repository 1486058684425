import cloneDeep from 'lodash/cloneDeep'
import actions from '../_base/actions'

export default {
  ...cloneDeep(actions),

  skipFetch({ state, getters, rootGetters }, params) {
    if (params.force) return false
    if (rootGetters['filters/dirtyLists']) return false
    if (!params.pageNumber) return true // 0 or null
    if (state.loadingPages.includes(params.pageNumber)) return true // page already in loading process
    return !!getters.loadedPages.includes(params.pageNumber) // page already loaded
  }
}
