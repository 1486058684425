import * as stonesData from '../../projects/gemstone/static/system_stone_data.json'
import * as stonesDataDev from '../../projects/gemstone/static/system_stone_data_dev.json'

export const stones =
  (process.env.isDev ? stonesDataDev.stoneTypes : stonesData.stoneTypes) || []
export const colors =
  (process.env.isDev ? stonesDataDev.stoneColors : stonesData.stoneColors) || []
export const stonesFetchDate = process.env.isDev
  ? stonesDataDev.date
  : stonesData.date
