import get from 'lodash/get'
import { calculateTotalWeight } from '../sitemapUtils'
import { caratFormatter, tcwFormatter } from './index'

const itemsNameMap = {
  Stone: stoneNameFormatter,
  Pair: pairNameFormatter,
  Ring: jewelryNameFormatter,
  Earring: jewelryNameFormatter,
  Pendant: jewelryNameFormatter,
  Necklace: jewelryNameFormatter,
  Bracelet: jewelryNameFormatter,
  Bangle: jewelryNameFormatter,
  Brooch: jewelryNameFormatter,
  Cufflink: jewelryNameFormatter,
  'Wedding Band': bandsNameFormatter,
  'Plain Band': bandsNameFormatter,
  Setting_Ring: settingsNameFormatter,
  Setting_Bracelet: settingsNameFormatter,
  Setting_Earring: settingsNameFormatter,
  Setting_Necklace: settingsNameFormatter,
  Setting_Pendant: settingsNameFormatter,
  Custom: customNameFormatter,
  Wax: waxNameFormatter
}

const stone =
  process.env.siteName.charAt(0).toUpperCase() + process.env.siteName.slice(1)

export default function itemNameFormatter(item, removeHtmlTags = false) {
  if (item.title) return item.title
  const func = itemsNameMap[item.category]
  if (!func) return 'Jewelry'
  if (removeHtmlTags)
    return func
      .call(this, item)
      .replace(/<[^>]*>?/gm, '')
      .replace('Total Carat Weight', '')
  return func.call(this, item)
}

function stoneNameFormatter(item) {
  // const weight = caratFormatter(item.weight)
  const stoneType = item.stoneSubType || item.stoneType
  const weight = caratFormatter(calculateTotalWeight(item))
  const parts = [weight, stoneType]

  if (item.origin !== 'Unknown') {
    parts.push('from', item.origin)
  }

  return parts.filter(Boolean).join(' ')
}

function pairNameFormatter(item) {
  const weight = tcwFormatter.call(this, calculateTotalWeight(item))
  const stoneType =
    item.stoneSubType ||
    item.stoneType ||
    get(item, 'stones[0].stoneSubType', '') ||
    get(item, 'stones[0].stoneType', '')
  const origin = item.origin || get(item, 'stones[0].origin', '')

  const parts = [weight, stoneType, 'Pair']

  if (origin !== 'Unknown') {
    parts.push('from', origin)
  }

  return parts.filter(Boolean).join(' ')
}

function bandsNameFormatter(item) {
  let style = getStyle(item)
  if (style === 'none') style = ''
  const metalName = item.metalName
  const category = item.category
  const stones = item.sideStoneOptions
    ? item.sideStoneOptions
        .map((s) => s.stoneType)
        .reverse()
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(' and ')
    : ''

  return [metalName, stones, style, category].filter(Boolean).join(' ')
}

function settingsNameFormatter(item) {
  const category = item.category.replace('Setting_', '')
  const metalName = item.metalName
  const style = getStyle(item)

  return [metalName, style, category].filter(Boolean).join(' ')
}

function waxNameFormatter(item) {
  const category = item.customSetting.category.replace('Setting_', '')

  return [category, 'Wax Model'].join(' ')
}

function customNameFormatter(item) {
  return customItemNameFormatter.call(this, item).replace('<br /> ', '')
}

function jewelryNameFormatter(item) {
  const weightFormatter = ['Earring', 'Bracelet'].includes(item.category)
    ? tcwFormatter
    : caratFormatter

  const weight = weightFormatter.call(this, calculateTotalWeight(item))

  const style = getStyle(item)
  const centerStone = get(item, 'centerStones[0].stoneType', stone)
  const sideStone = get(item, 'sideStones[0].stoneType', '')
  const metalName = get(item, 'metal.value', '') || item.metalName
  let category = item.category
  if (category === 'Earring') category = 'Earrings'

  return [weight, centerStone, sideStone, style, category, ',', metalName]
    .filter(Boolean)
    .join(' ')
    .replace(' ,', ',')
}

function customItemNameFormatter(item) {
  if (!item.customSetting.category) return ''
  // unsuitable items
  // the same as isItemsMatch in utils helper
  if (
    (item.customSetting.category === 'Setting_Earring' &&
      item.customStone.category !== 'Pair') ||
    (item.customSetting.category !== 'Setting_Earring' &&
      item.customStone.category === 'Pair')
  )
    return ''

  const weight = calculateTotalWeight(item)
  const stoneType =
    item.customStone.stoneSubType ||
    item.customStone.stoneType ||
    get(item, 'customStone.stones[0].stoneSubType', '') ||
    get(item, 'customStone.stones[0].stoneType', stone)
  const settingCategory = item.customSetting.category.replace('Setting_', '')
  const metalName = item.customSetting.metalName
  const style = getStyle(item)
  const weightFormatter = ['Earring', 'Bracelet'].includes(settingCategory)
    ? tcwFormatter
    : caratFormatter

  return [
    style,
    stoneType,
    settingCategory,
    weightFormatter.call(this, weight),
    ',',
    metalName
  ]
    .filter(Boolean)
    .join(' ')
    .replace(' ,', ',')
}

function getStyle(item) {
  const styleNames = item.customSetting?.styleNames || item.styleNames
  if (styleNames?.some((s) => s.includes("Men's"))) return "Men's"
  if (styleNames?.some((s) => s.includes('Antique'))) return 'Antique Style'
  return styleNames ? styleNames[0] : ''
}
