export const ITEMS_LIST = {
  RESET_FIRST_PAGE: 'RESET_FIRST_PAGE',
  ADD_TO_LOADING_LIST: 'ADD_TO_LOADING_LIST',
  REMOVE_FROM_LOADING_LIST: 'REMOVE_FROM_LOADING_LIST',
  SET_LOADING: 'SET_LOADING',
  GET_ITEMS_LIST: 'GET_ITEMS_LIST',
  DROP_ITEMS_LIST: 'DROP_ITEMS_LIST',
  GET_MORE_ITEMS: 'GET_MORE_ITEMS',
  SWITCH_ITEMS_LIST_VIEW: 'SWITCH_ITEMS_LIST_VIEW',
  LOADED_PAGES: 'LOADED_PAGES',
  DROP_LOADED_PAGES: 'DROP_LOADED_PAGES'
}
