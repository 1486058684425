import { serializeContactsForm } from '~~/utils/serializators'
export const actions = {
  async submitForm({ commit, rootGetters }, form) {
    const response = await this.$api.contacts.sendContactsForm(
      serializeContactsForm.call(this, { form, rootGetters })
    )
    return response
  }
}

export default {
  namespaced: true,
  actions
}
