import axios from 'axios'
import kebabCase from 'lodash/kebabCase.js'
import cookie from 'cookie'
import { domainsMap } from '../definitions/defaults.js'
import { getCountryCode } from '../utils.js'

export const isDev = process.env.NODE_ENV !== 'production'
// export const isDev = false
export const apiHost = isDev ? 'qa-api.47thst.com' : 'nxc-api2.47thst.com'
export const protocol = 'https'
export const port = 443
// export const protocol = isDev ? 'http' : 'https'
// export const port = isDev ? 80 : 443
export const cacheTime = 1000 * 60 * 60 * 24
const cacheStartDelay = 5 // Minutes
export const sitemapPageSize = 1000

const wafKeys = {
  emerald: 'b8b359888e85',
  ruby: '648b86f16b8e',
  gemstone: '7a001dea8b6e',
  sapphire: 'f92ad84d3b58'
}

export function getScripts() {
  const scripts = []

  scripts.push({
    src: `https://f86a65dca19a.edge.sdk.awswaf.com/f86a65dca19a/${
      wafKeys[process.env.siteName]
    }/challenge.js`,
    defer: true
  })

  return scripts
}

export function getSitemapBlackList() {
  const blackList = [
    '/account/**',
    '/auth',
    '/order-history',
    '/education',
    '/cart',
    '/cart/payment-order',
    '/cart/secure-payment',
    '/cart/order-summary',
    '/design-your-own/review',
    '/showroom/preview',
    '/showroom/preview/success',
    '/favorites',
    '/compare-list'
  ]

  if (!process.env.enableDmto) {
    blackList.push('/featured-designers')
    blackList.push('/featured-designers/**')
  }

  return blackList
}
export function getLinks() {
  return [
    {
      rel: 'preconnect',
      href: 'https://360images.thenaturalsapphirecompany.com/'
    },
    {
      rel: 'preconnect',
      href: `https://${isDev ? 'qa-' : ''}images.thenaturalsapphirecompany.com/`
    },
    {
      rel: 'dns-prefetch',
      href: `https://${isDev ? 'qa-' : ''}images.thenaturalsapphirecompany.com/`
    },
    {
      rel: 'preconnect',
      href: 'https://service.thenaturalsapphirecompany.com'
    },
    {
      rel: 'preload',
      as: 'font',
      crossOrigin: 'anonymous',
      href: '/styles/fonts/Lora/woff2/Lora-Regular.woff2'
    },
    {
      rel: 'preload',
      as: 'font',
      crossOrigin: 'anonymous',
      href: '/styles/fonts/Lora/woff2/Lora-Italic.woff2'
    },
    {
      rel: 'preload',
      as: 'font',
      crossOrigin: 'anonymous',
      href: '/styles/fonts/Lato/woff2/Lato-Regular.woff2'
    }
  ]
}

export function getRedisHost() {
  if (process.env.NODE_ENV === 'development') return 'redis://localhost:6379'
  if (process.env.NODE_ENV === 'production')
    return 'redis://frontend-prod-ro.atdtvc.ng.0001.use1.cache.amazonaws.com:6379'
  return 'redis://50.17.89.250:6379'
}

export function cacheDisableDelay(deployTime) {
  const delay = cacheStartDelay * 60 // in seconds
  return Math.ceil(new Date().getTime() / 1000) - parseInt(deployTime) < delay
}

export async function getRedisRouteKey(route, context) {
  let countryCode = null
  const { req } = context
  const { data } = await axios.get(
    `${getHost(isDev)}/deploy.txt`.replace(/([^:]\/)\/+/g, '$1')
  )
  if (cacheDisableDelay(data)) return false
  if (route.includes('forceCountry')) {
    countryCode = route.split('forceCountry=')[1].split('&')[0]
    return `${countryCode.toLowerCase()}-${data.trim()}-${kebabCase(route)}`
  }
  const cookies = cookie.parse(req.headers.cookie || '')
  const authToken = cookies ? cookies['auth._token.login'] : 'false'
  if (!countryCode) {
    countryCode = getCountryCode(req).countryCode
  }
  return [
    countryCode.toLowerCase(),
    data,
    kebabCase(route),
    authToken?.substring(authToken.length - 10) // will take last 10 chars from the token's end, because start of the token will look very similar
  ]
    .filter(Boolean)
    .join('-')
}

export const purgeCssWhiteList = [
  /^swiper-/,
  /^swiper-pagination-bullet/,
  /^zoom-lens/,
  /^zoom-area/,
  /^popup-confirmation/,
  /^arrow/,
  /^simple-view/,
  /^ag-.*/,
  'cell-wrapper',
  'half-item',
  'item-title',
  'item-image',
  'item-param',
  /^items-view/,
  /^sub-menu/,
  'item-status-awaiting',
  'small-font',
  'data-popper-placement',
  /hero-bg-(stone|pairs|home|ring|engagement|jewelry|wedding|settings)/,
  /popup-confirmation\[data-popper-placement\^='(?:top|bottom|left|right)'\] > .arrow/,
  'items-jewelries',
  'items-rings',
  'items-stones',
  'items-origin',
  'items-settings',
  'ring-item',
  'earrings-item',
  'pendant-item',
  'bracelet-item',
  'wedding-band-item',
  'plain-band-item',
  'mens-ring-item',
  /^multiselect--/,
  /^multiselect__/,
  'is-pinned',
  /hero-(stone|pair|home|ring|engagement|jewelry|wedding|settings)/,
  /(left|center|right)-image__(stone|pair|home|ring|engagement|jewelry|wedding|settings)/,
  'nuxt-link-exact-active',
  'nuxt-link-active',
  'ps__rail-y',
  'ps__rail-x',
  /^hero-mini-images__/
]

// TODO: Bad logic. Case when there is no envMode is not handled
export function getHost(envMode) {
  if (process.env.NODE_ENV === 'development') return 'http://localhost:3000'
  return envMode
    ? `https://${domainsMap[process.env.siteName].devDomain}`
    : `https://${domainsMap[process.env.siteName].prodDomain}`
}
