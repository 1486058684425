import kebabCase from 'lodash/kebabCase.js'
import camelCase from 'lodash/camelCase.js'

// import * as stonesData from '../../projects/sapphire/static/system_stone_data.json'
// import * as stonesDataDev from '../../projects/sapphire/static/system_stone_data_dev.json'

// export const stones =
//   (process.env.isDev ? stonesDataDev.stoneTypes : stonesData.stoneTypes) || []
// export const colors =
//   (process.env.isDev ? stonesDataDev.stoneColors : stonesData.stoneColors) || []
// export const stonesFetchDate = process.env.isDev
//   ? stonesDataDev.date
//   : stonesData.date

export const colors = [
  {
    name: 'Blue',
    children: [],
    parents: [],
    itemsCount: {
      stone: 11,
      pair: 10,
      ring: 8,
      earring: 1,
      necklace: 1,
      pendant: 1,
      bracelet: 1,
      cufflink: 1,
      brooch: 1,
      weddingBand: 1,
      engagement: 1
    }
  },
  {
    name: 'Pink',
    children: [],
    parents: [],
    itemsCount: {
      stone: 10,
      pair: 9,
      ring: 1,
      earring: 1,
      necklace: 1,
      pendant: 1,
      bracelet: 1,
      cufflink: 1,
      brooch: 1,
      weddingBand: 1,
      engagement: 1
    }
  },
  {
    name: 'Yellow',
    children: [],
    parents: [],
    itemsCount: {
      stone: 9,
      pair: 8,
      ring: 2,
      earring: 1,
      necklace: 1,
      pendant: 1,
      bracelet: 1,
      cufflink: 1,
      brooch: 1,
      weddingBand: 1,
      engagement: 1
    }
  },
  {
    name: 'Padparadscha',
    children: [],
    parents: [],
    itemsCount: {
      stone: 8,
      pair: 7,
      ring: 7,
      earring: 1,
      necklace: 1,
      pendant: 1,
      bracelet: 1,
      cufflink: 1,
      brooch: 1,
      weddingBand: 1,
      engagement: 1
    }
  },
  {
    name: 'White',
    children: [],
    parents: [],
    itemsCount: {
      stone: 7,
      pair: 6,
      ring: 5,
      earring: 1,
      necklace: 1,
      pendant: 1,
      bracelet: 1,
      cufflink: 1,
      brooch: 1,
      weddingBand: 1,
      engagement: 1
    }
  },
  {
    name: 'Green',
    children: [],
    parents: [],
    itemsCount: {
      stone: 6,
      pair: 5,
      ring: 4,
      earring: 1,
      necklace: 1,
      pendant: 1,
      bracelet: 1,
      cufflink: 1,
      brooch: 1,
      weddingBand: 1,
      engagement: 1
    }
  },
  // {
  //   name: 'Blue Green',
  //   children: [],
  //   parents: [],
  //   itemsCount: {
  //     stone: 0,
  //     pair: 0,
  //     ring: 3,
  //     earring: 1,
  //     necklace: 1,
  //     pendant: 1,
  //     bracelet: 1,
  //     cufflink: 1,
  //     brooch: 1,
  //     weddingBand: 1
  //   }
  // },
  {
    name: 'Purple',
    children: [],
    parents: [],
    itemsCount: {
      stone: 4,
      pair: 4,
      ring: 1,
      earring: 1,
      necklace: 1,
      pendant: 1,
      bracelet: 1,
      cufflink: 1,
      brooch: 1,
      weddingBand: 1,
      engagement: 1
    }
  },
  {
    name: 'Unique Colored',
    children: [],
    parents: [],
    itemsCount: {
      stone: 3,
      pair: 3,
      ring: 1,
      earring: 1,
      necklace: 1,
      pendant: 1,
      bracelet: 1,
      cufflink: 1,
      brooch: 1,
      weddingBand: 1
    }
  },
  {
    name: 'Peach',
    children: [],
    parents: [],
    itemsCount: {
      stone: 0,
      pair: 0,
      ring: 1,
      earring: 0,
      necklace: 0,
      pendant: 0,
      bracelet: 0,
      cufflink: 0,
      brooch: 0,
      weddingBand: 0,
      engagement: 1
    }
  },
  {
    name: 'Curiosities / Bi-Color',
    children: [],
    parents: [],
    itemsCount: {
      stone: 5,
      pair: 0,
      ring: 1,
      earring: 1,
      necklace: 1,
      pendant: 1,
      bracelet: 1,
      cufflink: 1,
      brooch: 1,
      weddingBand: 1
    }
  }
  // {
  //   name: 'Montana',
  //   children: [],
  //   parents: [],
  //   itemsCount: {
  //     stone: 2,
  //     pair: 0,
  //     ring: 6,
  //     earring: 1,
  //     necklace: 1,
  //     pendant: 1,
  //     bracelet: 1,
  //     cufflink: 1,
  //     brooch: 1,
  //     weddingBand: 1
  //   }
  // }
].map((color) => ({
  ...color,
  kebabCase: kebabCase(color.name),
  camelCase: camelCase(color.name),
  routes: {
    stone: `${kebabCase(color.name)}-sapphires`,
    pair: `${kebabCase(color.name)}-sapphire-pairs`,
    ring: `${kebabCase(color.name)}-sapphire-rings`,
    engagement: `${kebabCase(color.name)}-sapphire-engagement-rings`
  }
}))
