import Vue from 'vue'
import LIST from '../_base/constants'
import mutations, { helper } from '../_base/mutations'
export default {
  ...mutations,

  [LIST.ADD_ITEM]: addItem,

  [LIST.ADD_ITEM_SILENT]: addItem
}

function addItem(state, { item }) {
  helper.gtag.addItem(state, item)
  const type = helper.getType(item)
  state.lastAddedItemType = type

  const listItem = helper.addDate(item)

  const index = helper.getIndex(state, item)
  if (index > -1) {
    Vue.set(state[`${type}Items`], index, listItem)
    return
  }
  state[`${type}Items`].push(listItem)
}
