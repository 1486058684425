import Vue from 'vue'
import { ITEMS_LIST } from './constants'
import { guid } from '~~/utils/utils'

export default {
  bindNamespace(state, name) {
    state._namespace = name
  },

  changeItemsOrder(state, value) {
    state.itemsList = value
  },

  [ITEMS_LIST.RESET_FIRST_PAGE](state) {
    state.pagesList[1] = null
    state.loadingPages[1] = null
  },

  [ITEMS_LIST.GET_ITEMS_LIST](state, listData) {
    state.totalItemsCount = listData.totalCount
    if (state.pagesList[listData.pageNumber]) {
      Vue.set(state.pagesList, listData.pageNumber, listData)
    } else {
      state.pagesList[listData.pageNumber] = listData
    }
    state.totalPagesCount = listData.pageCount
    state.lastLoadedPageNumber = listData.pageNumber
    state.itemsHash = guid()
  },

  [ITEMS_LIST.DROP_ITEMS_LIST](state) {
    state.pagesList = []
    state.loadingPages = []
    state.lastLoadedPageNumber = 0
    state.totalItemsCount = 0
  },

  [ITEMS_LIST.SWITCH_ITEMS_LIST_VIEW](state, viewType) {
    state.listView = viewType
  },

  [ITEMS_LIST.SET_LOADING](state, st) {
    state.loading = st
  },

  [ITEMS_LIST.ADD_TO_LOADING_LIST](state, pageNumber) {
    state.loadingPages.push(pageNumber)
  },

  [ITEMS_LIST.REMOVE_FROM_LOADING_LIST](state, pageNumber) {
    const index = state.loadingPages.indexOf(pageNumber)
    if (index > -1) state.loadingPages.splice(index, 1)
  }
}
