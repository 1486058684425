import camelCase from 'lodash/camelCase.js'
import { getDetailsPath } from '~~/utils/sitemapUtils.js'
import { getItemId, getHost as checkHost } from '~~/utils/utils'
import { getHost } from '~~/utils/config'
import { listsRoutesMap } from '~~/utils/definitions/defaults.js'
import md5 from '~~/utils/helpers/md5'

export default {
  stonesListItems: (store) => store.stonesListItems,
  stonePairsListItems: (store) => store.stonePairsListItems,
  braceletsListItems: (store) => store.braceletsListItems,
  earringsListItems: (store) => store.earringsListItems,
  necklacesPendantsListItems: (store) => store.necklacesPendantsListItems,
  ringsListItems: (store) => store.ringsListItems,
  broochesListItems: (store) => store.broochesListItems,
  cufflinksListItems: (store) => store.cufflinksListItems,
  weddingBandsItems: (store) => store.weddingBandsItems,
  weddingBandsPlainItems: (store) => store.weddingBandsPlainItems,
  ringSettingsItems: (store) => store.ringSettingsItems,
  earringSettingsItems: (store) => store.earringSettingsItems,
  braceletSettingsItems: (store) => store.braceletSettingsItems,
  pendantSettingsItems: (store) => store.pendantSettingsItems,
  necklaceSettingsItems: (store) => store.necklaceSettingsItems,
  customItems: (store) =>
    store.customItems.filter((item) => item.dataType !== 3),
  dmtoItems: (store) => store.customItems.filter((item) => item.dataType === 3),
  allListItems: (store) => {
    return [
      ...store.stonesListItems,
      ...store.stonePairsListItems,
      ...store.braceletsListItems,
      ...store.earringsListItems,
      ...store.necklacesPendantsListItems,
      ...store.ringsListItems,
      ...store.broochesListItems,
      ...store.cufflinksListItems,
      ...store.weddingBandsItems,
      ...store.weddingBandsPlainItems,
      ...store.ringSettingsItems,
      ...store.earringSettingsItems,
      ...store.braceletSettingsItems,
      ...store.pendantSettingsItems,
      ...store.necklaceSettingsItems,
      ...store.customItems
    ]
  },

  // for Rejoiner
  itemsParams: (store, getters) => {
    const items = []
    getters.allListItems.forEach((item) => {
      if (item.placeholder) return // items are not fully fetched (for example received simplified items after login)
      let func = paramsMap[camelCase(item.category)]
      if (!func) func = defaultGetParams
      items.push(func(item))
    })
    return items
  },
  inList: (store, getters) => (item) =>
    getters.allListItems.some(
      (i) =>
        getItemId(i) === getItemId(item) ||
        (i.guid !== undefined && i.guid === item.guid)
    ),
  itemsHash: (store, getters) => {
    return md5(JSON.stringify(getters.allListItems))
  },
  shareUrl: (state) => {
    const query = state.shareHash ? `?p=${state.shareHash}` : ''
    return `${getHost()}/${listsRoutesMap[state._namespace]}/${query}`
  }
}

const paramsMap = {
  custom: customGetParams,
  wax: waxGetParams
}

/**
 * Get simple item parameters without selected options
 * @param item {object}
 * @returns {{id, category}}
 */
export function defaultGetParams(item) {
  const params = { id: item.id, category: item.category }
  if (item.metalTypeCode) params.metalTypeCode = item.metalTypeCode
  if (item.selectedOptions) params.selectedOptions = item.selectedOptions
  params.pageLink = `${checkHost()}${getDetailsPath(item)}`
  return params
}

export function customGetParams(item) {
  return {
    category: 'Custom',
    pageLink: `${checkHost()}${getDetailsPath(item)}`,
    customSetting: {
      id: item.dataType === 3 ? item.id : item.customSetting.id.toUpperCase(),
      metalTypeCode: item.customSetting.metalTypeCode.toUpperCase()
    },
    customStone: {
      id: item.customStone.id.toUpperCase()
    },
    selectedOptions: item.selectedOptions
  }
}

export function waxGetParams(item) {
  return {
    ...customGetParams(item),
    category: 'Wax'
  }
}
