import cloneDeep from 'lodash/cloneDeep'
import actions from '../_base/actions'
import { ITEMS_LIST } from '../_base/constants'
import { normalizeItemsListData } from '~~/utils/normalizers'
import { serializeFilter } from '~~/utils/serializators'
import {
  defaultPageSize,
  defaultWeddingBandsPlainQuery
} from '~~/utils/definitions/defaults'

export default {
  ...cloneDeep(actions),

  async getPage(
    { commit, rootState, rootGetters, state, getters, dispatch },
    params
  ) {
    if (await dispatch('skipFetch', params)) return {}

    commit(ITEMS_LIST.SET_LOADING, true)
    commit(ITEMS_LIST.ADD_TO_LOADING_LIST, params.pageNumber)
    const query = serializeFilter(
      state._namespace,
      rootState.filters,
      params,
      rootGetters
    )

    const pageQuery = {
      ...cloneDeep(defaultWeddingBandsPlainQuery),
      pageNumber: params.pageNumber,
      pageSize: params.pageSize || defaultPageSize
    }

    const listData = await this.$api.listings.fetchBandsList({
      ...pageQuery,
      ...{ query }
    })

    if (params.beforeAddItemsCallback) params.beforeAddItemsCallback()

    const normalizedData = normalizeItemsListData(listData, params.pageNumber)

    if (params.force) commit(ITEMS_LIST.DROP_ITEMS_LIST)
    commit(ITEMS_LIST.GET_ITEMS_LIST, normalizedData)

    if (params.afterAddItemsCallback) params.afterAddItemsCallback()

    commit(ITEMS_LIST.SET_LOADING, false)
    commit(ITEMS_LIST.REMOVE_FROM_LOADING_LIST, params.pageNumber)

    return normalizedData
  }
}
