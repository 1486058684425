import isEmpty from 'lodash/isEmpty'
import { wordpress } from '~~/utils/normalizers'

export const WP = {
  FETCH_PAGE: 'FETCH_PAGE',
  FETCH_PAGES: 'FETCH_PAGES',
  FETCH_MENU: 'FETCH_MENU',
  FETCH_MENUS: 'FETCH_MENUS',
  SET_EXPANDED: 'SET_EXPANDED',
  SET_SEARCH_FIELD: 'SET_SEARCH_FIELD',
  FETCH_SEARCH: 'FETCH_SEARCH',
  FETCH_VIDEOS_DETAILS: 'FETCH_VIDEOS_DETAILS'
}

const faqMenuId = {
  ruby: 11,
  emerald: 14,
  gemstone: 16
}

const sideMenuId = {
  ruby: 3,
  emerald: 3,
  gemstone: 15
}

export const state = () => ({
  page: {},
  pages: [],
  menu: {},
  menus: [],
  expanded: null,
  searchResults: [],
  searchField: '',
  videos: [],
  savedTitle: ''
})

export const getters = {}

export const actions = {
  async getVideo360Details({ commit }, query) {
    const result = await this.$api.details.video360Details(query)
    commit(WP.FETCH_VIDEOS_DETAILS, result)
  },

  setSearchField({ commit }, text) {
    commit(WP.SET_SEARCH_FIELD, text)
  },

  setExpanded({ commit }, id) {
    commit(WP.SET_EXPANDED, id)
  },

  async fetchPost({ commit, state }, { id, slug }) {
    if (
      !isEmpty(state.page) &&
      (state.page.id === id || state.page.slug === slug)
    )
      return

    if (id) {
      const result = await this.$api.wp.fetchPostById(id)
      commit(WP.FETCH_PAGE, { result: wordpress.page(result) })
    }

    if (slug) {
      const result = await this.$api.wp.fetchPostBySlug(slug)
      commit(WP.FETCH_PAGE, { result: wordpress.page(result[0]) }) // array of pages
    }
  },

  async fetchPage({ commit, state }, { id, slug, omitContent = false }) {
    if (
      !isEmpty(state.page) &&
      (state.page.id === id || state.page.slug === slug)
    )
      return

    if (id) {
      const result = await this.$api.wp.fetchPageById(id)
      commit(WP.FETCH_PAGE, { result: wordpress.page(result), omitContent })
    }

    if (slug) {
      const result = await this.$api.wp.fetchPageBySlug(slug)
      commit(WP.FETCH_PAGE, { result: wordpress.page(result[0]), omitContent }) // array of pages
    }
  },

  async search({ commit }, text) {
    if (!text) {
      commit(WP.FETCH_SEARCH, [])
      return
    }
    const query = {
      search: text,
      per_page: 50
    }
    const result = await this.$api.wp.search(
      new URLSearchParams(query).toString() + '&_embed'
    )
    commit(WP.FETCH_SEARCH, wordpress.search(result))
  },

  async fetchPages({ commit }, query) {
    const result = await this.$api.wp.fetchPages(query)
    commit(WP.FETCH_PAGES, wordpress.pages(result))
  },

  async fetchMenu({ commit, state }, route) {
    const id =
      route.params.category === 'frequently-asked-questions'
        ? faqMenuId[process.env.siteName]
        : sideMenuId[process.env.siteName]
    if (state.menu.ID === id) return
    const result = await this.$api.wp.fetchMenu(id)
    commit(WP.FETCH_MENU, wordpress.menu.call(this, result))
  },

  async fetchMenus({ commit }) {
    const result = await this.$api.wp.fetchMenus()
    commit(WP.FETCH_MENUS, wordpress.menus(result))
  }
}

export const mutations = {
  [WP.SET_EXPANDED](state, id) {
    if (state.expanded !== id) return (state.expanded = id)
    state.expanded = null
  },

  [WP.FETCH_VIDEOS_DETAILS](state, result) {
    state.videos = result
  },

  [WP.FETCH_SEARCH](state, result) {
    state.searchResults = result
  },

  [WP.SET_SEARCH_FIELD](state, text) {
    state.searchField = text
  },

  [WP.FETCH_PAGE](state, { result, omitContent }) {
    if (omitContent) {
      // avoid content flickering for list type pages during client side rendering
      delete result.content
      state.savedTitle = result.title
      delete result.title
      state.page = {
        ...state.page,
        ...result
      }
      return
    }
    state.page = result
  },

  [WP.FETCH_PAGES](state, result) {
    state.pages = result
    if (state.savedTitle) {
      state.page.title = state.savedTitle
      state.savedTitle = ''
    }
  },

  [WP.FETCH_MENU](state, result) {
    state.menu = result
  },

  [WP.FETCH_MENUS](state, result) {
    state.menus = result
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
