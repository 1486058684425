import { defaultQuickSearchValues } from '~~/utils/definitions/defaults'
import { normalizeShowroomSearchItems } from '~~/utils/normalizers'
import { serializeShowroomForm } from '~~/utils/serializators'

export const SHOWROOM = {
  SET_SEARCH_ITEMS: 'SET_SEARCH_ITEMS',
  CLEAR_SEARCH_RESULTS: 'CLEAR_SEARCH_RESULTS',
  UPDATE_FORM: 'UPDATE_FORM',
  SET_SELECTED_ITEMS: 'SET_SELECTED_ITEMS'
}

const form = {
  customerName: '',
  phone: '',
  email: '',
  date: '',
  time: '10:30 AM',
  salesPerson: null,
  itemIds: [],
  message: '',
  'g-recaptcha-response': ''
}

export const state = () => ({
  form,
  ...defaultQuickSearchValues,
  selectedItems: []
})

export const getters = {
  searchItems: (store) => [
    ...store.stones,
    ...store.jewelry,
    ...store.pairs,
    ...store.bands,
    ...store.settings
  ]
}

export const actions = {
  setSelectedItems({ commit }, items) {
    commit(SHOWROOM.SET_SELECTED_ITEMS, items)
  },

  formUpdate({ commit }, form) {
    commit(SHOWROOM.UPDATE_FORM, form)
  },

  async submitForm({ commit, state }) {
    const response = await this.$api.showroom.scheduleVisit(
      serializeShowroomForm(state.form)
    )
    return response
  },

  async fetchSearchItems({ commit }, query) {
    const result = query
      ? await this.$api.showroom.fetchItems(query)
      : defaultQuickSearchValues
    commit(SHOWROOM.SET_SEARCH_ITEMS, normalizeShowroomSearchItems(result))
  },

  clearSearchResults({ commit }) {
    commit(SHOWROOM.CLEAR_SEARCH_RESULTS)
  }
}

export const mutations = {
  [SHOWROOM.SET_SELECTED_ITEMS](state, items) {
    state.selectedItems = items.map((item) => {
      if (!item.from) item.from = 'manual'
      return item
    })
  },

  [SHOWROOM.UPDATE_FORM](state, form) {
    state.form = Object.assign(state.form, form)
  },

  [SHOWROOM.SET_SEARCH_ITEMS](
    state,
    { stones, jewelry, pairs, bands, settings }
  ) {
    state.stones = stones.map((item) => {
      item.itemId = getItemId(item)
      return item
    })
    state.jewelry = jewelry.map((item) => {
      item.itemId = getItemId(item)
      return item
    })
    state.pairs = pairs.map((item) => {
      item.itemId = getItemId(item)
      return item
    })
    state.bands = bands.map((item) => {
      item.itemId = getItemId(item)
      return item
    })
    state.settings = settings.map((item) => {
      item.itemId = getItemId(item)
      return item
    })
  },

  [SHOWROOM.CLEAR_SEARCH_RESULTS](state) {
    state.stones = []
    state.jewelry = []
    state.pairs = []
    state.bands = []
    state.settings = []
  }
}

function getItemId(item) {
  return `${item.id}${item.metalTypeCode || ''}`
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
