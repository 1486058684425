import camelCase from 'lodash/camelCase'
// import get from 'lodash/get'
// import { stoneType } from '~~/utils/sitemapUtils'
import { caratFormatter, tcwFormatter } from './index'

const itemsNameMap = {
  Stone: stoneNameFormatter,
  Pair: pairNameFormatter,
  Ring: jewelryNameFormatter,
  Earring: jewelryNameFormatter,
  Pendant: jewelryNameFormatter,
  Necklace: jewelryNameFormatter,
  Bracelet: jewelryNameFormatter,
  'Wedding Band': bandsNameFormatter,
  'Plain Band': bandsNameFormatter,
  Setting_Ring: settingsNameFormatter,
  Setting_Bracelet: settingsNameFormatter,
  Setting_Earring: settingsNameFormatter,
  Setting_Necklace: settingsNameFormatter,
  Setting_Pendant: settingsNameFormatter
}

export default function listingItemNameFormatter(item, removeHtmlTags = false) {
  if (item.title) return item.title
  const func = itemsNameMap[item.category]
  if (!func) return 'Jewelry'
  if (removeHtmlTags) return func.call(this, item).replace(/<[^>]*>?/gm, '')
  return func.call(this, item)
}

function stoneNameFormatter(item) {
  const carat = caratFormatter(item.weight)

  const name = [
    carat,
    this.$t(
      `listingPage.filters.stoneShapesFilter.${camelCase(item.shape) || 'none'}`
    ),
    this.$t(
      `listingPage.filters.stoneTypeName.${camelCase(item.stoneType) || 'none'}`
    )
  ]
  return name.join(' ').trim()
}

function pairNameFormatter(item) {
  const carat = tcwFormatter(item.weight)

  const name = [
    carat,
    this.$t(
      `listingPage.filters.stoneShapesFilter.${camelCase(item.shape) || 'none'}`
    ),
    this.$t(
      `listingPage.filters.stoneTypeName.${camelCase(item.stoneType) || 'none'}`
    ),
    this.$t('listingPage.stonePairsList.pair')
  ]
  return name.join(' ').trim()
}

function bandsNameFormatter(item) {
  const metalName = this.$t(
    `listingPage.filters.metalTypesFilter.${
      camelCase(item.metalName) || 'none'
    }`
  )
    .replace(' 950', '')
    .replace('14K ', '')
    .replace('18K ', '')
  const category = this.$t(
    `categories.${camelCase(
      item.category.replace('Setting_', '').toLowerCase()
    )}`
  )
  const carat = caratFormatter(item.totalCarat)
  const sType =
    item.category !== 'Plain Band'
      ? this.$t(`listingPage.common.stoneTypes.${camelCase(item.stoneType)}`)
      : ''
  if (item.category === 'Plain Band') return `${metalName} ${category}`
  return [carat, sType, metalName, category].join(' ').trim()
}

function settingsNameFormatter(item) {
  const metalName = this.$t(
    `listingPage.filters.metalTypesFilter.${
      camelCase(item.metalName) || 'none'
    }`
  )
  const category = this.$t(
    `categories.${camelCase(
      item.category.replace('Setting_', '').toLowerCase()
    )}`
  )

  return [metalName, category].join(' ')
}

// function customNameFormatter(item) {
//   return customItemNameFormatter.call(this, item).replace('<br /> ', '')
// }

function jewelryNameFormatter(item) {
  const metalName = this.$t(
    `listingPage.filters.metalTypesFilter.${
      camelCase(item.metalName) || 'none'
    }`
  )
    .replace(' 950', '')
    .replace('14K ', '')
    .replace('18K ', '')
  const category = this.$t(
    `categories.${camelCase(
      item.category.replace('Setting_', '').toLowerCase()
    )}`
  )
  const carat = ['Earring', 'Bracelet'].includes(item.category)
    ? tcwFormatter(item.stoneWeight)
    : caratFormatter(item.stoneWeight)
  const sType = this.$t(
    `listingPage.common.stoneTypes.${camelCase(item.stoneType)}`
  )
  return [carat, sType, metalName, category].join(' ')
}

// function customItemNameFormatter(item) {
//   if (!item.customSetting.category) return ''
//   // unsuitable items
//   // the same as isItemsMatch in utils helper
//   if (
//     (item.customSetting.category === 'Setting_Earring' &&
//       item.customStone.category !== 'Pair') ||
//     (item.customSetting.category !== 'Setting_Earring' &&
//       item.customStone.category === 'Pair')
//   )
//     return ''
//
//   const weight = item.customStone.totalWeight || item.customStone.weight
//   const stoneType =
//     item.customStone.stoneType ||
//     get(item, 'customStone.stones[0].stoneType', 'Ruby')
//   const settingCategory = item.customSetting.category.replace('Setting_', '')
//   const weightFormatter = ['Earring', 'Bracelet'].includes(settingCategory)
//     ? tcwFormatter
//     : caratFormatter
//
//   if (this?.$t)
//     return `${this.$t(
//       `detailsPage.stoneTypeName.${camelCase(stoneType)}`
//     )} ${this.$t(
//       `categories.${camelCase(settingCategory.toLowerCase())}`
//     )} <br /> ${weightFormatter.call(this, weight)} ${this.$t(
//       `detailsPage.metalTypesNames.${camelCase(item.customSetting.metalName)}`
//     )}`
//
//   return [
//     stoneType,
//     settingCategory,
//     weightFormatter.call(this, weight),
//     item.customSetting.metalName
//   ].join(' ')
// }
