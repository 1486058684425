import get from 'lodash/get'

export const ORDER = {
  SET_ORDER_HISTORY: 'SET_ORDER_HISTORY',
  SET_ORDER_STEPS: 'SET_ORDER_STEPS',
  FLUSH_ORDERS: 'FLUSH_ORDERS'
}

export const state = () => ({
  orderHistory: [],
  orderSteps: {}
})

export const actions = {
  flushOrders({ commit }) {
    commit(ORDER.FLUSH_ORDERS)
  },

  async fetchOrderHistory({ commit }) {
    try {
      const orderHistory = await this.$api.order.fetchOrderHistory()
      commit(ORDER.SET_ORDER_HISTORY, orderHistory)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async fetchOrderSteps({ commit }, { orderId, stepId }) {
    try {
      const steps = await this.$api.order.fetchOrderSteps(orderId, stepId)
      commit(ORDER.SET_ORDER_STEPS, { orderId, stepId, steps })
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export const getters = {
  itemSteps: (store) => (orderId, stepId) =>
    get(store, `orderSteps.${orderId}.${stepId}`, []),
  orderHistory: (store) => store.orderHistory
}

export const mutations = {
  [ORDER.FLUSH_ORDERS](state) {
    state.orderHistory = []
    state.orderSteps = {}
  },

  [ORDER.SET_ORDER_HISTORY](state, orderHistory) {
    state.orderHistory = orderHistory
  },

  [ORDER.SET_ORDER_STEPS](state, { orderId, stepId, steps }) {
    if (!state.orderSteps[orderId]) state.orderSteps[orderId] = {}
    if (!state.orderSteps[orderId][stepId])
      state.orderSteps[orderId][stepId] = {}
    state.orderSteps[orderId][stepId] = steps
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
