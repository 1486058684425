import camelCase from 'lodash/camelCase'

export const stonesFilters = {
  preset: 0,
  filter: 'standard',
  cuttingstyle: '',
  isStar: 0,
  origin: '',
  colorCategories: [],
  stoneCategories: [],
  stoneTypes: [],
  colors: [],
  availability: 0,
  webCategory: 0,
  style: ''
}

export const settingsFilters = {
  preset: 0,
  filter: 'standard',
  category: '',
  stoneId: '',
  style: null
}

export const bandsFilters = {
  preset: 0,
  filter: 'standard'
}

export const jewelryFilters = {
  ...stonesFilters
}

// There is no featured first b/c this is default sort
export const urlToStoreSort = {
  price: 'sortByPrice',
  weight: 'sortByWeight',
  'new-first': 'newFirst'
}

export const newSortingNamesMap = {
  'featured-first': 'FeaturedFirst',
  price: 'Price',
  'metal-weight': 'MetalWeight',
  'new-first': 'NewFirst',
  'carat-weight': 'TotalCarat'
}

export const rootFilters = [
  'lab-types',
  'design-styles',
  'styles',
  'color-categories',
  'stone-categories'
]
export const fetchRootFilters = [
  'lab-types',
  'design-styles',
  'styles',
  'color-categories',
  'stone-categories'
] // root filters that should be fetched
export const rootFiltersStore = rootFilters.map((f) => camelCase(f))
export const fetchRootFiltersStore = fetchRootFilters.map((f) => camelCase(f))

export const urlToStoreFilters = {
  categories: 'categories',
  clarity: 'clarity',
  intensity: 'intensity',
  'cutting-styles': 'cuttingStyles',
  'lab-colors': 'labColors',
  'lab-enhancements': 'labEnhancements',
  'lab-types': 'labTypes',
  availability: 'availability',
  'length-max': 'lengthRange',
  'length-min': 'lengthRange',
  colors: 'colors',
  origins: 'stoneOrigins',
  'price-max': 'priceRange',
  'price-min': 'priceRange',
  shapes: 'stoneShapes',
  'stone-sub-type': 'stoneSubType',
  'weight-max': 'caratRange',
  'weight-min': 'caratRange',
  'width-max': 'widthRange',
  'width-min': 'widthRange',
  'band-width-max': 'bandWidthRange',
  'band-width-min': 'bandWidthRange',
  metals: 'metalTypes',
  enhancements: 'enhancements',
  'stone-types': 'stoneTypes',
  'design-styles': 'designStyles',
  styles: 'styles',
  'center-stone-shapes': 'centerStoneShapes',
  'side-stone-shapes': 'sideStoneShapes',
  'sort-by': 'sortBy',
  order: 'order',
  'color-categories': 'colorCategories',
  'stone-categories': 'stoneCategories'
}

export const filtersNamesMap = {
  clarity: 'clarity',
  cuttingStyles: 'cutting-styles',
  intensity: 'intensity',
  labColors: 'lab-colors',
  colorCategories: 'color-categories',
  stoneCategories: 'stone-categories',
  labEnhancements: 'lab-enhancements',
  labTypes: 'lab-types',
  availability: 'availability',
  caratRange: 'weight',
  lengthRange: 'length',
  priceRange: 'price',
  colors: 'colors',
  color: 'color',
  stoneOrigins: 'origins',
  stoneShapes: 'shapes',
  stoneSubType: 'stone-sub-type',
  widthRange: 'width',
  bandWidthRange: 'band-width',
  metalTypes: 'metals',
  enhancements: 'enhancements',
  categories: 'categories',
  centerStoneShapes: 'center-stone-shapes',
  sideStoneShapes: 'side-stone-shapes',
  designStyles: 'design-styles',
  styles: 'styles',
  stoneTypes: 'stone-types',
  sortBy: 'sort-by',
  order: 'order'
}

export const sourceRangeFiltersNamesMap = {
  bandWidthRange: 'bandWidthRange',
  priceRange: 'priceRange',
  caratRange: 'caratRange',
  lengthRange: 'dimensions.lengthRange',
  widthRange: 'dimensions.widthRange'
}

export const notAreaFilters = ['availability']

export const validUrlFilterNames = [
  ...rootFilters,
  'categories',
  'clarity',
  'intensity',
  'cutting-styles',
  'lab-enhancements',
  'lab-colors',
  'lab-types',
  'color-categories',
  'colors',
  // 'availability',
  'length-max',
  'length-min',
  'origins',
  'price-max',
  'price-min',
  'shapes',
  'stone-sub-type',
  'weight-max',
  'weight-min',
  'width-max',
  'width-min',
  'band-width-max',
  'band-width-min',
  'metals',
  'enhancements',
  'stone-types',
  'styles',
  'design-styles',
  'center-stone-shapes',
  'side-stone-shapes',
  'sort-by',
  'order'
]
