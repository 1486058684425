export const BESTSELLERS = {
  FETCH_BESTSELLERS: 'FETCH_BESTSELLERS',
  FETCH_BESTSELLERS_START: 'FETCH_BESTSELLERS_START',
  FETCH_BESTSELLERS_END: 'FETCH_BESTSELLERS_END',
  SET_BESTSELLERS: 'SET_BESTSELLERS',
  RESET_BESTSELLERS: 'RESET_BESTSELLERS'
}

export const state = () => ({
  bestsellers: [],
  fetching: false
})

export const getters = {
  bestsellers: (store) => store.bestsellers,
  fetching: (store) => store.fetching
}

export const actions = {
  async getBestsellers({ commit, state }, query) {
    if (state.bestsellers.length) return // prevent multiple fetching
    try {
      commit(BESTSELLERS.FETCH_BESTSELLERS_START)
      const response = await this.$api.bestsellers.fetchDeals(query)
      commit(BESTSELLERS.SET_BESTSELLERS, response)
    } catch (e) {
      console.log('Error at bestsellers', e)
    } finally {
      commit(BESTSELLERS.FETCH_BESTSELLERS_END)
    }
  }
}

export const mutations = {
  [BESTSELLERS.SET_BESTSELLERS](state, data) {
    state.bestsellers = data
  },
  [BESTSELLERS.FETCH_BESTSELLERS_START](state) {
    state.fetching = true
  },
  [BESTSELLERS.FETCH_BESTSELLERS_END](state) {
    state.fetching = false
  },
  [BESTSELLERS.RESET_BESTSELLERS](state) {
    state.bestsellers = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
