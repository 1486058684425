import Vue from 'vue'
import get from 'lodash/get'
import { companyName } from '~~/utils/definitions/defaults.js'
import { itemNameFormatterNoLocale } from '~~/utils/formatters'
import { serializeShareLink, serializeShareForm } from '~~/utils/serializators'

export const SHARE = {
  SET_SHARE_LINK_ID: 'SET_SHARE_LINK_ID',
  RESET_MESSAGE: 'RESET_MESSAGE',
  SET_FORM: 'SET_FORM',
  RESET_FORM: 'RESET_FORM',
  DESTROY_SHARE_LINK_ID: 'DESTROY_SHARE_LINK_ID'
}

const form = {
  senderName: '',
  senderEmail: '',
  message: '',
  recipient1: '',
  recipient2: '',
  receiveCopy: true
}

export const state = () => ({
  form,
  shareLinkId: ''
})

export const getters = {
  form: (store) => store.form,
  shareLinkId: (store) => store.shareLinkId
}

export const actions = {
  async submitForm({ state, commit }, { params, params: { item } }) {
    Vue.$gtag.event('share_with_friend', {
      event_label: 'Email item to a friend',
      currency: 'USD',
      value: get(item, 'finalPrice.totalPrice', item.price),
      items: [
        {
          id: item.id,
          name: itemNameFormatterNoLocale(item),
          category: item.category,
          brand: companyName[process.env.siteName],
          quantity: 1,
          price: String(get(item, 'finalPrice.totalPrice', item.price))
        }
      ]
    })
    try {
      await this.$api.details.shareProduct(
        serializeShareForm({
          params,
          options: serializeShareLink({ item })
        })
      )
    } catch (e) {
      console.log(e)
    }
  },

  destroyShareLinkId({ commit }) {
    commit(SHARE.DESTROY_SHARE_LINK_ID)
  },

  setForm({ commit }, fields) {
    commit(SHARE.SET_FORM, fields)
  },

  resetForm({ commit }) {
    commit(SHARE.RESET_FORM)
  }
}

export const mutations = {
  [SHARE.DESTROY_SHARE_LINK_ID](state) {
    state.shareLinkId = ''
  },

  [SHARE.SET_SHARE_LINK_ID](state, id) {
    state.shareLinkId = id
  },

  [SHARE.RESET_MESSAGE](state, message = '') {
    state.form.message = message
  },

  [SHARE.SET_FORM](state, fields) {
    const keys = Object.keys(fields)
    keys.forEach((key) => {
      Vue.set(state.form, key, fields[key])
    })
  },

  [SHARE.RESET_FORM](state) {
    state.form = form
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
