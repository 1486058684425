import { normalizeRecommendations } from '~~/utils/normalizers'

export const ACCOUNT_PAGES = {
  SET_NOTIFICATIONS_LIST: 'SET_NOTIFICATIONS_LIST',
  SET_NOTIFICATION_STATE: 'SET_NOTIFICATION_STATE',
  FLUSH_ACCOUNT_PAGES: 'FLUSH_ACCOUNT_PAGES',
  PREVIEW_SUBMISSIONS: 'PREVIEW_SUBMISSIONS',
  RECOMMENDATIONS: 'RECOMMENDATIONS'
}

function getSelectedOptions(array, result = []) {
  array.forEach((node) => {
    if (node.type === 'option' && node.selected) result.push(node.id)
    if (node.children) getSelectedOptions(node.children, result)
  })
  return result
}

/**
 * Recursively update nodes states
 * @param {Array.<Object>} nodes
 * @param {String} id
 * @param {Object} parentNode
 */
function updateNode(nodes, id, parentNode) {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    // if node is "list" or "section"
    if (node.type !== 'option') {
      if (!node.children) continue
      updateNode(node.children, id, node)
      continue
    }

    // is this is a node (with type "option") we are looking for
    if (node.id === id) {
      // checkbox nodes may have related child nodes
      if (parentNode.selectionType === 'checkbox') {
        node.selected = !node.selected
        if (!node.selected) unselectChildren(node.children) // if parent checkbox is unchecked, then uncheck all child nodes
        if (node.selected) selectChildren(node.children) // if parent checkbox is checked, then check all child nodes and select first button foe radio buttons
      }

      if (parentNode.selectionType === 'radio') {
        parentNode.children.forEach((n) => {
          n.selected = false // unselect all radio buttons
        })
        node.selected = true // select only necessary radio button
      }
      break // stop loop if node found
    }

    if (node.children) updateNode(node.children, id, node) // continue searching for updated node
  }
}

function selectChildren(nodes, selectionType) {
  if (!nodes) return
  if (selectionType === 'radio') {
    if (nodes[0].hasOwnProperty('selected')) nodes[0].selected = true // select first radio button
    // radio button can't have children, so we dont need to search in children node
    return
  }
  nodes.forEach((node) => {
    if (node.hasOwnProperty('selected')) node.selected = true
    selectChildren(node.children, node.selectionType)
  })
}

function unselectChildren(nodes) {
  if (!nodes) return
  nodes.forEach((node) => {
    if (node.hasOwnProperty('selected')) node.selected = false
    unselectChildren(node.children)
  })
}

export const state = () => ({
  recommendations: [],
  notificationsList: [],
  previewSubmissions: []
})

export const getters = {
  selectedNotifications: (store) => getSelectedOptions(store.notificationsList)
}

export const actions = {
  async fetchRecommendations({ commit }) {
    const result = await this.$api.account.fetchRecommendations()
    commit(ACCOUNT_PAGES.RECOMMENDATIONS, normalizeRecommendations(result))
  },

  async deleteRecommendation({ commit }, { id }) {
    const result = await this.$api.account.deleteRecommendation({
      data: { id }
    })
    commit(ACCOUNT_PAGES.RECOMMENDATIONS, normalizeRecommendations(result))
  },

  async fetchPreviewSubmissions({ commit }) {
    const result = await this.$api.account.fetchPreviewSubmissions()
    commit(ACCOUNT_PAGES.PREVIEW_SUBMISSIONS, result)
  },

  setNotificationState({ commit }, id) {
    commit(ACCOUNT_PAGES.SET_NOTIFICATION_STATE, id)
  },

  async saveNotificationsList({ commit, getters }) {
    const result = await this.$api.account.saveNotificationsList(
      getters.selectedNotifications
    )
    commit(ACCOUNT_PAGES.SET_NOTIFICATIONS_LIST, result)
  },

  async fetchNotificationsList({ rootState, state, commit }) {
    if (!rootState.auth.loggedIn) return
    if (state.notificationsList.length) return
    const result = await this.$api.account.fetchNotificationsList()
    commit(ACCOUNT_PAGES.SET_NOTIFICATIONS_LIST, result)
  },

  flushAccount({ commit }) {
    commit(ACCOUNT_PAGES.FLUSH_ACCOUNT_PAGES)
  }
}

export const mutations = {
  [ACCOUNT_PAGES.RECOMMENDATIONS](state, recommendations) {
    state.recommendations = recommendations
  },

  [ACCOUNT_PAGES.PREVIEW_SUBMISSIONS](state, submissions) {
    state.previewSubmissions = submissions
  },

  [ACCOUNT_PAGES.SET_NOTIFICATION_STATE](state, id) {
    updateNode(state.notificationsList, id)
  },

  [ACCOUNT_PAGES.SET_NOTIFICATIONS_LIST](state, notifications) {
    state.notificationsList = notifications
  },

  [ACCOUNT_PAGES.FLUSH_ACCOUNT_PAGES](state) {
    state.notificationsList = []
    state.previewSubmissions = []
    state.recommendations = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
