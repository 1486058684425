export const sideStonesAvailableParameters = [
  'weight',
  'grade',
  'position',
  'clarity',
  'stoneType'
]

export const availableParameters = [
  'prong',
  'ringSize',
  'vedicSetting',
  'engraving',
  'finish',
  'ringWidth',
  ...sideStonesAvailableParameters
]

export const sideStonesParametersNamesMap = {
  weight: 'w',
  grade: 'g',
  position: 'p',
  clarity: 'c',
  stoneType: 'st'
}

export const parametersNamesMap = {
  ringSize: 'rs',
  vedicSetting: 'vs',
  engraving: 'e',
  finish: 'f',
  ringWidth: 'rw',
  prong: 'pr',
  ...sideStonesParametersNamesMap
}
