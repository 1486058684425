import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import {
  defaultWeddingBandsSideStonesQuery,
  productListTypeByCategory,
  bandsByCategory,
  settingsByCategory
} from '~~/utils/definitions/defaults'
import {
  serializeAskAboutForm,
  serializeWeddingBandsDetails
} from '~~/utils/serializators'
import {
  normalizeWeddingBandsDetails,
  normalizeSidestoneOptions,
  normalizeSettingsDetails,
  normalizeSharedSelectedOptions
} from '~~/utils/normalizers'
import FetchDetails from '~~/utils/store/classes/ProductDetails'

export const DETAILS = {
  FETCH_DETAILS: 'FETCH_DETAILS',
  SET_DETAILS: 'SET_DETAILS',
  RESET_DETAILS: 'RESET_DETAILS',
  RESET_SELECTED_OPTIONS: 'RESET_SELECTED_OPTIONS',
  FETCH_DETAILS_OPTIONS: 'FETCH_DETAILS_OPTIONS',
  FETCH_SIDE_STONES: 'FETCH_SIDE_STONES',
  SET_SELECTED_OPTIONS: 'SET_SELECTED_OPTIONS',
  SET_SIDE_STONE_OPTIONS: 'SET_SIDE_STONE_OPTIONS',
  SIDE_STONE_FETCHED: 'SIDE_STONE_FETCHED',
  DETAILS_FETCHED_POSITIVE: 'DETAILS_FETCHED_POSITIVE',
  DETAILS_FETCHED_NEGATIVE: 'DETAILS_FETCHED_NEGATIVE',
  UPDATE_ITEM: 'UPDATE_ITEM',
  SET_CURRENT_GUID: 'SET_CURRENT_GUID',
  CONFIRM_RING_SIZE: 'CONFIRM_RING_SIZE',
  CONFIRM_RING_SIZE_RESET: 'CONFIRM_RING_SIZE_RESET'
}

const itemState = {
  ringSizeConfirmed: false,
  stateHash: null
}

export const state = () => ({
  productDetails: {},
  productDetailsOptions: {},
  detailsFetched: false,
  sideStonesFetched: false,
  currentGuid: null,
  // item state (hashes, selected ring size and etc)
  itemState
})

export const getters = {
  productDetails: (store) => store.productDetails,
  productThumbnails: (store) => store.productDetails.thumbnails || [],
  productDetailsOptions: (store) => store.productDetailsOptions,
  selectedOptions: (store) => store.productDetails.selectedOptions,
  detailsFetched: (store) => store.detailsFetched,
  sideStonesFetched: (store) => store.sideStonesFetched,
  currentGuid: (store) => store.currentGuid,
  ringSizeConfirmed: (store) => store.itemState.ringSizeConfirmed,
  sideStonesGrades: (store) => (index, stoneTypeId) => {
    return (
      store.productDetails.sideStoneOptions[index]?.grades?.filter(
        (o) => o.stoneTypeId === stoneTypeId
      ) || []
    )
  },
  sideStonesWeights: (store) => (index, gradeId, stoneTypeId) => {
    return (
      store.productDetails.sideStoneOptions[index]?.weights?.filter(
        (o) => o.gradeId === gradeId && o.stoneTypeId === stoneTypeId
      ) || []
    )
  }
}

export const actions = {
  async fetchDetails(store, params) {
    const productDetails = new FetchDetails(
      store,
      params,
      this.$api,
      this.$cookies
    )
    await productDetails.fetch()
  },

  async fetchItemsPrices({ commit }, ids) {
    const query = {
      publicIds: ids
    }
    const prices = await this.$api.details.itemsPrice(query)
    return prices
  },

  async fetchDetailsOptions({ commit, state }, type = 'jewelry') {
    if (!isEmpty(state.productDetailsOptions)) return
    const ringSizes = await this.$api.details.fetchRingSizes(type)
    commit(DETAILS.FETCH_DETAILS_OPTIONS, { ringSizes })
  },

  async fetchSharedSelectedOptions({ commit }, { id }) {
    const sharedSelectedOptions = await this.$api.details.getProductLink(id)
    commit(
      DETAILS.SET_SELECTED_OPTIONS,
      normalizeSharedSelectedOptions({ params: sharedSelectedOptions })
    )
  },

  confirmRingSize({ commit }) {
    commit(DETAILS.CONFIRM_RING_SIZE)
  },

  confirmRingSizeReset({ commit }) {
    commit(DETAILS.CONFIRM_RING_SIZE_RESET)
  },

  setCurrentGuid({ commit }, guid) {
    commit(DETAILS.SET_CURRENT_GUID, guid)
  },

  unsetCurrentGuid({ commit }) {
    commit(DETAILS.SET_CURRENT_GUID, null)
  },

  async setDetailsItem({ commit, getters }, { item }) {
    let product = cloneDeep(item)
    if (bandsByCategory.includes(item.category)) {
      if (!item.metalTypes) {
        // simple band from bands list page. Need to fetch more details. metalTypes have only items with full data (duck typing)
        const params = {
          id: item.id,
          metalTypeCode: item.metalTypeCode,
          size: get(item, 'selectedOptions.ringSize', null),
          width: get(item, 'selectedOptions.ringWidth', null),
          sideStones: get(item, 'selectedOptions.sideStones', [])
        }
        let details = {}
        const query = serializeWeddingBandsDetails(params)
        if (item.category === 'Plain Band')
          details = await this.$api.details.fetchPlainBandsDetails(query)
        if (item.category === 'Wedding Band')
          details = await this.$api.details.fetchBandsDetails(query)
        product = normalizeWeddingBandsDetails(details)
      }
    }

    if (settingsByCategory.includes(item.category)) {
      if (!item.metalTypes) {
        // simple setting from settings list page. Need to fetch more details. metalTypes have only items with full data (duck typing)
        const details = await this.$api.details.fetchSettingsDetails({
          id: item.id,
          metalTypeCode: item.metalTypeCode
        })
        const normalized = normalizeSettingsDetails(details)
        product = { ...normalized, selectedOptions: item.selectedOptions }
        commit(DETAILS.SET_DETAILS, product)
        commit(DETAILS.RESET_SELECTED_OPTIONS)
        commit(DETAILS.SET_SELECTED_OPTIONS, item.selectedOptions)
        return
      }
    }

    commit(DETAILS.SET_DETAILS, product)

    if (isEmpty(item.selectedOptions)) {
      // Start setting default selected options
      commit(DETAILS.RESET_SELECTED_OPTIONS)

      // Set main selections
      const metalTypeIndex = getters.productDetails.metalTypes.findIndex(
        (metal) => metal.key === getters.productDetails.metalTypeCode
      )
      const options = {
        metalType: getters.productDetails.metalTypes[metalTypeIndex],
        ringSize: getters.productDetails.size,
        ringWidth: getters.productDetails.width,
        finish: getters.productDetails.finish[0]
      }
      commit(DETAILS.SET_SELECTED_OPTIONS, options)
    }

    // Set sidestones selections if not setted
    if (
      item.category === 'Wedding Band' &&
      !getters.selectedOptions.sideStoneOptions?.length
    ) {
      const sideStoneOptions = []
      getters.productDetails.sideStoneOptions.forEach((stone, index) => {
        const stoneType = stone.stoneTypes[0]
        const grade = stone.grades.filter(
          (w) => w.stoneTypeId === stoneType.id
        )[0]
        const weight = stone.weights.filter(
          (w) => w.stoneTypeId === stoneType.id && w.gradeId === grade.id
        )[0]
        const stoneParams = {
          stoneType,
          grade,
          weight,
          clarity: stone.clarities[0]
        }
        sideStoneOptions[index] = stoneParams
      })
      commit(DETAILS.SET_SELECTED_OPTIONS, { sideStoneOptions })
    } else {
      commit(DETAILS.SET_SELECTED_OPTIONS, item.selectedOptions)
    }

    commit(DETAILS.SIDE_STONE_FETCHED, true)
    commit(DETAILS.DETAILS_FETCHED_POSITIVE)
  },

  async submitAskAboutForm({ commit, state }, { form, item }) {
    const response = await this.$api.details.submitAskAboutForm(
      serializeAskAboutForm(form, item)
    )
    return response
  },

  setDetails({ commit }, details) {
    commit(DETAILS.SET_DETAILS, details)
  },

  resetDetails({ commit }) {
    commit(DETAILS.RESET_DETAILS)
  },

  resetSelectedOptions({ commit }) {
    commit(DETAILS.RESET_SELECTED_OPTIONS)
  },

  setSelectedOptions({ commit }, options) {
    commit(DETAILS.SET_SELECTED_OPTIONS, options)

    if (options.engraving !== undefined) return
    if (options.finish !== undefined) return
    commit(DETAILS.SIDE_STONE_FETCHED, false)
  },

  setSideStonesOptions({ commit }, options) {
    commit(DETAILS.SET_SIDE_STONE_OPTIONS, options)
    commit(DETAILS.DETAILS_FETCHED_NEGATIVE)
  },

  updateItem({ commit }, params) {
    commit(DETAILS.UPDATE_ITEM, params)
  },

  async fetchSideStones({ commit, getters }, { bandId, bandWidth }) {
    if (
      productListTypeByCategory[getters.productDetails.category] ===
      'weddingBands'
    ) {
      const query = { ...defaultWeddingBandsSideStonesQuery, bandId, bandWidth }
      const sideStoneOptions = await this.$api.details.fetchSideStonesOptions(
        query
      )
      commit(
        DETAILS.FETCH_SIDE_STONES,
        normalizeSidestoneOptions(sideStoneOptions)
      )

      getters.productDetails.sideStoneOptions.forEach((option, index) => {
        sideStoneOptions[index] = {
          grade: option.grades[0],
          stoneType: option.stoneTypes[0],
          position: option.position,
          weight: option.weights[0],
          clarity: option.clarities[0]
        }
      })
      commit(DETAILS.SET_SELECTED_OPTIONS, { sideStoneOptions })
    }

    commit(DETAILS.SIDE_STONE_FETCHED, true)
    commit(DETAILS.DETAILS_FETCHED_NEGATIVE)
  }
}

export const mutations = {
  [DETAILS.CONFIRM_RING_SIZE](state) {
    if (
      ['Ring', 'Wedding Band', 'Plain Band'].includes(
        state.productDetails.category
      )
    )
      state.itemState.ringSizeConfirmed = true
  },

  [DETAILS.CONFIRM_RING_SIZE_RESET](state) {
    state.itemState.ringSizeConfirmed = false
  },

  [DETAILS.FETCH_DETAILS](state, details) {
    state.productDetails = state.currentGuid
      ? { ...details, guid: state.currentGuid }
      : details
    state.currentGuid = null
  },

  [DETAILS.SET_DETAILS](state, details) {
    state.productDetails = details
    if (details.guid) state.currentGuid = details.guid
  },

  [DETAILS.RESET_DETAILS](state) {
    state.productDetails = {}
    state.selectedOptions = {}
    state.currentGuid = null
  },

  [DETAILS.SET_CURRENT_GUID](state, guid) {
    state.currentGuid = guid
  },

  [DETAILS.RESET_SELECTED_OPTIONS](state) {
    state.productDetails.selectedOptions = {}
  },

  [DETAILS.FETCH_DETAILS_OPTIONS](state, detailsOptions) {
    state.productDetailsOptions = detailsOptions
  },

  [DETAILS.UPDATE_ITEM](state, params) {
    if (!params) return
    const keys = Object.keys(params)
    keys.forEach((key) => {
      Vue.set(state.productDetails, key, params[key])
    })
  },

  [DETAILS.SET_SELECTED_OPTIONS](state, options) {
    if (!options) return
    const keys = Object.keys(options)
    keys.forEach((key) => {
      if (key === 'metalTypeCode') {
        Vue.set(state.productDetails.selectedOptions, key, options[key].key)
        Vue.set(
          state.productDetails.selectedOptions,
          'metalName',
          options[key].value
        )
      }
      Vue.set(state.productDetails.selectedOptions, key, options[key])
    })
  },

  [DETAILS.SET_SIDE_STONE_OPTIONS](state, { index, value, type }) {
    state.productDetails.selectedOptions.sideStoneOptions[index][type] = value
  },

  [DETAILS.FETCH_SIDE_STONES](state, sideStoneOptions) {
    state.productDetails.sideStoneOptions = sideStoneOptions
  },

  [DETAILS.SIDE_STONE_FETCHED](state, val) {
    state.sideStonesFetched = val
  },

  [DETAILS.DETAILS_FETCHED_POSITIVE](state) {
    state.detailsFetched = true
  },

  [DETAILS.DETAILS_FETCHED_NEGATIVE](state) {
    if (state.detailsFetched === null) {
      state.detailsFetched = false
      return
    }
    state.detailsFetched = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
