import get from 'lodash/get'
import camelCase from 'lodash/camelCase'
import { calculateTotalWeight } from './sitemapUtils'
import { normRouteName, removeTagsFromString } from '~~/utils/utils'
import { caratFormatter, tcwFormatter } from '~~/utils/formatters/index.js'
import { host } from '~~/utils/definitions/defaults.js'
import { settingsByCategory } from '~~/utils/sitemapUtils.js'

const jewelry = [
  'Earring',
  'Pendant',
  'Necklace',
  'Bracelet',
  'Ring',
  'Bangle',
  'Brooch',
  'Cufflink'
]

const stones = ['Stone', 'Pair']

const bands = ['Plain Band', 'Wedding Band']

const settingsTypesMap = {
  Setting_Ring: 'Ring',
  Setting_Earring: 'Earring',
  Setting_Bracelet: 'Bracelet',
  Setting_Pendant: 'Pendant',
  Setting_Necklace: 'Necklace'
}

const productPages = [
  'design-your-own-setting-ruby-engagement-ring-settings-id',
  'design-your-own-setting-ruby-necklace-pendant-settings-id',
  'design-your-own-setting-ruby-earring-settings-id',
  'design-your-own-setting-ruby-bracelet-settings-id',
  'design-your-own-setting-mens-ring-settings-id',
  'design-your-own-review-id',
  'ruby-jewelry-id',
  'rubies-id',
  'rubies-matched-pairs-of-rubies-id',
  'wedding-bands-without-gemstone-id',
  'ruby-wedding-rings-bands-id',
  'plain-wedding-rings-bands-id',
  'blue-sapphire-wedding-rings-bands-id',
  'blue-sapphire-diamond-wedding-rings-bands-id',
  'pink-sapphire-wedding-rings-bands-id',
  'pink-sapphire-diamond-wedding-rings-bands-id',
  'diamond-wedding-rings-bands-id',

  'design-your-own-setting-emerald-engagement-ring-settings-id',
  'design-your-own-setting-emerald-necklace-pendant-settings-id',
  'design-your-own-setting-emerald-ruby-earring-settings-id',
  'design-your-own-setting-emerald-bracelet-settings-id',
  'emerald-jewelry-id',
  'emeralds-id',
  'emeralds-matched-pairs-of-emeralds-id',
  'emerald-wedding-rings-bands-id'
]

const defaultTitle = ''
const defaultDescription = ''
const defaultPageTitle = ''

export function getMeta(context) {
  const title = pageTitle.call(context)

  const meta = [
    {
      name: 'title',
      content: title
    },
    {
      name: 'description',
      content: pageDescription.call(context)
    },
    {
      name: 'navigation-page-title',
      content: navPageTitle.call(context)
    },
    {
      name: 'navigation-page-description',
      content: navPageDescr.call(context)
    }
  ]

  if (productPages.includes(routeName.call(context))) {
    /* Twitter */
    meta.push({
      name: 'twitter:card',
      content: 'summary'
    })
    /* !Twitter */
    /* Pinterest */
    meta.push({
      property: 'product:price:amount',
      content: getProductPrice.call(context)
    })
    meta.push({
      property: 'product:price:currency',
      content: 'USD'
    })
    meta.push({
      property: 'og:image_url',
      content: productImage.call(context)
    })
    /* !Pinterest */
    meta.push({
      property: 'og:url',
      content: pageLink.call(context)
    })
    meta.push({ property: 'og:type', content: 'product' })
    meta.push({ property: 'og:title', content: title })
    meta.push({
      property: 'og:description',
      content: pageDescription.call(context)
    })
    meta.push({
      property: 'og:image',
      content: productImage.call(context)
    })
    meta.push({
      property: 'fb:app_id',
      content: process.env.fbAppId
    })
  } else {
    meta.push({
      property: 'og:image',
      content: host[process.env.siteName] + 'img/logo.jpg'
    })
  }

  return {
    meta,
    title
  }
}

function routeName() {
  let routeName = normRouteName(this.route.name)
  if (this.route.params.dynamicListing)
    routeName = routeName.replace(
      'dynamicListing',
      this.route.params.dynamicListing
    )
  return routeName
}

function pageDescription() {
  const route = routeName.call(this)
  if (route === 'design-your-own-review-id') {
    const product = this.store.getters['customItem/customItem']
    const description =
      product.preview?.productDescription ||
      [
        product.customStone.productDescription,
        product.customSetting.productDescription
      ]
        .join(' ')
        .trim()
    if (description) return removeTagsFromString(description)
  } else {
    const product = this.store.getters['productDetails/productDetails']
    if (product.productDescription) return product.productDescription
  }

  return (
    this.app.i18n.t(
      `metadata.${routeName.call(this)}.seo.description`,
      pageData.call(this)
    ) || defaultDescription
  )
}

function navPageTitle() {
  return (
    this.app.i18n.t(
      `metadata.${routeName.call(this)}.navPageTitle`,
      pageData.call(this)
    ) || defaultPageTitle
  )
}

function navPageDescr() {
  if (this.app.i18n.te(`metadata.${routeName.call(this)}.navPageDescr`))
    return this.app.i18n.t(
      `metadata.${routeName.call(this)}.navPageDescr`,
      pageData.call(this)
    )
  return ''
}

function pageLink() {
  if (!productPages.includes(routeName.call(this))) return ''
  return `${process.env.host}${this.route.path}`
}

function pageTitle() {
  return (
    this.app.i18n.t(
      `metadata.${routeName.call(this)}.seo.title`,
      pageData.call(this)
    ) || defaultTitle
  )
}

function pageData() {
  const route = routeName.call(this)
  const product = this.store.getters['productDetails/productDetails']
  if (route === 'design-your-own-review-id') {
    // custom item
    return getCustomItemMeta.call(this)
  }
  if (settingsByCategory.includes(product.category)) {
    return getSettingsMeta.call(this)
  }
  if (jewelry.includes(product.category)) {
    return getJewelryMeta.call(this)
  }
  if (stones.includes(product.category)) {
    return getStonesMeta.call(this)
  }
  if (bands.includes(product.category)) {
    return getBandsMeta.call(this)
  }
  if (route === 'featured-designers-name') {
    // custom item
    return getDesignersMeta.call(this)
  }
  if (route === 'featured-designers-name-id') {
    // DMTO item
    return getDmtoMeta.call(this)
  }
  return {}
}

function getDesignersMeta() {
  const designer = this.store.state.designers.designerDetails

  return {
    designer: designer.texts?.introducing,
    designerDescription: designer.texts?.aboutDesigner
  }
}

function getDmtoMeta() {
  const item = this.store.getters['customItem/customItem']

  return {
    itemName: item.designer.itemName,
    designer: item.designer.designerName,
    description:
      item.preview.productDescription || item.customStone.productDescription
  }
}

function getSettingsMeta() {
  const product = this.store.getters['productDetails/productDetails']
  const type = this.app.i18n.t(
    `categories.${camelCase(settingsTypesMap[product.category])}`
  )
  const metal = this.app.i18n.t(
    `detailsPage.metalTypesNames.${camelCase(product.metalName)}`
  )
  const s = product.styleNames?.some((s) => s.includes("Men's"))
    ? "Men's"
    : get(product, 'styleNames[0]', 'none')
  const style = s
    ? this.app.i18n.t(
        `detailsPage.designStylesFilter.${camelCase(s || 'none')}`
      )
    : ''
  const { id, metalTypeCode } = product
  return { metal, type, id, style, metalTypeCode }
}

function getJewelryMeta() {
  const product = this.store.getters['productDetails/productDetails']
  const type = this.app.i18n.t(`categories.${camelCase(product.category)}`)
  const carat = calculateTotalWeight(product)
  const shape = product.centerStones[0].shape
  const id = product.id
  const metal = this.app.i18n.t(
    `detailsPage.metalTypesNames.${camelCase(product.metal.value)}`
  )
  const stoneMineType =
    product.stoneType || get(product, 'centerStones[0].stoneType', '')
  const stoneSubType =
    product.stoneSubType || get(product, 'centerStones[0].stoneSubType', '')
  const stoneType = stoneSubType || stoneMineType
  return { carat, id, type, metal, shape, stoneType }
}

function getStonesMeta() {
  const product = this.store.getters['productDetails/productDetails']
  const origin = product.origin || get(product, 'stones[0].origin', '')
  const color = product.color || get(product, 'stones[0].color', '')
  const carat = calculateTotalWeight(product)
  const shape = product.shape || get(product, 'stones[0].shape', '')
  const stoneMineType =
    product.stoneType || get(product, 'stones[0].stoneType', '')
  const stoneSubType =
    product.stoneSubType || get(product, 'stones[0].stoneSubType', '')
  const stoneType = stoneSubType || stoneMineType
  const id = product.id
  return {
    color,
    carat: Number(carat).toFixed(2),
    id,
    origin,
    shape,
    stoneType
  }
}

function getBandsMeta() {
  const product = this.store.getters['productDetails/productDetails']
  const metal = this.app.i18n.t(
    `detailsPage.metalTypesNames.${camelCase(product.metalName)}`
  )
  const { id, metalTypeCode } = product
  return { metal, id, metalTypeCode }
}

function getCustomItemMeta() {
  const stone = this.store.getters['customItem/customStone']
  const setting = this.store.getters['customItem/customSetting']
  const metalName = setting.metalName
  const stoneMineType = stone.stoneType || get(stone, 'stones[0].stoneType', '')
  const stoneSubType =
    stone.stoneSubType || get(stone, 'stones[0].stoneSubType', '')
  const stoneType = stoneSubType || stoneMineType
  const category = setting.category?.replace('Setting_', '').toLowerCase()
  const weight = calculateTotalWeight(stone)
  const weightFormatter = category === 'earring' ? tcwFormatter : caratFormatter
  const style = setting.styleNames?.some((s) => s.includes("Men's"))
    ? "Men's"
    : 'none'
  return {
    style: this.app.i18n.t(
      `detailsPage.designStylesFilter.${camelCase(style || 'none')}`
    ),
    stoneType,
    metalName: this.app.i18n.t(
      `detailsPage.metalTypesNames.${camelCase(metalName)}`
    ),
    weight: weightFormatter.call(this, weight),
    category: this.app.i18n.t(`categories.${category}`),
    fullType: this.app.i18n.t(`breadcrumbs.review.${category}`)
  }
}

function productImage() {
  const isCustomReview = /design-your-own-review/.test(this.route.name)

  const previewThumbnails = this.store.getters['customItem/previewThumbnails']
  const settingThumbnails = this.store.getters['customItem/settingThumbnails']
  const productThumbnails =
    this.store.getters['productDetails/productThumbnails']

  const customItemThumbnailsSource = previewThumbnails.length
    ? previewThumbnails
    : settingThumbnails
  const thumbnails = isCustomReview
    ? customItemThumbnailsSource
    : productThumbnails
  return get(thumbnails, '[0].large', '')
}

function getProductPrice() {
  const isCustomReview = /design-your-own-review/.test(this.route.name)
  if (isCustomReview) {
    return this.store.getters['customItem/finalPrice'].totalPrice
  }
  return this.store.getters['productDetails/productDetails'].price
}
