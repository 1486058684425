import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import LIST from './constants'
import { lists } from './state.js'
import { getItemId } from '~~/utils/utils'
import { itemNameFormatterNoLocale } from '~~/utils/formatters'
import {
  bandsByCategory,
  productListTypeByCategory,
  companyName
} from '~~/utils/definitions/defaults'

export default {
  bindNamespace(state, name) {
    state._namespace = name
  },

  [LIST.SET_HASH](state, hash) {
    state.shareHash = hash
  },

  [LIST.RESET_HASH](state) {
    state.shareHash = ''
  },

  [LIST.SET_SHARED_ITEMS_FETCHED](state) {
    state.sharedItemsFetched = true
  },

  [LIST.RESET_SHARED_ITEMS_FETCHED](state) {
    state.sharedItemsFetched = false
  },

  [LIST.FETCHING_ITEMS](state, bool) {
    state.fetchingItems = bool
  },

  [LIST.REMOVE_PLACEHOLDERS](state, items) {
    const fetchedItems = items.map((item) => item.guid)
    Object.keys(state).forEach((key) => {
      if (Array.isArray(state[key]) && key.includes('Items'))
        state[key] = state[key].filter(
          (item) => !item.placeholder || !fetchedItems.includes(item.guid)
        )
    })
  },

  [LIST.MARK_ALL_AS_NOT_SYNCED](state) {
    Object.keys(state).forEach((key) => {
      const listing = state[key]
      if (Array.isArray(listing) && key.includes('Items')) {
        listing.forEach((item) => {
          item.serverSync = false
        })
      }
    })
  },

  [LIST.MARK_ALL_AS_SYNCED](state) {
    Object.keys(state).forEach((key) => {
      const listing = state[key]
      if (Array.isArray(listing) && key.includes('Items')) {
        listing.forEach((item) => {
          delete item.itemToSync
        })
      }
    })
  },

  [LIST.ADD_BASE_ITEMS](state, items) {
    items.forEach((item) => {
      const productList = productListTypeByCategory[item.category]
      if (state[`${productList}Items`].some((i) => i.guid === item.guid)) return
      const index = state[`${productList}Items`].findIndex(
        (i) => i.id === item.id
      )
      if (index > -1) {
        Vue.set(state[`${productList}Items`], index, item)
        return
      }
      state[`${productList}Items`].push(helper.addDate(item))
    })
  },

  [LIST.UPDATE_PRICES](state, prices) {
    prices.forEach((price) => {
      const productList = productListTypeByCategory[price.category]
      const items = state[`${productList}Items`]

      const index = items.findIndex((i) => i.id === price.publicId)

      if (index > -1) {
        const item = {
          ...items[index],
          ...price
        }
        Vue.set(items, index, item)
      }
    })
  },

  [LIST.CHANGE_ITEMS_ORDER](state, { type, value }) {
    state[`${type}Items`] = value
  },

  [LIST.ADD_ITEM]: addItem,

  [LIST.ADD_ITEM_SILENT]: addItem,

  [LIST.UPDATE_ITEM]: updateItem,

  [LIST.UPDATE_ITEM_SILENT]: updateItem,

  [LIST.REPLACE_ITEM_SILENT]: replaceItem,

  [LIST.REPLACE_ITEM](state, { item }) {
    const type = productListTypeByCategory[item.category]
    const index = state[`${type}Items`].findIndex(
      (i) => getItemId(i) === getItemId(item)
    )
    const extendItem = { ...item, addedAt: new Date() }
    Vue.set(state[`${type}Items`], index, extendItem)
  },

  [LIST.REMOVE_ITEM]: removeItem,

  [LIST.REMOVE_ITEM_SILENT]: removeItem, // REMOVE_ITEM_SILENT - dedicated mutation for quick item removing notification. See: layouts/default.vue

  [LIST.SET_SETTING_OPTIONS](state, { type, id, options }) {
    const index = state[`${type}Items`].findIndex((el) => el.id === id)
    if (index > -1) {
      const keys = Object.keys(options)
      keys.forEach((key) => {
        Vue.set(state[`${type}Items`][index].selectedOptions, key, options[key])
      })
    }
  },

  [LIST.UPDATE_CUSTOM_ITEM_PRICE](state, { id, price, type }) {
    const index = state[`${type}Items`].findIndex((el) => el.id === id)
    if (index > -1) {
      Vue.set(state[`${type}Items`][index], 'finalPrice', price)
    }
  },

  [LIST.SET_FINAL_OPTIONS](state, { item, finalOptions }) {
    const type = productListTypeByCategory[item.category]
    const index = state[`${type}Items`].findIndex((el) => el.id === item.id)
    if (index > -1) {
      if (isEmpty(state[`${type}Items`][index].selectedOptions))
        state[`${type}Items`][index].selectedOptions =
          state[`${type}Items`][index].customSetting.selectedOptions
      state[`${type}Items`][index].customStone =
        finalOptions.pair || finalOptions.stone
      state[`${type}Items`][index].customSetting = finalOptions.setting
      state[`${type}Items`][index].sideStones = finalOptions.sideStoneOptions
    }
  },

  [LIST.SET_SIDE_STONE_OPTIONS](state, { type, id, index, value, sideType }) {
    const indexInList = state[`${type}Items`].findIndex((el) => el.id === id)
    if (indexInList > -1) {
      if (!state[`${type}Items`][indexInList].selectedOptions.sideStoneOptions)
        Vue.set(
          state[`${type}Items`][indexInList].selectedOptions,
          'sideStoneOptions',
          []
        )
      Vue.set(
        state[`${type}Items`][indexInList].selectedOptions.sideStoneOptions[
          index
        ],
        sideType,
        value
      )
    }
  },

  [LIST.CLEAR_LIST](state) {
    ;[...Object.keys(lists), 'waxItems'].forEach((list) => {
      state[list] = []
    })
  }
}

export const helper = {
  type: '',

  getType(item) {
    this.type = [2, 3].includes(item.dataType)
      ? 'custom'
      : productListTypeByCategory[item.category]
    return this.type
  },

  getIndex(state, item, by = 'id') {
    this.getType(item)
    return state[`${this.type}Items`].findIndex(
      (el) => getItemId(el, by) === getItemId(item, by)
    )
  },

  addDate(item) {
    return { ...item, addedAt: new Date() }
  },

  markToSync(item) {
    return { ...item, itemToSync: true }
  },

  gtag: {
    addItem(state, item) {
      if (!Vue.$gtag) return
      Vue.$gtag.event(`add_to_${state._namespace}`, {
        event_label: `Add item to ${state._namespace}`,
        currency: 'USD',
        value: get(item, 'finalPrice.totalPrice', item.price),
        items: [
          {
            id: item.id,
            name: itemNameFormatterNoLocale(item),
            category: item.category,
            brand: companyName[process.env.siteName],
            quantity: 1,
            price: String(get(item, 'finalPrice.totalPrice', item.price))
          }
        ]
      })
    },
    removeItem(state, item) {
      if (!Vue.$gtag) return
      Vue.$gtag.event(`remove_from_${state._namespace}`, {
        event_label: `Remove item from ${state._namespace}`,
        currency: 'USD',
        value: get(item, 'finalPrice.totalPrice', item.price),
        items: [
          {
            id: item.id,
            name: itemNameFormatterNoLocale(item),
            category: item.category,
            brand: companyName[process.env.siteName],
            quantity: 1,
            price: String(get(item, 'finalPrice.totalPrice', item.price))
          }
        ]
      })
    }
  }
}

function addItem(state, { item }) {
  helper.gtag.addItem(state, item)
  const type = helper.getType(item)
  let listItem = helper.addDate(item)

  if (!this.state.auth.loggedIn) listItem = helper.markToSync(listItem)

  const index = helper.getIndex(state, item)
  if (index > -1) {
    Vue.set(state[`${type}Items`], index, listItem)
    return
  }
  state[`${type}Items`].push(listItem)
}

function updateItem(state, { item }) {
  const type = helper.getType(item)
  const byGuid = [...bandsByCategory, 'Custom'].includes(item.category)
    ? 'guid'
    : 'id'
  let listItem = helper.addDate(item)
  if (!this.state.auth.loggedIn) listItem = helper.markToSync(listItem)

  const index = helper.getIndex(state, listItem, byGuid)
  if (index > -1) {
    Vue.set(state[`${type}Items`], index, listItem)
  }
}

function replaceItem(state, { item }) {
  const type = helper.getType(item)
  const index = helper.getIndex(state, item, 'guid')
  if (index > -1) {
    Vue.set(state[`${type}Items`], index, helper.addDate(item))
  }
}

function removeItem(state, { item }) {
  helper.gtag.removeItem(state, item)

  const type = helper.getType(item)
  const index = helper.getIndex(state, item)
  if (index > -1) state[`${type}Items`].splice(index, 1)
}
