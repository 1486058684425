export default class onDemand {
  // src - the full url to load
  // waitForPageLoad - still wait for the page load event on first hit
  constructor({ script, css }, opt = {}) {
    const defaultOptions = {
      waitForPageLoad: true,
      async: true,
      body: false
    }

    this.options = Object.assign(defaultOptions, opt)

    this.scriptsLoaded = 0
    this.isLoading = false
    this.callbacks = []
    this.src = script
    this.css = css
  }

  get isLoaded() {
    return this.scriptsLoaded === this.scriptsCount
  }

  get scriptsCount() {
    if (Array.isArray(this.src)) return this.src.length
    return 1
  }

  load(callback = () => {}) {
    if (this.isLoaded) return callback()

    this.callbacks.push(callback)

    if (!this.isLoading) {
      this.isLoading = true
      if (!this.options.waitForPageLoad || document.readyState === 'complete')
        this._loadScript()
      else window.addEventListener('load', () => this._loadScript())
    }
  }

  _addLink(src) {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = src
    document.getElementsByTagName('head')[0].prepend(link)
  }

  _addScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      const tag = this.options.body ? 'body' : 'head'
      script.src = src
      script.async = this.options.async
      script.onload = () => {
        resolve()
        this._invokeCallbacks(src)
      }
      document.getElementsByTagName(tag)[0].appendChild(script)
    })
  }

  async _loadScript() {
    if (Array.isArray(this.src)) {
      for (const src of this.src) {
        await this._addScript(src)
      }
    } else {
      this._addScript(this.src)
    }

    if (this.css && this.css.length) {
      this.css.forEach((src) => {
        this._addLink(src)
      })
    }
  }

  _invokeCallbacks() {
    this.scriptsLoaded++
    if (this.isLoaded) this.callbacks.forEach((callback) => callback())
  }
}
