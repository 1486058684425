import md5 from './md5.js'

const apiSecretKey = process.env.isDev
  ? 'Aa48259b9Bd849b7A063+591348d0EA-c'
  : 'Ae12GbaG3dbP3B05D83+0439fc5e-bd91'

export default function addSecurityHeaders(request) {
  const timestamp = Date.now()
  const nonce = getNonce(3)
  const query = getQuery(request)
  const signature = md5(timestamp + nonce + query + apiSecretKey)

  const headers = {
    timestamp,
    nonce,
    signature
  }

  if (request.headers?.common) {
    request.headers.common = {
      ...request.headers.common,
      ...headers
    }
    return
  }

  return headers
}

function getQuery(request) {
  if (request.method === 'get') return request.url.split('?')[1] || ''
  return JSON.stringify(request.data)
}

function getNonce(length) {
  return Array(length)
    .fill()
    .map(Math.random)
    .map((s) => s.toString(36).slice(2))
    .join('')
}
