import { guid } from '~~/utils/utils'

export const OUR_STAFF = {
  SET_TEAMS: 'SET_TEAMS',
  SET_MEMBER: 'SET_MEMBER',
  FLUSH_MEMBER: 'FLUSH_MEMBER'
}

export const state = () => ({
  teams: [],
  member: {}
})

export const getters = {
  teams: (store) => store.teams,
  member: (store) => store.member
}

export const actions = {
  async getTeams({ commit }) {
    const result = await this.$api.ourStaff.fetchTeams()
    commit(OUR_STAFF.SET_TEAMS, result || [])
  },

  async getMemberDetails({ commit }, { bio, id }) {
    const result = await this.$api.ourStaff.fetchMember({ bio })
    result.id = id
    commit(OUR_STAFF.SET_MEMBER, result)
  },

  flushMember({ commit }) {
    commit(OUR_STAFF.FLUSH_MEMBER)
  }
}

export const mutations = {
  [OUR_STAFF.SET_TEAMS](state, teams) {
    state.teams = teams.map((team) => {
      team.members = team.members.map((member) => {
        member.id = guid()
        return member
      })
      return team
    })
  },

  [OUR_STAFF.SET_MEMBER](state, member) {
    state.member = member
  },

  [OUR_STAFF.FLUSH_MEMBER](state, member) {
    state.member = member
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
