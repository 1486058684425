import cloneDeep from 'lodash/cloneDeep'
import actions from '../_base/actions'
import { ITEMS_LIST } from '../_base/constants'
import { normalizeItemsListData } from '~~/utils/normalizers'
import { serializeFilter } from '~~/utils/serializators'
import {
  defaultPageSize,
  defaultSettingsQuery
} from '~~/utils/definitions/defaults'

export default {
  ...cloneDeep(actions),

  async getPage(
    { commit, rootState, rootGetters, state, getters, dispatch },
    params
  ) {
    if (await dispatch('skipFetch', params)) return {}

    commit(ITEMS_LIST.SET_LOADING, true)
    commit(ITEMS_LIST.ADD_TO_LOADING_LIST, params.pageNumber)
    const query = serializeFilter(
      state._namespace,
      rootState.filters,
      params,
      rootGetters
    )

    const pageQuery = {
      ...cloneDeep(defaultSettingsQuery),
      pageNumber: params.pageNumber,
      pageSize: params.pageSize || defaultPageSize
    }

    // For specific design listing pages
    if (params.style) pageQuery.query.styles[0] = params.style

    const listData = await this.$api.listings.fetchSettingsList({
      ...pageQuery,
      ...{ query }
    })

    if (params.beforeAddItemsCallback) params.beforeAddItemsCallback()

    const normalizedData = normalizeItemsListData(listData, params.pageNumber)

    // if (params.pageNumber > normalizedData.pageCount) {
    //   commit(ITEMS_LIST.SET_LOADING, false)
    //   return normalizedData
    // }

    if (params.force) commit(ITEMS_LIST.DROP_ITEMS_LIST)
    commit(ITEMS_LIST.GET_ITEMS_LIST, normalizedData)

    if (params.afterAddItemsCallback) params.afterAddItemsCallback()

    commit(ITEMS_LIST.SET_LOADING, false)
    commit(ITEMS_LIST.REMOVE_FROM_LOADING_LIST, params.pageNumber)

    return normalizedData
  },

  skipFetch({ state, getters, rootGetters }, params) {
    if (params.force) return false
    if (rootGetters['filters/dirtyLists']) return false
    if (!params.pageNumber) return true // 0 or null
    if (state.loadingPages.includes(params.pageNumber)) return true // page already in loading process
    return !!getters.loadedPages.includes(params.pageNumber) // page already loaded
  }
}
