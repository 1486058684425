import Vue from 'vue'
import flatten from 'lodash/flatten'
import omit from 'lodash/omit'
import cloneDeep from 'lodash/cloneDeep'
import { normalizeFiltersResponse } from '~~/utils/normalizers'
import { bands, jewelry, settings, stones } from '~~/utils/definitions/defaults'
import {
  bandsFilters,
  fetchRootFiltersStore,
  jewelryFilters,
  rootFiltersStore,
  settingsFilters,
  stonesFilters,
  urlToStoreFilters
} from '~~/utils/definitions/filters'

export const FILTERS = {
  SET_AREA_FILTERS: 'SET_AREA_FILTERS',
  SET_FILTERS: 'SET_FILTERS',
  CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
  CLEAR_ORIGIN_FILTER: 'CLEAR_ORIGIN_FILTER',
  CLEAR_STYLES_FILTER: 'CLEAR_STYLES_FILTER',
  CHANGE_PREFILTER_STATE: 'CHANGE_PREFILTER_STATE',
  SET_DIRTY_LISTS: 'SET_DIRTY_LISTS',
  CLEAN_DIRTY_LISTS: 'CLEAN_DIRTY_LISTS',
  SET_DATA_TYPE: 'SET_DATA_TYPE',
  REMOVE_SELECTED_FILTER: 'REMOVE_SELECTED_FILTER',
  CLEAR_ROOT_FILTERS: 'CLEAR_ROOT_FILTERS',
  CLEAR_SOURCE_FILTERS: 'CLEAR_SOURCE_FILTERS',
  SET_ROOT_SELECTED_FILTERS: 'SET_ROOT_SELECTED_FILTERS',
  ADD_ROOT_SELECTED_FILTERS: 'ADD_ROOT_SELECTED_FILTERS',
  REMOVE_CHILD_FILTERS: 'REMOVE_CHILD_FILTERS'
}

/**
 * All existed item types
 * @type {string[]}
 */
const itemsTypes = [...stones, ...settings, ...jewelry, ...bands]

const lists = {
  ringsList: {},
  stonesList: {},
  stonePairsList: {},
  earringsList: {},
  necklacesPendantsList: {},
  braceletsList: {},
  broochesList: {},
  cufflinksList: {},
  weddingBandsPlain: {},
  weddingBands: {},
  ringSettings: {},
  earringSettings: {},
  braceletSettings: {},
  pendantSettings: {},
  necklaceSettings: {}
}

function getFiltersQuery(params, defaultQuery, rootGetters) {
  const queryString = getCommonFiltersQuery(...arguments)

  const {
    preset,
    origin,
    isStar,
    cuttingstyle,
    webCategory,
    stoneColors,
    stoneTypes
  } = params

  if (preset) queryString.preset = preset

  if (stoneColors && stoneColors.length)
    queryString.colorCategories = stoneColors
  if (stoneTypes && stoneTypes.length) queryString.stoneCategories = stoneTypes
  // if (stoneTypes) queryString.stoneTypes = stoneTypes
  if (origin) queryString.origin = origin
  if (isStar) queryString.isStar = isStar
  if (webCategory) queryString.webCategory = webCategory
  if (cuttingstyle) queryString.cuttingstyle = cuttingstyle

  return queryString
}

function getCommonFiltersQuery(params, defaultQuery) {
  const { query, preset } = params

  const { filterType = 'standard', filterValues = [] } = query

  const queryString = cloneDeep(defaultQuery)
  queryString.filter = [filterType]

  if (preset) queryString.preset = preset

  if (!['standard', 'advanced'].includes(filterType))
    queryString[filterType] = filterValues

  return queryString
}

export const state = () => ({
  usePrefilter: true,
  dataType: {
    braceletsList: 0,
    ringsList: 0,
    earringsList: 0,
    necklacesPendantsList: 0
  },
  dirtyLists: false,
  selectedFilters: cloneDeep(lists),
  sourceFilters: cloneDeep(lists),
  rootSelectedFilters: cloneDeep(lists)
})

export const getters = {
  usePrefilter: (state, getters, rootState, rootGetters) => {
    if (rootGetters['customItem/customItem'].dataType === 3) return false
    return state.usePrefilter
  },
  dirtyLists: (state) => state.dirtyLists,

  hasFilters: (state) => (listType) => {
    const values = Object.values(
      omit(state.selectedFilters[listType], ['sortBy', 'order'])
    )
    return !!flatten(values).length
  },

  selectedFilters: (state) => (listType) => {
    return state.selectedFilters[listType]
  },

  // selectedFilters: (state) => (listType) => {
  //   const isRangeFilterValid = (f, v) => {
  //     if (/Range/.test(f)) {
  //       const dimension = state.sourceFilters[listType].dimensions?.[f]
  //       if (['lengthRange', 'widthRange'].includes(f) && dimension) {
  //         const { min, max } = dimension.values
  //         return inRange(v, min, max)
  //       }
  //       const filter = state.sourceFilters[listType][f]
  //       if (filter?.values) {
  //         const { min, max } = filter.values
  //         return inRange(v, min, max)
  //       }
  //     }
  //     return false
  //   }
  //
  //   const isFilterValid = (f, v) => {
  //     if (['sortBy', 'order'].includes(f)) return true
  //     if (isRangeFilterValid(f, v)) return true
  //
  //     const filterValues = state.sourceFilters[listType][f]?.values
  //     return filterValues
  //       ? filterValues.map((s) => s.key || s.name).includes(v)
  //       : false
  //   }
  //
  //   return Object.keys(state.selectedFilters[listType]).reduce((acc, f) => {
  //     acc[f] = state.selectedFilters[listType][f].filter((v) =>
  //       isFilterValid(f, v)
  //     )
  //     return acc
  //   }, {})
  // },

  subFiltersByType: (state) => (listType, filterType) => {
    if (!filterType) return []
    const sourceFilters = state.sourceFilters[listType]
    const filters = []
    Object.keys(sourceFilters).forEach((f) => {
      if (sourceFilters[f].filterType === filterType) filters.push(f)
    })
    return filters
  },

  selectedRootFilters: (state) => (listType, filterName) => {
    return state.rootSelectedFilters[listType][filterName] || []
  },

  /**
   * Get a map of selected sub filters grouped by parent filters.
   * Example:
   * {
   *   "Green": [
   *     { count: 1, name: "Green", parent: ["Green"] },
   *     { count: 1, name: "Bluish Green", parent: ["Green", "Blue"] }
   *   ]
   * }
   * @param {string} listType - list type "stonesList" for example
   * @returns {Object} a map of selected sub filters grouped by parent filters
   */
  getSelectedSubFilters: (state) => (listType) => {
    const selectedFilters = state.selectedFilters[listType]
    const sourceFilters = state.sourceFilters[listType]
    const selectedFiltersMap = {}
    Object.keys(selectedFilters).forEach((f) => {
      if (!sourceFilters[f]) return
      if (!sourceFilters[f].subFilters?.length) return // skip not root filters
      sourceFilters[f].subFilters.forEach((sf) => {
        selectedFilters[f].forEach((selF) => {
          if (!selectedFilters[sf]) return
          selectedFiltersMap[selF] = []
          selectedFilters[sf].forEach((v) => {
            const val = sourceFilters[sf].values.find(
              (s) => s.name === v && s.parent.includes(selF)
            )
            if (val) selectedFiltersMap[selF].push(val)
          })
        })
      })
    })
    return selectedFiltersMap
  },

  /**
   * Filters received from server
   */
  ...(function () {
    const filters = {}
    itemsTypes.forEach((type) => {
      filters[`${type}SourceFilters`] = (store) => store.sourceFilters[type]
    })
    return filters
  })()
}

export const actions = {
  removeSelectedFilter({ commit }, params) {
    commit(FILTERS.REMOVE_SELECTED_FILTER, params)
  },

  addRootSelectedFilters({ commit }, p) {
    const params = Array.isArray(p) ? p : [p]
    params.forEach((param) => commit(FILTERS.ADD_ROOT_SELECTED_FILTERS, param))
    // commit(FILTERS.SET_ROOT_SELECTED_FILTERS, params)
  },

  setRootSelectedFilters({ commit }, p) {
    const params = Array.isArray(p) ? p : [p]
    params.forEach((param) => commit(FILTERS.SET_ROOT_SELECTED_FILTERS, param))
  },

  setAreasFilters({ commit }, params) {
    commit(FILTERS.SET_AREA_FILTERS, params)
  },

  setDataType({ commit }, { listType, value }) {
    commit(FILTERS.SET_DATA_TYPE, { listType, value })
  },

  disablePrefilter({ commit, getters }) {
    commit(FILTERS.CHANGE_PREFILTER_STATE, { result: false, getters })
  },

  enablePrefilter({ commit, getters }) {
    commit(FILTERS.CHANGE_PREFILTER_STATE, { result: true, getters })
  },

  setDirtyLists({ commit }) {
    commit(FILTERS.SET_DIRTY_LISTS)
  },

  cleanDirtyLists({ commit }) {
    commit(FILTERS.CLEAN_DIRTY_LISTS)
  },

  async fetchFilters({ dispatch }, params) {
    const { itemsType } = params
    await dispatch(`${itemsType}FetchFilters`, params)
    await dispatch('checkForAdvancedFilters', {
      ...params,
      query: { ...params.query, filterType: 'advanced' }
    })
  },

  async checkForAdvancedFilters({ dispatch, state }, params) {
    const { itemsType } = params

    const filtersFromRoute = Object.keys(this.app.router.currentRoute.query)
      .map((f) => urlToStoreFilters[f])
      .filter(Boolean)
    // const currentlyFetchedFilters = Object.keys(state.sourceFilters[itemsType])

    const sourceFilters = state.sourceFilters[itemsType]
    const standardFilters = []
    Object.keys(state.sourceFilters[itemsType]).forEach((f) => {
      if (sourceFilters[f].filterType === 'standard') {
        standardFilters.push(sourceFilters[f].filterName)
        sourceFilters[f].subFilters.forEach((sf) => {
          standardFilters.push(sf)
        })
      }
    })

    const needAdvancedFilters = filtersFromRoute.some(
      (f) => !standardFilters.includes(f)
    )

    if (this.$device.isMobile || (process.server && needAdvancedFilters)) {
      await dispatch(`${itemsType}FetchFilters`, params)
      // await dispatch('fetchChildFilters', { params, filtersFromRoute })
    }
  },

  // async fetchChildFilters({ getters, dispatch }, { params, filtersFromRoute }) {
  //   const paramsCopy = cloneDeep(params)
  //   const { itemsType } = paramsCopy

  //   for (let i = 0; i < filtersFromRoute.length; i++) {
  //     if (rootFiltersStore.includes(filtersFromRoute[i])) {
  //       paramsCopy.query = {
  //         filterType: filtersFromRoute[i],
  //         filterValues: getters.selectedRootFilters(
  //           itemsType,
  //           filtersFromRoute[i]
  //         )
  //       }
  //       await dispatch(`${itemsType}FetchFilters`, paramsCopy)
  //     }
  //   }
  // },

  removeAllChildFilters({ commit, state, dispatch }) {
    Object.keys(state.sourceFilters).forEach((itemsType) =>
      dispatch('removeChildFilters', { itemsType })
    )
  },

  removeChildFilters({ commit }, params) {
    commit(FILTERS.REMOVE_CHILD_FILTERS, params)
  },

  async stonesListFetchFilters(
    { dispatch, commit, getters, rootState, rootGetters },
    params
  ) {
    const { usePrefilter } = getters
    const {
      itemsType,
      query: { filterType = 'standard' }
    } = params

    const q = getFiltersQuery(params, stonesFilters, rootGetters)

    if (usePrefilter) {
      const customSettingId =
        rootState.customItem.customSetting.id ||
        this.$cookies.get('customSetting')
      q.settingId = customSettingId
    }

    const filters = await this.$api.filters.fetchStonesFilters(q)

    if (params.flushFilters) dispatch('clearSourceFilters', itemsType)

    commit(FILTERS.SET_FILTERS, {
      filters: normalizeFiltersResponse(filters, filterType),
      itemsType,
      filterType
    })
  },

  async stonePairsListFetchFilters(
    { dispatch, commit, getters, rootState, rootGetters },
    params
  ) {
    const { usePrefilter } = getters
    const {
      itemsType,
      query: { filterType = 'standard' }
    } = params

    const query = getFiltersQuery(params, stonesFilters, rootGetters)

    if (usePrefilter) {
      const customSettingId =
        rootState.customItem.customSetting.id ||
        this.$cookies.get('customSetting')
      query.settingId = customSettingId
    }

    const filters = await this.$api.filters.fetchPairsFilters(query)

    if (params.flushFilters) dispatch('clearSourceFilters', itemsType)

    commit(FILTERS.SET_FILTERS, {
      filters: normalizeFiltersResponse(filters, filterType),
      itemsType,
      filterType
    })
  },

  async ringSettingsFetchFilters(
    { dispatch, commit, getters, rootState },
    params
  ) {
    const { usePrefilter } = getters
    const {
      itemsType,
      query: { filterType = 'standard' }
    } = params

    const query = getCommonFiltersQuery(params, settingsFilters)
    if (usePrefilter) {
      const customStoneId =
        rootState.customItem.customStone.id || this.$cookies.get('customStone')
      query.stoneId = customStoneId
    }

    query.category = params.category || 'ring'
    query.style = params.style || null

    const filters = await this.$api.filters.fetchSettingsFilters(query)

    if (params.flushFilters) dispatch('clearSourceFilters', itemsType)

    commit(FILTERS.SET_FILTERS, {
      filters: normalizeFiltersResponse(filters, filterType),
      itemsType,
      filterType
    })
  },

  earringSettingsFetchFilters({ dispatch }, params) {
    dispatch('ringSettingsFetchFilters', { ...params, category: 'earring' })
  },

  braceletSettingsFetchFilters({ dispatch }, params) {
    dispatch('ringSettingsFetchFilters', { ...params, category: 'bracelet' })
  },

  pendantSettingsFetchFilters({ dispatch }, params) {
    dispatch('ringSettingsFetchFilters', { ...params, category: 'pendant' })
  },

  necklaceSettingsFetchFilters({ dispatch }, params) {
    dispatch('ringSettingsFetchFilters', { ...params, category: 'necklace' })
  },

  async ringsListFetchFilters(
    { dispatch, commit, state, rootState, rootGetters },
    params
  ) {
    const {
      itemsType,
      query: { filterType = 'standard' }
    } = params

    const query = getFiltersQuery(params, jewelryFilters, rootGetters)

    query.availability = state.dataType[itemsType]
    query.style = params.style || ''
    query.category = params.category || 'ring'

    const filters = await this.$api.filters.fetchJewelryFilters(query)

    if (params.flushFilters) dispatch('clearSourceFilters', itemsType)

    commit(FILTERS.SET_FILTERS, {
      filters: normalizeFiltersResponse(filters, filterType),
      itemsType,
      filterType
    })
  },

  earringsListFetchFilters({ dispatch }, params) {
    dispatch('ringsListFetchFilters', { ...params, category: 'earring' })
  },

  braceletsListFetchFilters({ dispatch }, params) {
    dispatch('ringsListFetchFilters', { ...params, category: 'bracelet' })
  },

  broochesListFetchFilters({ dispatch }, params) {
    dispatch('ringsListFetchFilters', { ...params, category: 'brooch' })
  },

  cufflinksListFetchFilters({ dispatch }, params) {
    dispatch('ringsListFetchFilters', { ...params, category: 'cufflink' })
  },

  necklacesPendantsListFetchFilters({ dispatch }, params) {
    dispatch('ringsListFetchFilters', { ...params, category: 'necklace' })
  },

  async weddingBandsFetchFilters(
    { dispatch, commit, state, rootState },
    params
  ) {
    const {
      itemsType,
      query: { filterType = 'standard' }
    } = params

    const query = getCommonFiltersQuery(params, bandsFilters)

    query.category = params.category || 'ring'

    const filters = await this.$api.filters.fetchBandsFilters(query)

    if (params.flushFilters) dispatch('clearSourceFilters', itemsType)

    commit(FILTERS.SET_FILTERS, {
      filters: normalizeFiltersResponse(filters, filterType),
      itemsType,
      filterType
    })
  },

  async weddingBandsPlainFetchFilters(
    { dispatch, commit, state, rootState },
    params
  ) {
    const {
      itemsType,
      query: { filterType = 'standard' }
    } = params

    const query = getCommonFiltersQuery(params, bandsFilters)

    query.category = params.category || 'ring'

    const filters = await this.$api.filters.fetchPlainBandsFilters(query)

    if (params.flushFilters) dispatch('clearSourceFilters', itemsType)

    commit(FILTERS.SET_FILTERS, {
      filters: normalizeFiltersResponse(filters, filterType),
      itemsType,
      filterType
    })
  },

  clearSourceFilters({ commit }, itemsType) {
    commit(FILTERS.CLEAR_SOURCE_FILTERS, itemsType)
  },

  clearAllFilters({ commit }, itemsType) {
    commit(FILTERS.CLEAR_ALL_FILTERS, itemsType)
  },

  clearRootFilters({ commit }, itemsType) {
    commit(FILTERS.CLEAR_ROOT_FILTERS, itemsType)
  },

  clearOriginFilter({ commit }, itemsType) {
    commit(FILTERS.CLEAR_ORIGIN_FILTER, itemsType)
  },

  clearStyleFilter({ commit }, itemsType) {
    commit(FILTERS.CLEAR_STYLES_FILTER, itemsType)
  }
}

export const mutations = {
  [FILTERS.REMOVE_SELECTED_FILTER](
    state,
    { listType, valuesToRemove, filterType }
  ) {
    if (filterType.includes('Range')) {
      state.selectedFilters[listType][filterType] = []
      return
    }

    valuesToRemove.forEach((value) => {
      const index = state.selectedFilters[listType][filterType].findIndex(
        (v) => v === value
      )
      state.selectedFilters[listType][filterType].splice(index, 1)
    })
  },

  [FILTERS.SET_DATA_TYPE](state, { listType, value }) {
    state.dataType[listType] = value
  },

  [FILTERS.ADD_ROOT_SELECTED_FILTERS](state, { listType, filterName, value }) {
    if (!state.rootSelectedFilters[listType][filterName])
      Vue.set(state.rootSelectedFilters[listType], filterName, [])
    if (!state.rootSelectedFilters[listType][filterName].includes(value))
      state.rootSelectedFilters[listType][filterName].push(value)
  },

  [FILTERS.SET_ROOT_SELECTED_FILTERS](state, { listType, filterName, value }) {
    if (!state.rootSelectedFilters[listType][filterName])
      Vue.set(state.rootSelectedFilters[listType], filterName, [])
    if (state.rootSelectedFilters[listType][filterName].includes(value)) {
      state.rootSelectedFilters[listType][filterName].splice(
        state.rootSelectedFilters[listType][filterName].indexOf(value),
        1
      )
      // remove unsupported child filters
    } else {
      state.rootSelectedFilters[listType][filterName].push(value)
    }
  },

  [FILTERS.SET_AREA_FILTERS](state, { listType, filterName, value }) {
    if (rootFiltersStore.includes(filterName)) {
      Vue.set(state.rootSelectedFilters[listType], filterName, value)
      if (!fetchRootFiltersStore.includes(filterName)) return
    }
    Vue.set(state.selectedFilters[listType], filterName, value)
  },

  [FILTERS.REMOVE_CHILD_FILTERS](state, { itemsType }) {
    Object.keys(state.sourceFilters[itemsType]).forEach((key) => {
      const filter = state.sourceFilters[itemsType][key]
      if (!['standard', 'advanced'].includes(filter.filterType))
        Vue.delete(state.sourceFilters[itemsType], key)
    })
  },

  [FILTERS.CHANGE_PREFILTER_STATE](state, { result, getters }) {
    if (result && !getters.usePrefilter) state.dirtyLists = true
    state.usePrefilter = result
  },

  [FILTERS.SET_DIRTY_LISTS](state) {
    state.dirtyLists = true
  },

  [FILTERS.CLEAN_DIRTY_LISTS](state) {
    state.dirtyLists = false
  },

  [FILTERS.SET_FILTERS](state, { filters, itemsType, filterType }) {
    state.sourceFilters[itemsType] = {
      ...state.sourceFilters[itemsType],
      ...filters
    }
    const filtersWithType = Object.keys(state.sourceFilters[itemsType]).filter(
      (filter) =>
        state.sourceFilters[itemsType][filter].filterType === filterType
    )
    filtersWithType.forEach((filter) => {
      if (!filters[filter]) delete state.sourceFilters[itemsType][filter]
    })
  },

  [FILTERS.CLEAR_SOURCE_FILTERS](state, itemsType) {
    state.sourceFilters[itemsType] = {}
  },

  [FILTERS.CLEAR_ALL_FILTERS](state, itemsType) {
    if (itemsType) {
      state.rootSelectedFilters[itemsType] = {}
      state.selectedFilters[itemsType] = {}
    } else {
      // Clear all filters for all item types
      Object.keys(state.selectedFilters).forEach((type) => {
        state.rootSelectedFilters[type] = {}
        state.selectedFilters[type] = {}
      })
    }
  },

  [FILTERS.CLEAR_ROOT_FILTERS](state, itemsType) {
    state.rootSelectedFilters[itemsType] = {}
  },

  [FILTERS.CLEAR_ORIGIN_FILTER](state, itemsType) {
    delete state.selectedFilters[itemsType].stoneOrigins
  },

  [FILTERS.CLEAR_STYLES_FILTER](state, itemsType) {
    delete state.selectedFilters[itemsType].styles
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
