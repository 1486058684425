import { guid } from '~~/utils/utils'
import { defaultQuickSearchValues } from '~~/utils/definitions/defaults'

export const state = () => ({
  ...defaultQuickSearchValues,
  query: '',
  showResults: false
})

export const getters = {
  searchItems: (store) => [
    ...store.stones,
    ...store.jewelry,
    ...store.pairs,
    ...store.bands,
    ...store.settings
  ],
  query: (store) => store.query,
  showResults: (store) => store.showResults
}

export const actions = {
  async fetchSearchItems({ commit }, query) {
    const result = query
      ? await this.$axios.$get(
          `/public/${process.env.siteName}/search/${query}`
        )
      : defaultQuickSearchValues
    commit('SET_SEARCH_ITEMS', result)
  },

  clearSearchResults({ commit }) {
    commit('CLEAR_SEARCH_RESULTS')
  },

  setQuery({ commit }, value) {
    commit('SET_QUERY', value)
  },

  setShowResults({ commit }, value) {
    commit('SET_SHOW_RESULTS', value)
  }
}

export const mutations = {
  SET_QUERY(state, value) {
    state.query = value
  },

  SET_SHOW_RESULTS(state, value) {
    state.showResults = value
  },

  SET_SEARCH_ITEMS(state, { stones, jewelry, pairs, bands, settings }) {
    state.stones = setGuid.call(this, stones)
    state.jewelry = setGuid.call(this, jewelry)
    state.pairs = setGuid.call(this, pairs)
    state.bands = setGuid.call(this, bands)
    state.settings = setGuid.call(this, settings)
  },

  CLEAR_SEARCH_RESULTS(state) {
    state.stones = []
    state.jewelry = []
    state.pairs = []
    state.bands = []
    state.settings = []
  }
}

function setGuid(items) {
  return items.map((item) => {
    item.guid = guid()
    return item
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
