export default {
  _namespace: '',
  loading: false,
  listView: 'module',
  itemsHash: '',
  lastLoadedPageNumber: 0,
  pagesList: [],
  loadingPages: [], // loading pages in progress
  totalPagesCount: -1,
  totalItemsCount: 0
}
