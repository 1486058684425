import account from './modules/account'
import accountPages from './modules/accountPages'
import app from './modules/app'
import bestsellers from './modules/bestsellers'
import contacts from './modules/contacts'
import customItem from './modules/customItem'
import designers from './modules/designers'
import filters from './modules/filters'
import locale from './modules/locale'
import navigation from './modules/navigation'
import order from './modules/order'
import ourStaff from './modules/ourStaff'
import productDetails from './modules/productDetails'
import quickSearch from './modules/quickSearch'
import shareProduct from './modules/shareProduct'
import showroom from './modules/showroom'
import wordpress from './modules/wordpress'

import favorites from './lists/favorites'
import compareList from './lists/compare'
import cart from './lists/cart'

import stonesList from './listings/stonesList'
import stonePairsList from './listings/stonePairsList'
import ringSettings from './listings/ringSettings'
import earringSettings from './listings/earringSettings'
import braceletSettings from './listings/braceletSettings'
import pendantSettings from './listings/pendantSettings'
import necklaceSettings from './listings/necklaceSettings'
import braceletsList from './listings/braceletsList'
import earringsList from './listings/earringsList'
import necklacesPendantsList from './listings/necklacesPendantsList'
import ringsList from './listings/ringsList'
import cufflinksList from './listings/cufflinksList'
import broochesList from './listings/broochesList'
import weddingBands from './listings/weddingBands'
import weddingBandsPlain from './listings/weddingBandsPlain'

export default {
  account,
  accountPages,
  app,
  bestsellers,
  contacts,
  customItem,
  designers,
  filters,
  locale,
  navigation,
  order,
  ourStaff,
  productDetails,
  quickSearch,
  shareProduct,
  showroom,
  wordpress,

  stonesList,
  stonePairsList,
  ringSettings,
  earringSettings,
  braceletSettings,
  pendantSettings,
  necklaceSettings,
  braceletsList,
  earringsList,
  necklacesPendantsList,
  ringsList,
  cufflinksList,
  broochesList,
  weddingBands,
  weddingBandsPlain,

  favorites,
  compareList,
  cart
}
