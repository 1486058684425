import Vue from 'vue'
import get from 'lodash/get'
import { ipWhitelist } from '~~/utils/definitions/defaults'

export const APP = {
  ERROR: 'ERROR',
  SET_WEBP_SUPPORT: 'SET_WEBP_SUPPORT',
  ALL_FONTS_LOADED: 'ALL_FONTS_LOADED',
  FONT_LOADED: 'FONT_LOADED',
  SET_MAIN_MENU_POSITION: 'SET_MAIN_MENU_POSITION',
  TOGGLE_MENU: 'TOGGLE_MENU',
  SET_MENU_STATE: 'SET_MENU_STATE',
  SET_INITIAL_DATA: 'SET_INITIAL_DATA',
  TOGGLE_CURRENCY: 'TOGGLE_CURRENCY',
  SET_CURRENCY: 'SET_CURRENCY',
  SET_MOBILE_HEADER_VISIBILITY: 'SET_MOBILE_HEADER_VISIBILITY',
  UPDATE_WISHLIST_PRICES: 'UPDATE_WISHLIST_PRICES',
  UPDATE_COMPARE_PRICES: 'UPDATE_COMPARE_PRICES',
  UPDATE_CART_PRICES: 'UPDATE_CART_PRICES',
  RESET_HASH: 'RESET_HASH',
  SET_HASH: 'SET_HASH',
  SET_STONES_LIST: 'SET_STONES_LIST',
  SET_COLORS_LIST: 'SET_COLORS_LIST',
  SET_OFFICE_IP: 'SET_OFFICE_IP',
  SET_AVAILABLE_ICONS: 'SET_AVAILABLE_ICONS',
  CACHE_ICON: 'CACHE_ICON'
}

export const state = () => ({
  hash: '', // hash to get saved strings on backend side (for ex. to shortify links length)
  stonesList: [], // list of all stones for NGC
  colorsList: [], // list of all colors for NGC
  wishlistPricesUpdated: false,
  comparePricesUpdated: false,
  cartPricesUpdated: false,
  mobileHeaderHide: false,
  allFontsLoaded: false,
  fontLoaded: {},
  webp: false,
  mainMenuFixed: false,
  menuOpen: false,
  saleHeadline: '',
  salePersons: [],
  stoneTypes: [],
  promoBanners: {},
  workingHours: {},
  documentPriceConfig: {},
  localCurrency: {},
  showLocal: true,
  officeIp: false,
  availableIcons: [], // list of available icon names without .svg extension
  iconCache: {}
})

export const getters = {
  stoneTypes: (store) => store.stoneTypes,
  stonesList: (store) => store.stonesList,
  colorsList: (store) => store.colorsList,
  allFontsLoaded: (store) => store.allFontsLoaded,
  fontLoaded: (store) => store.fontLoaded,
  webp: (store) => store.webp,
  mainMenuFixed: (store) => store.mainMenuFixed,
  menuOpen: (store) => store.menuOpen,
  showLocal: (store) => {
    if (get(store, 'localCurrency.currencyCode', 'USD') === 'USD') return false
    return store.showLocal && !!get(store, 'localCurrency.localRate', false)
  },
  localCurrencySymbol: (store) => get(store, 'localCurrency.symbol', '$'),
  localCurrencyCode: (store) => get(store, 'localCurrency.currencyCode', 'USD'),
  symbolPositionFront: (store) => {
    if (get(store, 'localCurrency.currencyCode', 'USD') === 'USD') return true
    const showLocal =
      store.showLocal && !!get(store, 'localCurrency.localRate', false)
    if (!showLocal) return true // for USD always in front
    return get(store, 'localCurrency.symbolFront', false)
  },
  availableIcons: (store) => store.availableIcons,
  getCachedIcon: (store) => (iconName) => store.iconCache[iconName]
}

export const actions = {
  setOfficeIp({ commit }, req) {
    let userIp = req.headers['x-forwarded-for'] || req.connection.remoteAddress

    // x-forwarded-for can contain multiple IP addresses separated by commas (for example: client, proxy1, proxy2)
    if (userIp && userIp.includes(',')) {
      userIp = userIp.split(',')[0].trim()
    }
    const officeIp = ipWhitelist.includes(userIp)
    commit(APP.SET_OFFICE_IP, officeIp)
  },

  resetHash({ commit }) {
    commit(APP.RESET_HASH)
  },

  async saveString({ commit }, sting) {
    commit(APP.SET_HASH, await this.$api.app.saveString(sting))
  },

  async getString({ commit }, hash) {
    return await this.$api.app.getString(hash)
  },

  setStonesList({ commit }, data) {
    commit(APP.SET_STONES_LIST, data)
  },

  setColorsList({ commit }, data) {
    commit(APP.SET_COLORS_LIST, data)
  },

  wishlistUpdatePrices({ commit }) {
    commit(APP.UPDATE_WISHLIST_PRICES)
  },

  compareUpdatePrices({ commit }) {
    commit(APP.UPDATE_COMPARE_PRICES)
  },

  cartUpdatePrices({ commit }) {
    commit(APP.UPDATE_CART_PRICES)
  },

  async fetchInitialData({ commit }) {
    const result = await this.$api.app.fetchInitialData()
    commit(APP.SET_INITIAL_DATA, result)
  },

  setWebpSupport({ commit }, result) {
    commit(APP.SET_WEBP_SUPPORT, result)
  },

  setMobileHeaderVisibility({ commit }, state) {
    commit(APP.SET_MOBILE_HEADER_VISIBILITY, state)
  },

  setAllFontsLoaded({ commit }) {
    commit(APP.ALL_FONTS_LOADED)
  },

  setFontLoaded({ commit }, family) {
    commit(APP.FONT_LOADED, family)
  },

  setMainMenuState({ commit }, value) {
    commit(APP.SET_MAIN_MENU_POSITION, value)
  },

  toggleMenu({ commit }) {
    commit(APP.TOGGLE_MENU)
  },

  setMenuState({ commit }, menuState) {
    commit(APP.SET_MENU_STATE, menuState)
  },

  error({ commit }) {
    commit(APP.ERROR)
  },

  toggleCurrency({ commit }) {
    commit(APP.TOGGLE_CURRENCY)
  },

  setLocalCurrency({ commit }) {
    commit(APP.SET_CURRENCY, true)
  },

  setDefaultCurrency({ commit }) {
    commit(APP.SET_CURRENCY, false)
  },

  setAvailableIcons({ commit }, icons) {
    commit(APP.SET_AVAILABLE_ICONS, icons)
  },

  cacheIcon({ commit }, { name, element }) {
    commit(APP.CACHE_ICON, { name, element })
  }
}

export const mutations = {
  [APP.SET_OFFICE_IP](state, value) {
    state.officeIp = value
  },

  [APP.SET_HASH](state, hash) {
    state.hash = hash
  },

  [APP.SET_STONES_LIST](state, data) {
    state.stonesList = data
  },

  [APP.SET_COLORS_LIST](state, data) {
    state.colorsList = data
  },

  [APP.RESET_HASH](state) {
    state.hash = ''
  },

  [APP.UPDATE_WISHLIST_PRICES](state) {
    state.wishListPrices = state.wishListPrices || {}
  },

  [APP.UPDATE_WISHLIST_PRICES](state) {
    state.wishlistPricesUpdated = true
  },

  [APP.UPDATE_COMPARE_PRICES](state) {
    state.comparePricesUpdated = true
  },

  [APP.UPDATE_CART_PRICES](state) {
    state.cartPricesUpdated = true
  },

  [APP.SET_MOBILE_HEADER_VISIBILITY](state, cond) {
    state.mobileHeaderHide = cond
  },

  [APP.SET_INITIAL_DATA](state, result) {
    Object.assign(state, { localCurrency: result.currency, ...result })
  },

  [APP.ERROR](state) {}, // emit common errors notification

  [APP.SET_WEBP_SUPPORT](state, result) {
    state.webp = result
  },

  [APP.ALL_FONTS_LOADED](state) {
    state.allFontsLoaded = true
  },

  [APP.FONT_LOADED](state, family) {
    Vue.set(state.fontLoaded, family, true)
  },

  [APP.SET_MAIN_MENU_POSITION](state, value) {
    state.mainMenuFixed = value
  },

  [APP.SET_MENU_STATE](state, menuState) {
    state.menuOpen = menuState
  },

  [APP.TOGGLE_MENU](state) {
    state.menuOpen = !state.menuOpen
  },

  [APP.TOGGLE_CURRENCY](state) {
    state.showLocal = !state.showLocal
  },

  [APP.SET_LOCAL_CURRENCY](state, localCurrency) {
    state.localCurrency = localCurrency
  },

  [APP.SET_CURRENCY](state, value) {
    state.showLocal = value
  },

  [APP.SET_AVAILABLE_ICONS](state, icons) {
    state.availableIcons = icons
  },

  [APP.CACHE_ICON](state, { name, element }) {
    state.iconCache[name] = element
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
