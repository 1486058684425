import { stones, colors as gemstoneColors } from './config/gemstoneConfig.js'
import { colors as sapphireColors } from './config/sapphireConfig.js'

const colors =
  process.env.siteName === 'sapphire' ? sapphireColors : gemstoneColors

/**
 * Determines if the given route is a stone or pair listing/details.
 *
 * @param {object} route - The route to check for listing/details type.
 * @return {boolean} Whether the route is a stone or pair listing/details.
 */
export function isStoneOrPairPage(route) {
  return getListingType(route).includes('stone')
}

/**
 * Normalize dynamic parameters by removing specific substrings.
 *
 * @param {string} dynamicListing - the dynamic listing to be normalized
 * @return {string} the normalized dynamic listing
 */
function normalizeDynamicParameters(dynamicListing) {
  return dynamicListing.replace(
    /-(gemstone-rings|gemstone-pairs|sapphire-engagement-rings|sapphire-rings|sapphire-pairs|gemstones|sapphires|rings|pairs)/g,
    ''
  )
}

/**
 * Retrieves a stone object based on a dynamic route value.
 *
 * @param {Object} route - The dynamic part of the route used to identify the stone.
 * @return {Object|null} The stone object if found, otherwise null.
 */
export function getStoneByDynamicRoute(route) {
  if (!route.params.dynamicListing) return {}
  return stones.find(
    (stone) =>
      stone.kebabCase ===
      normalizeDynamicParameters(route.params.dynamicListing)
  )
}

/**
 * Finds a color by dynamic route parameter and returns it.
 *
 * @param {Object} route - The dynamic route parameter
 * @return {Object|null} The color found by the dynamic route parameter
 */
export function getColorByDynamicRoute(route) {
  if (
    !route.params.dynamicListing ||
    isStoneTypeOrColor(route) === 'stoneTypes'
  )
    return {}
  return colors.find(
    (color) =>
      color.kebabCase ===
      normalizeDynamicParameters(route.params.dynamicListing)
  )
}

/**
 * Determines the listing type based on the route.
 *
 * @param {Object} route - The route object containing params.
 * @return {string} The specific listing type as a string.
 */
export function getListingType(route) {
  const { dynamicListing } = route.params
  if (dynamicListing.includes('earrings')) return 'earringsList'
  if (dynamicListing.includes('rings')) return 'ringsList'
  if (dynamicListing.includes('pairs')) return 'stonePairsList'
  return 'stonesList'
}

/**
 * Determines if the provided route corresponds to
 * stone colors or stone types.
 *
 * @param {Object} route - The route object that
 * contains parameters to check.
 * @return {string} 'stoneColors' if the route
 * includes 'gemstone', otherwise 'stoneTypes'.
 */
export function isStoneTypeOrColor(route) {
  const { dynamicListing } = route.params
  return /gemstone|sapphire/g.test(dynamicListing)
    ? 'stoneColors'
    : 'stoneTypes'
  // return dynamicListing.includes('gemstone') ? 'stoneColors' : 'stoneTypes'
}

/**
 * Determines the category of a product based on the route parameter.
 * Conditions order is important!
 *
 * @param {Object} route - The route object containing parameters.
 * @return {string} The product category as 'Ring', 'Pair', or 'Stone'.
 */
export function getCategoryByRoute(route) {
  const { dynamicListing } = route.params
  const routeName = route.name

  const routeString = [dynamicListing, routeName].filter(Boolean).join('')

  if (routeString.includes('plain-wedding-rings-bands')) return 'Plain Band'
  if (routeString.includes('wedding-rings-bands')) return 'Wedding Band'
  if (routeString.includes('earrings')) return 'Earring'
  if (routeString.includes('rings')) return 'Ring'
  if (routeString.includes('pairs')) return 'Pair'
  return 'Stone'
}

export function getStoneByStoneType(type) {
  const stone = stones.find((stone) => stone.name === type)
  if (!stone) return null
  stone.children = stone.children.map((child) => {
    child.itemsCount = stones.find((s) => s.name === child.name).itemsCount
    return child
  })
  return stone
}

export function getColorByStoneColor(colorName) {
  const color = colors.find((c) => c.name === colorName)
  if (!color) return null
  color.children = color.children.map((child) => {
    child.itemsCount = colors.find((c) => c.name === child.name).itemsCount
    return child
  })
  return color
}
