import cloneDeep from 'lodash/cloneDeep'
import kebabCase from 'lodash/kebabCase.js'
import { guid } from '~~/utils/utils.js'
import { defaultDesignersCollectionQuery } from '~~/utils/definitions/defaults.js'

export const DESIGNERS = {
  SET_DESIGNERS_LIST: 'SET_DESIGNERS_LIST',
  SET_DESIGNER_DETAILS: 'SET_DESIGNER_DETAILS',
  SET_DESIGNER_COLLECTION: 'SET_DESIGNER_COLLECTION',
  SET_LOADING: 'SET_LOADING',
  INIT_PAGES_DATA: 'INIT_PAGES_DATA'
}

export const state = () => ({
  loading: false,
  designersList: [],
  designerDetails: {},
  pagesList: {}, // collection
  pagesData: {}
})

export const getters = {
  isAllPagesLoaded: (state) => (designerName) => {
    return (
      state.pagesData[designerName]?.pageNumber >
      state.pagesData[designerName]?.pageCount
    )
  },

  designerNameKebabCase: (state) => {
    return kebabCase(state.designerDetails.texts?.introducing)
  }
}

export const actions = {
  async fetchFeaturedDesigners({ commit }) {
    const response = await this.$api.dmto.fetchDesignersList()
    commit(DESIGNERS.SET_DESIGNERS_LIST, response)
  },

  async fetchDesignersDetails({ commit }, designerName) {
    const response = await this.$api.dmto.fetchDesignerInfo(designerName)
    commit(DESIGNERS.SET_DESIGNER_DETAILS, response)
  },

  async fetchDesignersCollection({ state, commit, getters }, designerName) {
    if (getters.isAllPagesLoaded(designerName)) return

    if (!state.pagesData[designerName])
      commit(DESIGNERS.INIT_PAGES_DATA, designerName)

    const query = cloneDeep(state.pagesData[designerName])
    query.query.designerName = designerName

    commit(DESIGNERS.SET_LOADING, true)
    const response = await this.$api.dmto.fetchDesignerCollection(query)
    commit(DESIGNERS.SET_DESIGNER_COLLECTION, { response, designerName })
    commit(DESIGNERS.SET_LOADING, false)
  }
}

export const mutations = {
  [DESIGNERS.SET_DESIGNER_COLLECTION](state, { response, designerName }) {
    if (!state.pagesList[designerName]) state.pagesList[designerName] = []
    state.pagesList[designerName].push(
      ...response.page.map((i) => {
        i.guid = guid()
        return i
      })
    )
    state.pagesData[designerName].pageCount = response.pageCount
    state.pagesData[designerName].pageNumber++
  },

  [DESIGNERS.SET_DESIGNERS_LIST](state, designersList) {
    state.designersList = designersList
  },

  [DESIGNERS.SET_DESIGNER_DETAILS](state, designerDetails) {
    state.designerDetails = designerDetails
  },

  [DESIGNERS.SET_LOADING](state, loading) {
    state.loading = loading
  },

  [DESIGNERS.INIT_PAGES_DATA](state, designerName) {
    state.pagesData[designerName] = cloneDeep(defaultDesignersCollectionQuery)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
