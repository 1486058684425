import forEach from 'lodash/forEach'
import LIST from './constants'
import {
  cookiesOpts,
  productListTypeByCategory
} from '~~/utils/definitions/defaults'
import {
  serializeBaseItems,
  serializeCustomItem,
  serializeRemoveListItems,
  serializeListItems
} from '~~/utils/serializators'
import {
  normalizeBaseItem,
  normalizeBandsItemsWishlist,
  normalizeCustomItemWishlist,
  normalizePlainBandsItemsWishlist,
  normalizeSkip,
  normalizeWaxItemCart
} from '~~/utils/normalizers'
import {
  normalizeBandsItemsCompare,
  normalizeCustomItemCompare,
  normalizePlainBandsItemsCompare
} from '~~/utils/normalizers/compareList'
import { getItemId } from '~~/utils/utils'

const normalizersMap = {
  favorites: {
    weddingBands: normalizeBandsItemsWishlist,
    weddingBandsPlain: normalizePlainBandsItemsWishlist,
    custom: normalizeCustomItemWishlist
  },
  compareList: {
    weddingBands: normalizeBandsItemsCompare,
    weddingBandsPlain: normalizePlainBandsItemsCompare,
    custom: normalizeCustomItemCompare
  },
  cart: {
    weddingBands: normalizeBandsItemsCompare,
    weddingBandsPlain: normalizePlainBandsItemsCompare,
    custom: normalizeCustomItemCompare,
    wax: normalizeWaxItemCart
  }
}

function normalizeItemsMap(namespace, list) {
  switch (list) {
    case 'weddingBands':
    case 'weddingBandsPlain':
    case 'custom':
    case 'wax':
      return normalizersMap[namespace][list]
    default:
      return normalizeSkip
  }
}

export default {
  async getParamsHash({ getters, dispatch }) {
    if (!getters.itemsParams.length) {
      dispatch('resetHash')
      return
    }
    await dispatch('saveString', JSON.stringify(getters.itemsParams))
  },

  resetHash({ commit }) {
    commit(LIST.RESET_HASH)
  },

  async saveString({ commit }, string) {
    commit(LIST.SET_HASH, await this.$api.app.saveString(string))
  },

  async getString({ commit }, hash) {
    return await this.$api.app.getString(hash)
  },

  setSharedItemsFetched({ commit }) {
    commit(LIST.SET_SHARED_ITEMS_FETCHED)
  },

  resetSharedItemsFetched({ commit }) {
    commit(LIST.RESET_SHARED_ITEMS_FETCHED)
  },

  setBaseItems({ state, commit }, items) {
    // do not add base items ob the cart page, this breaks price and delivery logic
    if (this.$router.currentRoute.name.includes('cart-index')) return
    commit(LIST.ADD_BASE_ITEMS, normalizeBaseItem(items))
  },

  async getItemsFromBase({ state, commit, dispatch }, items) {
    commit(LIST.FETCHING_ITEMS, true)
    const response = await this.$api[state._namespace].fetchListItems(
      serializeBaseItems(items, state._namespace)
    )

    const fullItems =
      response[`${state._namespace.replace('favorites', 'favorite')}Data`]

    forEach(fullItems, (itemsList) => {
      itemsList.forEach((i) => {
        let item = { ...i, serverSync: true }
        const productListType = productListTypeByCategory[item.category]
        item = normalizeItemsMap(state._namespace, productListType)(item)

        commit(LIST.REPLACE_ITEM_SILENT, { item, type: productListType })
      })
    })
    commit(LIST.REMOVE_PLACEHOLDERS, items)
    commit(LIST.FETCHING_ITEMS, false)
    await dispatch('setSharedItemsFetched')
    await dispatch('getParamsHash')
  },

  changeItemsOrder({ commit }, { type, value }) {
    commit(LIST.CHANGE_ITEMS_ORDER, { type, value })
  },

  /**
   * Mark all items as unsynced to fetch actual prices from lists
   * @param commit
   */
  markItemsAsNotSynced({ commit }) {
    commit(LIST.MARK_ALL_AS_NOT_SYNCED)
  },

  /**
   * NOT PRICES! Sync new added and updated locally items with the server
   * @param commit
   */
  markItemsAsSynced({ commit }) {
    commit(LIST.MARK_ALL_AS_SYNCED)
  },

  async pushAllListItemsToServer({ rootState, getters, dispatch }) {
    if (!rootState.auth.loggedIn) return
    const notSyncedItems = getters.allListItems.filter(
      (item) => item.itemToSync && !item.placeholder
    )
    if (notSyncedItems.length) {
      await dispatch('addToServerListForce', { item: notSyncedItems })
      dispatch('markItemsAsSynced')
    }
  },

  async addToServerList(
    { commit, getters, state, rootState, dispatch },
    { item, addSilent }
  ) {
    if (!rootState.auth.loggedIn) return
    const addType = addSilent ? LIST.ADD_ITEM_SILENT : LIST.ADD_ITEM
    const listItems = (Array.isArray(item) ? item : [item]).filter((i) => i)
    if (!listItems.length) return
    if (listItems.length === 1) {
      if (isInList(getters.allListItems, listItems[0])) {
        // await dispatch('removeFromServerList', listItems[0])
        return
      }
    }
    await this.$api[state._namespace].mergeList(serializeListItems(listItems))
    listItems.forEach((listItem) => {
      const item = { ...listItem, serverSync: true }
      const productListType = productListTypeByCategory[item.category]
      this.$cookies.set(
        `${state._namespace}Items`,
        Number(getters.allListItems.length) + 1,
        cookiesOpts
      )
      commit(addType, { item, type: productListType })
    })
  },

  async addToServerListForce(
    { state, commit, getters, rootState, dispatch },
    { item, update }
  ) {
    const listItems = (Array.isArray(item) ? item : [item]).filter(
      (item) => item
    )
    if (!listItems.length) return
    if (rootState.auth.loggedIn)
      await this.$api[state._namespace].mergeList(serializeListItems(listItems))
    if (update === undefined) {
      listItems.forEach((listItem) => {
        const item = { ...listItem, serverSync: true }
        const productListType = productListTypeByCategory[item.category]
        commit(LIST.ADD_ITEM, { item, type: productListType })
      })
    }
  },

  async removeFromServerList(
    { state, commit, getters, rootState },
    { item, removeSilent }
  ) {
    if (!rootState.auth.loggedIn) return
    const removeType = removeSilent ? LIST.REMOVE_ITEM_SILENT : LIST.REMOVE_ITEM
    await this.$api[state._namespace].removeFromList({
      data: serializeRemoveListItems([item])
    })
    this.$cookies.set(
      `${state._namespace}Items`,
      Number(getters.allListItems.length) - 1,
      cookiesOpts
    )
    commit(removeType, { item })
  },

  async addToList(
    { state, commit, getters, rootState, dispatch },
    { item, serverSync = true, addSilent = false, removeSilent = false }
  ) {
    const addType = addSilent ? LIST.ADD_ITEM_SILENT : LIST.ADD_ITEM
    const removeType = removeSilent ? LIST.REMOVE_ITEM_SILENT : LIST.REMOVE_ITEM
    if (isInList(getters.allListItems, item)) {
      if (rootState.auth.loggedIn) {
        await dispatch('removeFromServerList', { item, removeSilent })
      } else commit(removeType, { item })
      this.$cookies.set(
        `${state._namespace}Items`,
        Number(getters.allListItems.length),
        cookiesOpts
      )
      await dispatch('getParamsHash')
      return
    }
    if (serverSync) await dispatch('addToServerList', { item, addSilent })
    this.$cookies.set(
      `${state._namespace}Items`,
      Number(getters.allListItems.length) + 1,
      cookiesOpts
    )
    commit(addType, { item })
    await dispatch('getParamsHash')
  },

  async getFinalOptions({ commit, state }, item) {
    const query = serializeCustomItem(item)
    const finalOptions = await this.$api.customItem.fetchFinalOptions(query)
    commit(LIST.SET_FINAL_OPTIONS, { item, finalOptions })
  },

  async setSettingOptions(
    { commit, rootState, dispatch },
    { type, id, item, options } // for custom items
  ) {
    if (rootState.auth.loggedIn) {
      await dispatch('addToServerListForce', { item, update: false })
    }
    commit(LIST.SET_SETTING_OPTIONS, { type, id, options })
  },

  setSettingOptionsWithoutServerUpdate(
    { commit, rootGetters, dispatch },
    { type, id, item, options } // for custom items
  ) {
    commit(LIST.SET_SETTING_OPTIONS, { type, id, options })
  },

  setSideStonesOptions(
    { commit },
    { type, id, index, value, sideType } // for custom items
  ) {
    commit(LIST.SET_SIDE_STONE_OPTIONS, {
      type,
      id,
      index,
      value,
      sideType
    })
  },

  async updateCustomItemPrice(
    { commit, rootState, dispatch, state },
    { id, price, type } // for custom items
  ) {
    commit(LIST.UPDATE_CUSTOM_ITEM_PRICE, { id, price, type })
    const index = state[`${type}Items`].findIndex((el) => el.id === id)
    if (index > -1) {
      if (rootState.auth.loggedIn) {
        // update server wishlist only after price was updated
        await dispatch('addToServerListForce', {
          item: state[`${type}Items`][index],
          update: false
        })
      }
    }
  },

  async updateListItem({ commit, rootState, dispatch }, { item, silent }) {
    if (rootState.auth.loggedIn) {
      await dispatch('addToServerListForce', { item, update: false })
    }
    if (silent) {
      commit(LIST.UPDATE_ITEM_SILENT, { item })
      await dispatch('getParamsHash')
      return
    }
    commit(LIST.UPDATE_ITEM, { item })
    await dispatch('getParamsHash')
  },

  async replaceListItem(
    { state, commit, rootState, dispatch },
    { index, item }
  ) {
    if (rootState.auth.loggedIn) {
      await dispatch('addToServerListForce', { item, update: false }) // add new item
    }
    commit(LIST.REPLACE_ITEM, { index, item })
    await dispatch('getParamsHash')
  },

  async removeFromList({ state, commit, getters, dispatch, rootState }, item) {
    if (rootState.auth.loggedIn) {
      await dispatch('removeFromServerList', { item })
      await dispatch('getParamsHash')
      return
    }
    const productListType = productListTypeByCategory[item.category]
    this.$cookies.set(
      `${state._namespace}Items`,
      Number(getters.allListItems.length) - 1,
      cookiesOpts
    )
    commit(LIST.REMOVE_ITEM, { item, type: productListType })
    await dispatch('getParamsHash')
  },

  async removeFromListSilent(
    { state, commit, getters, dispatch, rootState },
    item
  ) {
    if (rootState.auth.loggedIn) {
      await dispatch('removeFromServerList', { item })
      await dispatch('getParamsHash')
      return
    }
    const productListType = productListTypeByCategory[item.category]
    this.$cookies.set(
      `${state._namespace}Items`,
      Number(getters.allListItems.length) - 1,
      cookiesOpts
    )
    commit(LIST.REMOVE_ITEM_SILENT, { item, type: productListType })
    await dispatch('getParamsHash')
  },

  async updatePrices({ commit, dispatch }, prices) {
    commit(LIST.UPDATE_PRICES, prices)
    await dispatch('getParamsHash')
  },

  clearList({ commit }) {
    commit(LIST.CLEAR_LIST)
  }
}

export function isInList(list, item) {
  return list.some((el) => getItemId(el) === getItemId(item))
}
