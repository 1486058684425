import cloneDeep from 'lodash/cloneDeep'
import { ITEMS_LIST } from './constants'
import {
  defaultGemListQuery,
  defaultPageSize
} from '~~/utils/definitions/defaults'
import { serializeFilter } from '~~/utils/serializators'
import { normalizeItemsListData } from '~~/utils/normalizers'

export default {
  resetFirstListingPage({ commit }) {
    commit(ITEMS_LIST.RESET_FIRST_PAGE)
  },

  async getPage(
    { commit, rootState, rootGetters, state, getters, dispatch },
    params
  ) {
    if (await dispatch('skipFetch', params)) return {}

    commit(ITEMS_LIST.SET_LOADING, true)
    commit(ITEMS_LIST.ADD_TO_LOADING_LIST, params.pageNumber)
    const query = serializeFilter(
      state._namespace,
      rootState.filters,
      params,
      rootGetters
    )

    const pageQuery = cloneDeep({
      ...cloneDeep(defaultGemListQuery),
      query,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize || defaultPageSize
    })

    if (params.preset) pageQuery.query.preset = params.preset

    // For specific origin listing pages
    if (params.origin) pageQuery.query.origins[0] = params.origin

    if (params.webCategory) pageQuery.query.webCategory = params.webCategory

    const listData = await this.$api.listings.fetchStonesList(pageQuery)

    if (params.beforeAddItemsCallback) params.beforeAddItemsCallback()

    const normalizedData = normalizeItemsListData(listData, params.pageNumber)

    if (params.force) commit(ITEMS_LIST.DROP_ITEMS_LIST)
    commit(ITEMS_LIST.GET_ITEMS_LIST, normalizedData)

    if (params.afterAddItemsCallback) params.afterAddItemsCallback()

    commit(ITEMS_LIST.SET_LOADING, false)
    commit(ITEMS_LIST.REMOVE_FROM_LOADING_LIST, params.pageNumber)

    return normalizedData
  },

  skipFetch({ state, getters }, params) {
    if (params.force) return false
    if (!params.pageNumber) return true // 0 or null
    if (state.loadingPages.includes(params.pageNumber)) return true // page already in loading process
    return getters.loadedPages.includes(params.pageNumber) // page already loaded
  },

  switchItemsListView({ commit }, viewType) {
    commit(ITEMS_LIST.SWITCH_ITEMS_LIST_VIEW, viewType)
  },

  dropItems({ commit }) {
    commit(ITEMS_LIST.DROP_ITEMS_LIST)
  },

  changeItemsOrder({ commit }, value) {
    commit('changeItemsOrder', value)
  },

  setLoading({ commit }, state) {
    commit(ITEMS_LIST.SET_LOADING, state)
  }
}
