import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import omit from 'lodash/omit.js'
import flatten from 'lodash/flatten.js'
import getters from '../_base/getters'
import {
  defaultWaxPrice,
  jewelryByCategory,
  showDocsOptionsEdge,
  // stateTaxes,
  stonesByCategory,
  chargeAfterLimit
} from '~~/utils/definitions/defaults'
import { getLocalPrice } from '~~/utils/utils'
import md5 from '~~/utils/helpers/md5'
export default {
  ...getters,

  showChargeAfter: (store, getters) =>
    store.addressForm.shippingCountryId === 'US' &&
    getters.getTotal <= chargeAfterLimit,

  checkoutStep: (store) => store.checkoutStep,
  shipping: (store, getters) => {
    if (!store.metaData.shippingRates) return 0
    let rates =
      store.addressForm.shippingCountryId === 'US'
        ? store.metaData.shippingRates.filter((i) => i.domestic)
        : store.metaData.shippingRates.filter((i) => !i.domestic)

    rates = rates.filter(
      (i) =>
        (i.maxOrderTotal >= getters.getSubTotal || i.maxOrderTotal === null) &&
        i.minOrderTotal <= getters.getSubTotal
    )

    const shippingPriceObj = rates.find(
      (r) => r.id === store.addressForm.shippingRateId
    )

    if (shippingPriceObj) return shippingPriceObj.price
    return 0
  },
  shippingLocal: (store, getters, rootState) => {
    const { localRate, usdRate } = rootState.app.localCurrency
    return getLocalPrice(getters.shipping, usdRate, localRate)
  },
  metaData: (store) => store.metaData,
  shippingCountry: (store) => store.addressForm.shippingCountryId,
  paymentForm: (store) => store.paymentForm,
  orderSummary: (store) => store.orderSummary,
  orderItems: (store) => {
    return [
      ...store.orderSummary.stones,
      ...store.orderSummary.pairs,
      ...store.orderSummary.settings,
      ...store.orderSummary.bands,
      ...store.orderSummary.jewelries,
      ...store.orderSummary.custom,
      ...store.orderSummary.waxModels
    ]
  },
  showExpandedCart: (store) => store.showExpandedCart,
  isOrderReceived: (store) => store.isOrderReceived,
  isOrderConfirmed: (store) => store.isOrderConfirmed,
  fetchCheckout: (store) => store.fetchCheckout,
  isBankWire: (store) => store.paymentForm.paymentOptionId === 3,
  conversionConfirmed: (store) => store.conversionConfirmed,
  docType: (store) => store.paymentForm.docType,
  localCurrency: (store) => store.metaData.localCurrency,
  pickUpDeliverySelected: (store) => store.addressForm.shippingRateId === 38,
  customItems: (store) => store.customItems,
  allListItems: (store) => {
    return [
      ...store.stonesListItems,
      ...store.stonePairsListItems,
      ...store.braceletsListItems,
      ...store.earringsListItems,
      ...store.necklacesPendantsListItems,
      ...store.ringsListItems,
      ...store.broochesListItems,
      ...store.cufflinksListItems,
      ...store.weddingBandsItems,
      ...store.weddingBandsPlainItems,
      ...store.ringSettingsItems,
      ...store.earringSettingsItems,
      ...store.braceletSettingsItems,
      ...store.pendantSettingsItems,
      ...store.necklaceSettingsItems,
      ...store.customItems,
      ...store.waxItems
    ]
  },
  itemsHash: (store, getters) => {
    return md5(JSON.stringify(getters.allListItems) + getters.getTotal)
  },
  hasFilters: (state, getters) => (listType) => {
    const values = Object.values(
      omit(state.selectedFilters[listType], ['sortBy', 'order'])
    )
    return !!flatten(values).length
  },
  documentsPrice: (state, getters) => {
    return getters.allListItems.reduce((acc, item) => {
      const price = item.discountPrice || item.price
      const threshold = getters.getThreshold(item)
      if (price <= threshold) return acc + getters.getDocumentPrice(item)
      return acc
    }, 0)
  },

  /**
   * Get document price for single item
   * @param state
   * @param getters
   * @returns {function(*): *}
   */
  getDocumentsPrice: (state, getters) => (item) => {
    if (state.paymentForm.docType !== 4) return 0
    const price = item.discountPrice || item.price
    const threshold = getters.getThreshold(item)
    if (price <= threshold) return getters.getDocumentPrice(item)
    return 0
  },

  getThreshold: (state, getters, rootState) => (item) => {
    return (
      rootState.app.documentPriceConfig.priceThreshold[item.category] ||
      rootState.app.documentPriceConfig.priceThreshold.default
    )
  },

  getDocumentPrice: (state, getters, rootState) => (item) => {
    return (
      rootState.app.documentPriceConfig.documentPrice[item.category] ||
      rootState.app.documentPriceConfig.documentPrice.default
    )
  },

  selectedUSState: (state, getters) => {
    return (
      state.metaData.states?.us?.find(
        (s) => s.key === getters.addressForm.shippingState
      ) || {}
    )
  },
  addressForm: (store, getters) => {
    if (getters.pickUpDeliverySelected)
      return { ...store.addressForm, ...store.companyAddress }
    return store.addressForm
  },
  items: (store, getters) => {
    return sortBy(getters.allListItems, ['addedAt'])
  },
  mixedPrices: (
    store, // Items in cart in different currencies
    getters,
    rootState
  ) => {
    if (!getters.items.length) return false
    const defaultCurrencyCode =
      rootState.app.localCurrency.currencyCode || 'USD'
    return getters.items.some((item) => {
      return (
        (get(item, 'localCurrency.currencyCode', '') ||
          get(item, 'finalPrice.localCurrency.currencyCode', '')) !==
        defaultCurrencyCode
      )
    })
  },
  getSubTotal: (store, getters) => {
    const isUS = getters.addressForm.shippingCountryId === 'US'
    return getters.items.reduce((acc, current) => {
      let price
      const extendPrice = isUS ? (current.extendPlan?.price || 0) / 100 : 0

      if (current.category === 'Wax') return acc + Number(defaultWaxPrice)

      if (current.finalPrice) {
        if (current.finalPrice.discountAmt) {
          const stonePrice = current.finalPrice.stonePrice
          const settingPrice = current.finalPrice.settingPrice
          return acc + Number(stonePrice) + Number(settingPrice) + extendPrice
        }
        price = current.finalPrice.totalPrice
      } else {
        price = current.discountPrice
          ? current.discountPrice
          : current.price || current.totalPrice
      }
      return acc + Number(price) + extendPrice
    }, 0)
  },
  getSubTotalWithoutWarranty: (store, getters) => {
    return getters.items.reduce((acc, current) => {
      let price

      if (current.category === 'Wax') return acc + Number(defaultWaxPrice)

      if (current.finalPrice) {
        if (current.finalPrice.discountAmt) {
          const stonePrice = current.finalPrice.stonePrice
          const settingPrice = current.finalPrice.settingPrice
          return acc + Number(stonePrice) + Number(settingPrice)
        }
        price = current.finalPrice.totalPrice
      } else {
        price = current.discountPrice
          ? current.discountPrice
          : current.price || current.totalPrice
      }
      return acc + Number(price)
    }, 0)
  },
  getSubTotalLocal: (store, getters, rootState) => {
    const { localRate, usdRate } = rootState.app.localCurrency
    return getLocalPrice(getters.getSubTotal, usdRate, localRate)
  },
  getTax: (store, getters) => {
    if (getters.addressForm.shippingCountryId !== 'US') return 0
    if (!getters.selectedUSState?.tax) return 0
    return store.tax
  },
  getTaxLocal: (store, getters, rootState) => {
    const { localRate, usdRate } = rootState.app.localCurrency

    return getLocalPrice(getters.getTax, usdRate, localRate)
  },

  getTotalWithoutTax: (store, getters) => {
    let total = getters.getSubTotal + getters.shipping
    if (store.paymentForm.docType === 4) total += getters.documentsPrice
    return total
  },

  getTotalWithoutWarranty: (store, getters) => {
    let total =
      getters.getSubTotalWithoutWarranty + getters.shipping + getters.getTax
    if (store.paymentForm.docType === 4) total += getters.documentsPrice
    return total
  },

  getTotal: (store, getters) => {
    let total = getters.getSubTotal + getters.shipping + getters.getTax
    if (store.paymentForm.docType === 4) total += getters.documentsPrice
    return Number(total).toFixed(2)
  },

  showDocsOptions: (store, getters) => {
    const priceCondition = getters.getTotal < showDocsOptionsEdge
    const itemsCondition = getters.items.some((item) =>
      [...jewelryByCategory, ...stonesByCategory, 'Custom'].includes(
        item.category
      )
    )
    return priceCondition && itemsCondition
  },

  getTotalLocal: (store, getters, rootState) => {
    const { localRate, usdRate } = rootState.app.localCurrency

    return getLocalPrice(getters.getTotal, usdRate, localRate)
  }
}
