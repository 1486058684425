import constants from '../_base/constants'

export default {
  ...constants,

  UPDATE_ADDRESS_FORM_VALUE: 'UPDATE_ADDRESS_FORM_VALUE',
  SET_ORDER_RECEIVED: 'SET_ORDER_RECEIVED',
  SET_META_DATA: 'SET_META_DATA',
  UPDATE_FORM_VALUE: 'UPDATE_FORM_VALUE',
  SET_FORM_VALUE: 'SET_FORM_VALUE',
  SET_PAYMENT_FORM_VALUE: 'SET_PAYMENT_FORM_VALUE',
  ORDER_SUCCESS: 'ORDER_SUCCESS',
  SET_SHIPPING: 'SET_SHIPPING',
  SET_ORDER_CONFIRMED_STATE: 'SET_ORDER_CONFIRMED_STATE',
  RESET_CARD: 'RESET_CARD',
  CONFIRM_CONVERSION: 'CONFIRM_CONVERSION',
  SET_DIRTY: 'SET_DIRTY',
  CLEAR_SHIPPING_RATES: 'CLEAR_SHIPPING_RATES',
  SET_DOCUMENTATION_TYPE: 'SET_DOCUMENTATION_TYPE',
  ADD_EXTEND: 'ADD_EXTEND',
  REMOVE_EXTEND: 'REMOVE_EXTEND',
  FLUSH_CART: 'FLUSH_CART',
  SET_TAX: 'SET_TAX',
  REMOVE_TAX: 'REMOVE_TAX',
  BACKEND_TAX_CALC_MODE: 'BACKEND_TAX_CALC_MODE',
  SET_TAX_HASH: 'SET_TAX_HASH',
  SET_SHIPPING_BILLING_ADDRESS: 'SET_SHIPPING_BILLING_ADDRESS',
  UPDATE_CARD_PRICE: 'UPDATE_CARD_PRICE',
  CLEAR_ALL_CREDIT_CARDS: 'CLEAR_ALL_CREDIT_CARDS',
  ADD_REPLACE_EDITED_CREDIT_CARD: 'ADD_REPLACE_EDITED_CREDIT_CARD',
  CREDIT_CARD_ADD_BTN_STATE_CHANGE: 'CREDIT_CARD_ADD_BTN_STATE_CHANGE',
  DELETE_CREDIT_CARD: 'DELETE_CREDIT_CARD'
}
