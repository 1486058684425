export default {
  RESET_HASH: 'RESET_HASH',
  SET_HASH: 'SET_HASH',
  ADD_ITEM: 'ADD_ITEM',
  ADD_ITEM_SILENT: 'ADD_ITEM_SILENT',
  ADD_BASE_ITEMS: 'ADD_BASE_ITEMS',
  REMOVE_ITEM: 'REMOVE_ITEM',
  REMOVE_ITEM_SILENT: 'REMOVE_ITEM_SILENT',
  REMOVE_BAND_ITEM: 'REMOVE_BAND_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  UPDATE_ITEM_SILENT: 'UPDATE_ITEM_SILENT',
  REPLACE_ITEM_SILENT: 'REPLACE_ITEM_SILENT',
  SET_SETTING_OPTIONS: 'SET_SETTING_OPTIONS',
  SET_SIDE_STONE_OPTIONS: 'SET_SIDE_STONE_OPTIONS',
  UPDATE_CUSTOM_ITEM_PRICE: 'UPDATE_CUSTOM_ITEM_PRICE',
  RING_SIZES: 'RING_SIZES',
  SET_FINAL_OPTIONS: 'SET_FINAL_OPTIONS',
  REPLACE_ITEM: 'REPLACE_ITEM',
  CHANGE_ITEMS_ORDER: 'CHANGE_ITEMS_ORDER',
  UPDATE_PRICES: 'UPDATE_PRICES',
  SET_PRICE_UPDATED: 'SET_PRICE_UPDATED',
  MARK_AS_NOT_SYNCED: 'MARK_AS_NOT_SYNCED',
  MARK_ALL_AS_SYNCED: 'MARK_ALL_AS_SYNCED',
  MARK_ALL_AS_NOT_SYNCED: 'MARK_ALL_AS_NOT_SYNCED',
  FETCHING_ITEMS: 'FETCHING_ITEMS',
  REMOVE_PLACEHOLDERS: 'REMOVE_PLACEHOLDERS',
  CLEAR_LIST: 'CLEAR_LIST',
  SET_SHARED_ITEMS_FETCHED: 'SET_SHARED_ITEMS_FETCHED',
  RESET_SHARED_ITEMS_FETCHED: 'RESET_SHARED_ITEMS_FETCHED'
}
