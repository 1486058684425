import cloneDeep from 'lodash/cloneDeep'
import state from '../_base/state'
import { companyAddress } from '~~/utils/definitions/defaults'

export default {
  ...cloneDeep(state),
  waxItems: [], // only for cart
  conversionConfirmed: false,
  showExpandedCart: false,
  isDirty: true, // if true, then need to be select default delivery rate
  isOrderReceived: false,
  isOrderConfirmed: false,
  tax: 0,
  taxHash: '',
  backendTaxCalcMode: false, // stop calculating tax amount on frontend side because of too many requests to backend (>9)
  fetchCheckout: false,
  metaData: {
    localCurrency: {}
  },
  // items: [],
  orderSummary: {
    bands: [],
    jewelries: [],
    pairs: [],
    stones: [],
    waxModels: [],
    custom: [],
    settings: []
  },
  paymentForm: {
    customerComments: '',
    docType: 0,
    paymentOptionId: null
  },
  allCreditCards: [],
  activeBtnConfirm: false,
  companyAddress,
  addressForm: {
    email: '',
    teamMember: '',
    phone: '',
    shippingFirstName: '',
    shippingLastName: '',
    shippingStreet1: '',
    shippingStreet2: '',
    shippingCity: '',
    shippingZipCode: '',
    shippingState: '',
    shippingCountryId: 'US',
    shipToBilling: true,
    shippingRateId: '',
    billingFirstName: '',
    billingLastName: '',
    billingStreet1: '',
    billingStreet2: '',
    billingCity: '',
    billingZipCode: '',
    billingState: '',
    billingCountryId: 'US'
  }
}
