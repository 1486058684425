import Vue from 'vue'
import { serializeProfile, serializePassword } from '~~/utils/serializators'
import { normalizeProfile } from '~~/utils/normalizers'
import {
  accountForm as form
  // defaultAddresses
} from '~~/utils/definitions/defaults'

export const ACCOUNT = {
  UPDATE_AUTH_USER: 'UPDATE_AUTH_USER',
  UPDATE_FIELDS: 'UPDATE_FIELDS',
  SET_PROFILE: 'SET_PROFILE',
  SAVE_ADDRESS: 'SAVE_ADDRESS',
  FLUSH_ACCOUNT: 'FLUSH_ACCOUNT',
  GET_ADDRESSES_LIST: 'GET_ADDRESSES_LIST',
  UPDATE_DEFAULT_ADDRESSES: 'UPDATE_DEFAULT_ADDRESSES',
  CLEAR_USER_DATA: 'CLEAR_USER_DATA'
}

export const state = () => ({
  form,
  fetched: false,
  shippingAddress: null, // address ID
  billingAddress: null, // address ID
  addressesList: []
})

export const getters = {
  form: (store) => store.form,
  defaultShippingAddress: (store) => {
    return (
      store.addressesList.find(
        (address) => store.shippingAddress === address.id
      ) || {}
    )
  }
}

export const actions = {
  async submitAddressForm({ commit }, { form }) {
    const result = await this.$api.account.saveAddress(form)
    commit(ACCOUNT.GET_ADDRESSES_LIST, result)
  },

  async fetchAddressesList({ rootState, state, commit }) {
    if (!rootState.auth.loggedIn) return
    if (state.addressesList.length) return
    const result = await this.$api.account.fetchAddressesList()
    commit(ACCOUNT.GET_ADDRESSES_LIST, result)
  },

  setDefaultAddress({ state, commit }, { type, id }) {
    const query = {
      shippingAddress: state.shippingAddress,
      billingAddress: state.billingAddress
    }
    query[`${type}Address`] = id

    commit(ACCOUNT.UPDATE_DEFAULT_ADDRESSES, query)
  },

  async updateDefaultAddress({ state }) {
    const query = {
      shippingAddress: state.shippingAddress,
      billingAddress: state.billingAddress
    }
    await this.$api.account.setDefaultAddress(query)
  },

  async deleteAddress({ commit }, { id }) {
    const result = await this.$api.account.deleteAddress({
      data: { addressId: id }
    })
    commit(ACCOUNT.GET_ADDRESSES_LIST, result)
  },

  /**
   * Fetch compare/favorites/cart base info
   * @param commit
   * @param dispatch
   * @param rootState
   * @returns {Promise<void>}
   */
  async fetchLists({ rootState, commit, dispatch }) {
    if (!rootState.auth.loggedIn) return
    const items = await this.$api.account.fetchLists()
    dispatch('favorites/setBaseItems', items.favorite, { root: true })
    dispatch('compareList/setBaseItems', items.compareList, { root: true })
    dispatch('cart/setBaseItems', items.cart, { root: true })
  },

  async fetchProfile({ state, commit }, force = false) {
    if (!force && state.fetched) return
    const result = await this.$api.account.fetchProfile()

    commit(ACCOUNT.SET_PROFILE, result)
    commit(ACCOUNT.UPDATE_FIELDS, normalizeProfile(result))
  },

  async updateProfile({ state, commit }, { form }) {
    const result = await this.$api.account.updateProfile(serializeProfile(form))
    commit(ACCOUNT.UPDATE_AUTH_USER)
    commit(ACCOUNT.SET_PROFILE, result)
  },

  async changePassword({ commit }, params) {
    const result = await this.$api.account.changePassword(
      serializePassword(params)
    )
    return result
  },

  async requestResetPassword({ commit }, params) {
    const result = await this.$api.account.requestResetPassword(params)
    return result
  },

  async resetPassword({ commit }, params) {
    try {
      const result = await this.$api.account.resetPassword(params)
      return result
    } catch (e) {
      console.log(e)
    }
  },

  async setPassword({ commit }, params) {
    try {
      const result = await this.$api.account.setPassword(params)
      return result
    } catch (e) {
      console.log(e)
    }
  },

  flushAccount({ commit }) {
    commit(ACCOUNT.FLUSH_ACCOUNT)
  },

  setFields({ commit }, fields) {
    commit(ACCOUNT.UPDATE_FIELDS, fields)
  }
}

export const mutations = {
  [ACCOUNT.GET_ADDRESSES_LIST](state, { defaultAddresses, addresses }) {
    state.shippingAddress = defaultAddresses.shippingAddress
    state.billingAddress = defaultAddresses.billingAddress
    state.addressesList = addresses
  },

  [ACCOUNT.UPDATE_DEFAULT_ADDRESSES](
    state,
    { shippingAddress, billingAddress }
  ) {
    state.shippingAddress = shippingAddress
    state.billingAddress = billingAddress
  },

  [ACCOUNT.FLUSH_ACCOUNT](state) {
    state.form = form
    state.fetched = false
    state.shippingAddress = null
    state.billingAddress = null
    state.addressesList = []
  },

  [ACCOUNT.UPDATE_FIELDS](state, fields) {
    const keys = Object.keys(fields)
    keys.forEach((key) => {
      Vue.set(state.form, key, fields[key])
    })
  },

  [ACCOUNT.SET_PROFILE](state, profile) {
    this.$auth.setUser(profile)
    state.fetched = true
  },

  [ACCOUNT.UPDATE_AUTH_USER]() {
    // update user trigger in auth module
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
