import numeral from 'numeral'
import { format, parseISO } from 'date-fns'
import camelCase from 'lodash/camelCase'
import pluralize from 'pluralize'
import iNF from './itemNameFormatter'
import lINF from './listingItemNameFormatter'

const decimalFormat = '0,0.00'
const priceFormat = '0,0'
// const decimalFormatThree = '0,0.000'
const twoDecimalFormat = '0.00'
const threeDecimalFormat = '0.000'
const integerFormat = '0,0'
const twoDigitsFormat = '00'
const dateFormat = 'yyyy-MM-dd'
// const outOfStockDateFormat = 'EEEE, MMMM d, yyyy'
const outOfStockDeliveryDateFormat = 'MMMM d'

export const itemNameFormatter = iNF
export const listingItemNameFormatter = lINF

export function designItemNameFormatter({
  stoneWeight,
  stoneShape,
  stoneType,
  category
}) {
  return `${stoneWeight} Ct. ${this.$t(
    `detailsPage.stoneShapesName.${camelCase(stoneShape)}`
  )} ${this.$t(
    `detailsPage.stoneTypeName.${camelCase(stoneType)}`
  )} ${category}`
}

export function addressFormatter1(address) {
  return [address.street1, address.street2]
    .filter((i) => i)
    .join(', ')
    .trim()
}

export function addressFormatter2(address) {
  const stateAndZip = [address.state, address.zipCode]
    .filter((i) => i)
    .join(' ')
    .trim()
  return [address.city, stateAndZip]
    .filter((i) => i)
    .join(', ')
    .trim()
}

export function twoDecimalFormatter(val) {
  return numeral(val).format(twoDecimalFormat)
}

export function twoDigitsFormatter(val) {
  return numeral(val).format(twoDigitsFormat)
}

export function timeFormatter(val) {
  return format(val, 'MM/dd/yyyy hh:mm a')
}

export function priceFormatter(val) {
  return numeral(val).format(priceFormat)
}

export function moneyFormatter(val) {
  return `$${numeral(val).format(priceFormat)}`
}

export function moneyFormatterWithoutRounding(val) {
  return `$${numeral(val).format(decimalFormat)}`
}

export function decimalFormatter(val) {
  return `${numeral(val).format(decimalFormat)}`
}

export function priceValidDate(date) {
  return format(date, dateFormat)
}

export function outOfStockDate(date) {
  return `${format(parseISO(date), outOfStockDeliveryDateFormat)}`
}

export function caratFormatter(val) {
  return `${numeral(val).format(decimalFormat)} Ct.`
}

export function tcwFormatter(val) {
  if (!this?.$t) return `${numeral(val).format(decimalFormat)} Ct.Tw.`
  return this.$t('common.tcw', [numeral(val).format(decimalFormat)])
}

export function tcwPlainFormatter(val) {
  if (!this?.$t) return `${numeral(val).format(decimalFormat)} Ct.Tw.`
  return this.$t('common.tcwPlain', [numeral(val).format(decimalFormat)])
}

export function tcwTFormatter(val) {
  if (!this?.$t) return `${numeral(val).format(decimalFormat)} Ct.Tw.`
  return this.$t('common.tcw-t', [numeral(val).format(decimalFormat)])
}

export function caratPaveFormatter(val) {
  return `${numeral(val).format(decimalFormat)} Ct.`
}

export function weightPaveFormatter(val) {
  return numeral(val).format(decimalFormat)
}

export function caratURLFormatter(val) {
  return numeral(val).format(decimalFormat)
}

export function sideStonesCaratFormatter(val) {
  return `${numeral(val).format(twoDecimalFormat)} Ct.`
}

export function caratsFormatter(val) {
  return `${numeral(val).format(decimalFormat)} Cts`
}

export function capitalizeFullString(string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
}

export function metalWeightFormatter(val) {
  return `${numeral(val).format(decimalFormat)} g.`
}

export function heightFormatter(val) {
  return `${numeral(val).format(decimalFormat)}H`
}

export function widthFormatter(val) {
  return `${numeral(val).format(decimalFormat)}W`
}

export function lengthFormatter(val) {
  return `${numeral(val).format(decimalFormat)}L`
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function moneyFormatterInt(val) {
  return numeral(val).format(integerFormat)
}

export function moneyMinFormatterInt(val) {
  return numeral(val).format(integerFormat, Math.floor) // round to largest integer less than or equal to a given number
}

export function moneyMaxFormatterInt(val) {
  return numeral(val).format(integerFormat, Math.ceil) // round number up to the next largest integer
}

export function integerFormatter(val) {
  return `${numeral(val).format(integerFormat)}`
}

export function itemNameFormatterNoLocale(item, removeHtmlTags = false) {
  return itemNameFormatter(item, removeHtmlTags)
}

export function formatDateShips(dateString) {
  const date = new Date(dateString)
  const options = { month: 'short', day: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

export function formatAccountDateUTC(date) {
  return format(getUTCDate(date), 'MMMM d, y')
}

export function orderDateLocal(date) {
  return format(getUTCDate(date), 'MMMM d, y')
}

export function formatDateUTC(date) {
  return format(getUTCDate(date), 'MM/dd/yyyy hh:mm:ss')
}

export function formatDateUTCAM(date) {
  return format(getUTCDate(date), 'MM/dd/yyyy hh:mm a')
}

export function getUTCDate(dateString = Date.now()) {
  const date = new Date(dateString)

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  )
}

/**
 * Formats a numeric value into a string with a specified format and appends the suffix " Ct.",
 * removing all trailing zeros after the decimal point in the formatted string.
 * If a decimal point is left at the end of the string after zero removal, it is also removed.
 *
 * @param {number} val - The numeric value to be formatted.
 * @returns {string} A string representing the formatted value with " Ct." appended at the end.
 */

export function formatCaratValueWithoutZero(val) {
  return `${numeral(val)
    .format(threeDecimalFormat)
    .replace(/\.?0+$/, '')} Ct.`
}

export function formatNameStonePlural(gemstone) {
  pluralize.addIrregularRule('topaz', 'topazes')

  return pluralize(gemstone)
}
