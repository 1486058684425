import axios from 'axios'
import { getHost } from '../../utils/config'
import { saveDataToJsonFile, getCountryCode } from '../../utils/utils.js'
import { normalizeStonesList } from '../../utils/normalizers/index.js'
import { getMeta } from '~~/utils/metaUtils'
import {
  colors as stoneColors,
  stones as stoneTypes,
  stonesFetchDate
} from '~~/utils/config/gemstoneConfig.js'

export const ROOT = {
  SET_META: 'SET_META',
  SET_SERVER_COOKIES: 'SET_SERVER_COOKIES',
  SET_PAGE_TITLE: 'SET_PAGE_TITLE',
  SET_USER_LANG: 'SET_USER_LANG',
  SET_USER_COUNTRY_NAME: 'SET_USER_COUNTRY_NAME',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  SET_TYPE_LIST: 'SET_TYPE_LIST',
  SET_FETCHED: 'SET_FETCHED',
  SET_IMAGES_VERSION: 'SET_IMAGES_VERSION'
}

const defaultCountryCode = 'US'
const defaultCountryName = 'United States'
let loadingTimeout = null

export const state = () => ({
  serverCookies: {}, // cookies from SSR
  loading: false,
  typeList: null,
  fetched: false, // loaded data from local storage
  countryCode: defaultCountryCode,
  countryName: defaultCountryName,
  meta: [],
  pageTitle: '',
  imagesVersion: null
})

export const getters = {
  typeList: (store) => store.typeList,
  fetched: (store) => store.fetched,
  loading: (store) => store.loading,
  countryCode: (store) => store.countryCode || defaultCountryCode,
  countryName: (store) => store.countryName,
  meta: (store) => store.meta,
  pageTitle: (store) => store.pageTitle
}

export const actions = {
  async nuxtServerInit({ dispatch, commit }, { app, req, res }) {
    try {
      await dispatch('getImagesVersion')
      await dispatch('getCountryCode', req)
      await dispatch('app/fetchInitialData')
      if (['gemstone'].includes(process.env.siteName))
        await dispatch('fetchStonesList')
      await dispatch('fetchUser')
      if (req) {
        dispatch('app/setOfficeIp', req)
      }
      dispatch('getServerCookies', { app })
    } catch (e) {
      console.log(e)
    }
  },

  getServerCookies({ commit }, { app }) {
    const customStone = app.$cookies.get('customStone')
    const customSetting = app.$cookies.get('customSetting')

    commit(ROOT.SET_SERVER_COOKIES, { customStone, customSetting })
  },

  async fetchUser({ dispatch }) {
    if (!this.$auth.strategy.token.status().valid()) return
    try {
      await dispatch('account/fetchProfile')
    } catch (e) {
      console.log(e)
    }
  },

  /**
   * Fetches the stones list and dispatches the updated list to the app store.
   *
   * @param {Function} dispatch - the dispatch function for updating the store
   * @return {void}
   */
  async fetchStonesList({ dispatch }) {
    // 1 hour from last update. No updates needed
    const isDev = this.$axios.defaults.baseURL.includes('qa-') // Nuxt can't detect environment properly. Only this works properly
    if (Date.now() - stonesFetchDate < 1000 * 60 * 60) {
      const stonesList = normalizeStonesList({ stoneTypes, stoneColors } || {})
      dispatch('app/setStonesList', stonesList.stoneTypes)
      dispatch('app/setColorsList', stonesList.stoneColors)
      return
    }

    const stonesData = await this.$api.app.getString('system_stone_data')
    const data = normalizeStonesList(stonesData || { stoneTypes, stoneColors })
    dispatch('app/setStonesList', data.stoneTypes)
    dispatch('app/setColorsList', data.stoneColors)

    if (!stonesData) return

    const path = require('path')
    const pathToFile = `projects/${process.env.siteName}/static`
    const dirname = __dirname || path.resolve()
    const fileName = isDev
      ? 'system_stone_data_dev.json'
      : 'system_stone_data.json'
    const fullPath = path.join(dirname, pathToFile, fileName)

    saveDataToJsonFile(data, fullPath)
  },

  getMeta({ commit }, context) {
    const { meta, title } = getMeta(context)
    commit(ROOT.SET_META, meta)
    commit(ROOT.SET_PAGE_TITLE, title)
  },

  async getImagesVersion({ commit }) {
    const v = await axios.get(`${getHost()}/images.txt`)
    commit(ROOT.SET_IMAGES_VERSION, v.data)
  },

  getCountryCode({ commit }, req) {
    const { countryCode, countryName } = getCountryCode(req)

    commit(ROOT.SET_USER_LANG, countryCode || defaultCountryCode)
    commit(ROOT.SET_USER_COUNTRY_NAME, countryName || defaultCountryName)
  },

  globalLoading({ commit, dispatch }, state) {
    if (state) {
      dispatch('enableGlobalLoading')
      return
    }
    dispatch('disableGlobalLoading')
  },

  enableGlobalLoading({ commit, state }) {
    if (loadingTimeout) clearTimeout(loadingTimeout)
    if (!state.loading) commit(ROOT.SET_LOADING_STATE, true)
  },

  disableGlobalLoading({ commit }) {
    if (loadingTimeout) clearTimeout(loadingTimeout)
    loadingTimeout = setTimeout(() => {
      commit(ROOT.SET_LOADING_STATE, false)
    }, 500) // to avoid flickering
  },

  setTypeList({ commit }, typeList) {
    commit(ROOT.SET_TYPE_LIST, typeList)
  },

  setFetched({ commit }) {
    commit(ROOT.SET_FETCHED)
  }
}

export const mutations = {
  [ROOT.SET_SERVER_COOKIES](state, cookies) {
    Object.keys(cookies).forEach((key) => {
      if (!cookies[key]) return
      state.serverCookies[key] = cookies[key]
    })
  },

  [ROOT.SET_IMAGES_VERSION](state, version) {
    state.imagesVersion = version
  },

  [ROOT.SET_USER_LANG](state, locale) {
    state.countryCode = locale
  },

  [ROOT.SET_META](state, meta) {
    state.meta = meta
  },

  [ROOT.SET_PAGE_TITLE](state, pageTitle) {
    state.pageTitle = pageTitle
  },

  [ROOT.SET_USER_COUNTRY_NAME](state, country) {
    state.countryName = country
  },

  [ROOT.SET_LOADING_STATE](state, val) {
    state.loading = val
  },

  [ROOT.SET_TYPE_LIST](state, typeList) {
    state.typeList = typeList
  },

  [ROOT.SET_FETCHED](state) {
    state.fetched = true
  }
}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
}
