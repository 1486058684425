export const CREATE_CUSTOM = {
  SET_LANG: 'SET_LANG'
}

export const state = () => ({
  locales: ['en'],
  locale: 'en' // site locale
})

export const getters = {
  locale: (store) => store.locale,
  locales: (store) => store.locales
}

export const actions = {
  setLocale({ commit }, locale) {
    commit(CREATE_CUSTOM.SET_LANG, locale)
  }
}

export const mutations = {
  [CREATE_CUSTOM.SET_LANG](state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
