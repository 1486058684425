import { stones as stonesList } from '../config/gemstoneConfig.js'
export const host = {
  ruby: 'https://thenaturalrubycompany.com/',
  emerald: 'https://www.emeralds.com/',
  gemstone: 'https://naturalgemstones.com/',
  sapphire: 'https://new.thenaturalsapphirecompany.com/'
}

export const listsRoutesMap = {
  cart: 'cart',
  compare: 'compare-list',
  favorites: 'wishlist'
}

export const shortCompanyName = {
  ruby: 'NRC',
  emerald: 'NEC',
  gemstone: 'NGC',
  sapphire: 'NSC'
}

export const companyName = {
  ruby: 'The Natural Ruby Company',
  emerald: 'The Natural Emerald Company',
  gemstone: 'The Natural Gemstone Company',
  sapphire: 'The Natural Sapphire Company'
}

export const domainsMap = {
  ruby: {
    devDomain: 'staging.thenaturalrubycompany.com',
    prodDomain: 'thenaturalrubycompany.com',
    wpDomain: 'about.thenaturalrubycompany.com'
  },
  emerald: {
    devDomain: 'staging.emeralds.com',
    prodDomain: 'emeralds.com',
    wpDomain: 'about.emeralds.com'
  },
  gemstone: {
    devDomain: 'staging.naturalgemstones.com',
    prodDomain: 'naturalgemstones.com',
    wpDomain: 'about.naturalgemstones.com'
  },
  sapphire: {
    devDomain: 'staging.thenaturalsapphirecompany.com',
    prodDomain: 'new.thenaturalsapphirecompany.com',
    wpDomain: 'about.thenaturalsapphirecompany.com'
  }
}

export const phones = {
  us: {
    ruby: '+1-212-221-6136',
    emerald: '+1-212-204-8581',
    gemstone: '+1-212-804-9994',
    sapphire: '+1-212-869-1165'
  }
}

export const locationsLinks = {
  ruby: {
    map: 'https://www.google.com/maps/place/The+Natural+Ruby+Company/@40.7549194,-73.9814686,17z/data=!3m2!4b1!5s0x89c25990a488e0b1:0x340c5b7911e4b2fe!4m6!3m5!1s0x89c259ff56c27a51:0x25d7146796e74b2b!8m2!3d40.7549194!4d-73.9788937!16s%2Fg%2F11j99txh61?hl=en&entry=ttu',
    location:
      'https://www.google.com/maps/dir//The+Natural+Ruby+Company,+6+E+45th+St+Fl.+20,+New+York,+NY+10017,+United+States/@40.7549791,-74.013913,13z/data=!3m1!5s0x89c259076548250b:0xca131d87981af58b!4m8!4m7!1m0!1m5!1m1!1s0x89c259ff56c27a51:0x25d7146796e74b2b!2m2!1d-73.9788937!2d40.7549194'
  },
  emerald: {
    map: 'https://www.google.com/maps/place/The+Natural+Emerald+Company/@40.7549194,-73.9814686,17z/data=!3m1!5s0x89c258fe3164b581:0x6cc8fa24664d4854!4m14!1m7!3m6!1s0x89c259b2fefa8ccf:0xe6f94715b4ceefe5!2sThe+Natural+Emerald+Company!8m2!3d40.7549194!4d-73.9788937!16s%2Fg%2F11gzmx1qrb!3m5!1s0x89c259b2fefa8ccf:0xe6f94715b4ceefe5!8m2!3d40.7549194!4d-73.9788937!16s%2Fg%2F11gzmx1qrb?hl=en&entry=ttu',
    location:
      'https://www.google.com/maps/dir//The+Natural+Emerald+Company,+6+E+45th+St+20th+floor,+New+York,+NY+10017/@40.012927,-76.6185264,8z/data=!3m2!4b1!5s0x89c258fe3164b581:0x6cc8fa24664d4854!4m17!1m7!3m6!1s0x89c259b2fefa8ccf:0xe6f94715b4ceefe5!2sThe+Natural+Emerald+Company!8m2!3d40.7549194!4d-73.9788937!16s%2Fg%2F11gzmx1qrb!4m8!1m1!4e1!1m5!1m1!1s0x89c259b2fefa8ccf:0xe6f94715b4ceefe5!2m2!1d-73.9788937!2d40.7549194?hl=en&entry=ttu'
  },
  gemstone: {
    map: 'https://www.google.com/maps/place/The+Natural+Gemstone+Company/@40.7549194,-73.9788937,15z/data=!4m6!3m5!1s0x89c2599487e60395:0x71e2510a2611c840!8m2!3d40.7549194!4d-73.9788937!16s%2Fg%2F11y329k2c_?hl=en-US&entry=ttu',
    location:
      'https://www.google.com/maps/dir//The+Natural+Gemstone+Company,+6+E+45th+St+20th+floor,+New+York,+NY+10017/@40.7549194,-73.9788937,15z/data=!4m17!1m7!3m6!1s0x89c2599487e60395:0x71e2510a2611c840!2sThe+Natural+Gemstone+Company!8m2!3d40.7549194!4d-73.9788937!16s%2Fg%2F11y329k2c_!4m8!1m1!4e2!1m5!1m1!1s0x89c2599487e60395:0x71e2510a2611c840!2m2!1d-73.9788937!2d40.7549194?hl=en-US&entry=ttu'
  },
  sapphire: {
    map: 'https://www.google.com/maps/place/The+Natural+Sapphire+Company/@40.7577687,-73.9780239,1115m/data=!3m2!1e3!4b1!4m6!3m5!1s0x89c258fe3cd85eab:0x43f4ef0614f7a470!8m2!3d40.7577687!4d-73.9780239!16s%2Fg%2F1th0z87p?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D',
    location:
      'https://www.google.com/maps/dir//The+Natural+Sapphire+Company,+6+E+45th+St,+New+York,+NY+10017/@40.7527672,-73.9800917,2230m/data=!3m1!1e3!4m8!4m7!1m0!1m5!1m1!1s0x89c258fe3cd85eab:0x43f4ef0614f7a470!2m2!1d-73.9780239!2d40.7577687?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D'
  }
}

export const socialsMap = {
  ruby: {
    pinterest: 'https://www.pinterest.com/naturalrubycompany/',
    facebook:
      'https://www.facebook.com/The-Natural-Ruby-Company-110301307385037/',
    instagram: 'https://www.instagram.com/thenaturalrubyco/'
  },
  emerald: {
    pinterest: 'https://www.pinterest.com/naturalemeraldcompany/',
    facebook: 'https://www.facebook.com/TheNaturalEmeraldCompany/',
    instagram: 'https://www.instagram.com/thenaturalemeraldco/'
  },
  gemstone: {
    pinterest: 'https://www.pinterest.com/thenaturalgemstones/',
    facebook:
      'https://www.facebook.com/profile.php?id=61556211775996&mibextid=LQQJ4d',
    instagram: 'https://www.instagram.com/thenaturalgemstoneco/'
  },
  sapphire: {
    pinterest: 'https://www.pinterest.com/naturalsapphireco/',
    facebook: 'https://www.facebook.com/thenaturalsapphirecompany',
    instagram: 'https://www.instagram.com/thenaturalsapphireco/'
  }
}

export const emailsMap = {
  ruby: 'info@thenaturalrubycompany.com',
  emerald: 'info@emeralds.com',
  gemstone: 'info@naturalgemstones.com',
  sapphire: 'info@TheNaturalSapphireCompany.com'
}

export const title = companyName[process.env.siteName]

// Routes list to omit title postfix
export const omitTitlePostfix = ['index', 'financing', 'extend-protection-plan']

const descriptions = {
  ruby: 'We are the authority in ruby engagement rings, natural ruby, and ruby jewelry, since 1939. We have the largest collection of rubies found online.',
  emerald:
    'We are the authority in emerald engagement rings, natural emerald, and emerald jewelry, since 1939. We have the largest collection of emeralds found online.',
  gemstone:
    'We are the authority in gemstone engagement rings, natural gemstones, and gemstone jewelry, since 1939. We have the largest collection of gemstones found online.',
  sapphire:
    'We are the authority in sapphire engagement rings, natural sapphires, and sapphire jewelry, since 1939. We have the largest collection of sapphires found online.'
}

export const chargeAfterLimit = 10000

export const description = descriptions[process.env.siteName]

export const reportTypes = [3, 4, 10] // reports thumbnails types

export const prohibitedCountries = ['BE', 'IN', 'IR']

export const mensStyleID = 83
export const mensStyleRingID = "Men's"

export const defaultCountryCode = 'US'
export const defaultCountryName = 'United States'

export const cookiesOpts = {
  sameSite: 'lax',
  secure: !process.env.isDev,
  path: '/'
}

export const {
  productListTypeByCategory,
  jewelry,
  settings,
  bands,
  bandsByCategory,
  settingsByCategory
} = require('../sitemapUtils')

export const menuOpenDelay = 200
export const menuCloseDelay = 200
export const filterOpenDelay = 200
export const filterCloseDelay = 500
export const updateItemsListDelay = 500
export const video360Speed = 0.5 // from 0 to 1
export const defaultWaxPrice = 95
export const showDocsOptionsEdge = Number.POSITIVE_INFINITY // change this to necessary threshold. For example 5000

export const companyAddress = {
  shippingStreet1: '608 5th Avenue',
  shippingStreet2: '11th Floor Penthouse',
  shippingCity: 'New York',
  shippingZipCode: '10020',
  shippingState: 'NY',
  shippingCountryId: 'US'
}

export const stateTaxes = {
  NY: 8.88
}

export const skipCache = [
  /^\/helpcode/,
  /^\/design-your-own/,
  /^\/emerald/,
  /^\/wedding/,
  /^\/rubies/,
  /^\/ruby/,
  /^\/design/,
  /^\/account/,
  /^\/cart/,
  /^\/compare/,
  /^\/wishlist/,
  /^\/favorites/,
  /^\/gemstone/,
  /^\/mens-gemstone/,
  ...stonesList.map((stone) => new RegExp(`^\\/${stone.kebabCase}/`))
]

export const cartRoutes = [
  // 'cart-index', // on this page cart item will be synced automatically
  'cart-index-secure-payment',
  'cart-index-payment-order'
]

export const jewelryByCategory = [
  'Ring',
  'Earring',
  'Pendant',
  'Necklace',
  'Bracelet',
  'Bangle',
  'Brooch',
  'Cufflink'
]

export const itemTypeByListingType = {
  ringSettings: 'Ring',
  ringsList: 'Ring',
  earringSettings: 'Earring',
  earringsList: 'Earring',
  braceletSettings: 'Bracelet',
  braceletsList: 'Bracelet',
  pendantSettings: 'Pendant',
  necklaceSettings: 'Necklace',
  necklacesPendantsList: 'Pendant',
  broochesList: 'Brooch',
  cufflinksList: 'Cufflink'
}

export const settingsListByCategory = {
  ring: 'ringSettings',
  earring: 'earringSettings',
  bracelet: 'braceletSettings',
  pendant: 'pendantSettings',
  necklace: 'necklaceSettings',
  Ring: 'ringSettings',
  Earring: 'earringSettings',
  Bracelet: 'braceletSettings',
  Pendant: 'pendantSettings',
  Necklace: 'necklaceSettings'
}

export const routesMap = {
  ruby: {
    Setting_Earring: 'design-your-own-setting-ruby-earring-settings',
    Setting_Bracelet: 'design-your-own-setting-ruby-bracelet-settings',
    Setting_Ring: 'design-your-own-setting-ruby-engagement-ring-settings',
    Setting_Pendant: 'design-your-own-setting-ruby-pendants-settings',
    Setting_Necklace: 'design-your-own-setting-ruby-necklaces-settings'
  },
  emerald: {
    Setting_Earring: 'design-your-own-setting-emerald-earring-settings',
    Setting_Bracelet: 'design-your-own-setting-emerald-bracelet-settings',
    Setting_Ring: 'design-your-own-setting-emerald-engagement-ring-settings',
    Setting_Pendant: 'design-your-own-setting-emerald-pendants-settings',
    Setting_Necklace: 'design-your-own-setting-emerald-necklaces-settings'
  },
  gemstone: {
    Setting_Earring: 'gemstone-earring-settings',
    Setting_Bracelet: 'gemstone-bracelet-settings',
    Setting_Ring: 'gemstone-ring-settings',
    Setting_Pendant: 'gemstone-necklace-pendant-settings',
    Setting_Necklace: 'gemstone-necklace-pendant-settings'
  },
  sapphire: {
    Setting_Earring: 'sapphire-earring-settings',
    Setting_Bracelet: 'sapphire-bracelet-settings',
    Setting_Ring: 'sapphire-engagement-ring-settings',
    Setting_Pendant: 'sapphire-necklace-pendant-settings',
    Setting_Necklace: 'sapphire-necklace-pendant-settings'
  }
}

export const settingsListBySettingUrl = {
  ruby: {
    'design-your-own-setting-ruby-engagement-ring-settings': 'ringSettings',
    'design-your-own-setting-mens-ring-settings': 'ringSettings',
    'design-your-own-setting-ruby-earring-settings': 'earringSettings',
    'design-your-own-setting-ruby-bracelet-settings': 'braceletSettings',
    'design-your-own-setting-ruby-necklace-pendant-settings':
      'necklaceSettings',
    'design-your-own-setting-ruby-pendant-settings': 'necklaceSettings',
    'design-your-own-setting-ruby-necklace-settings': 'necklaceSettings'
  },
  emerald: {
    'design-your-own-setting-emerald-engagement-ring-settings': 'ringSettings',
    'design-your-own-setting-mens-ring-settings': 'ringSettings',
    'design-your-own-setting-emerald-earring-settings': 'earringSettings',
    'design-your-own-setting-emerald-bracelet-settings': 'braceletSettings',
    'design-your-own-setting-emerald-necklace-pendant-settings':
      'necklaceSettings',
    'design-your-own-setting-emerald-pendants-settings': 'necklaceSettings',
    'design-your-own-setting-emerald-necklaces-settings': 'necklaceSettings'
  },
  gemstone: {
    'gemstone-ring-settings': 'ringSettings',
    'mens-gemstone-ring-settings': 'ringSettings',
    'gemstone-earring-settings': 'earringSettings',
    'gemstone-bracelet-settings': 'braceletSettings',
    'gemstone-necklace-pendant-settings': 'necklaceSettings'
  },
  sapphire: {
    'sapphire-engagement-ring-settings': 'ringSettings',
    'sapphire-ring-settings': 'ringSettings',
    'mens-sapphire-ring-settings': 'ringSettings',
    'sapphire-earring-settings': 'earringSettings',
    'sapphire-bracelet-settings': 'braceletSettings',
    'sapphire-necklace-pendant-settings': 'necklaceSettings'
  }
}

export const itemsTypeByRouteName = {
  stonesList: [
    'rubies',
    'rubies-star-rubies',
    'rubies-ruby-cabochon',
    'rubies-gemologist-favorites',
    'rubies-burmese-rubies',
    'rubies-mozambique-rubies',

    'emeralds',
    'emeralds-star-emeralds',
    'emeralds-emerald-cabochon',
    'emeralds-gemologist-favorites',
    'emeralds-colombian-emeralds',
    'emeralds-zambian-emeralds'
  ],
  stonePairsList: [
    'rubies-matched-pairs-of-rubies',
    'rubies-star-ruby-pairs',
    'rubies-matched-pairs-of-rubies-cabochon',

    'emeralds-matched-pairs-of-emeralds',
    'emeralds-star-emerald-pairs',
    'emeralds-matched-pairs-of-emeralds-cabochon'
  ],
  braceletsList: [
    'ruby-jewelry-ruby-bracelets',

    'emerald-jewelry-emerald-bracelets'
  ],
  broochesList: [
    'ruby-jewelry-ruby-brooches',

    'emerald-jewelry-emerald-brooches'
  ],
  cufflinksList: [
    'ruby-jewelry-ruby-cufflinks',

    'emerald-jewelry-emerald-cufflinks'
  ],
  earringSettings: [
    'design-your-own-setting-ruby-earring-settings',

    'design-your-own-setting-emerald-earring-settings',

    'gemstone-earring-settings',

    'sapphire-earring-settings'
  ],
  braceletSettings: [
    'design-your-own-setting-ruby-bracelet-settings',

    'design-your-own-setting-emerald-bracelet-settings',

    'gemstone-bracelet-settings',

    'sapphire-bracelet-settings'
  ],
  earringsList: [
    'ruby-jewelry-ruby-earrings',

    'emerald-jewelry-emerald-earrings'
  ],
  necklaceSettings: [
    'design-your-own-setting-ruby-necklace-pendant-settings',

    'design-your-own-setting-emerald-necklaces-settings',

    'gemstone-necklace-pendant-settings',

    'sapphire-necklace-pendant-settings'
  ],
  necklacesPendantsList: [
    'ruby-jewelry-ruby-necklaces-pendants',

    'emerald-jewelry-emerald-necklaces-pendants'
  ],
  pendantSettings: [
    'design-your-own-setting-ruby-necklace-pendant-settings',

    'design-your-own-setting-emerald-pendants-settings',

    'gemstone-necklace-pendant-settings',

    'sapphire-necklace-pendant-settings'
  ],
  ringSettings: [
    'design-your-own-setting-ruby-engagement-ring-settings',
    'design-your-own-setting-mens-ring-settings',

    'design-your-own-setting-emerald-engagement-ring-settings',

    'gemstone-ring-settings',
    'mens-gemstone-ring-settings',

    'sapphire-engagement-ring-settings',
    'sapphire-ring-settings',
    'mens-sapphire-ring-settings'
  ],
  ringsList: [
    'ruby-jewelry-ruby-rings',
    'ruby-jewelry-ruby-engagement-rings',
    'ruby-jewelry-star-ruby-rings',
    'ruby-jewelry-burmese-ruby-rings',
    'ruby-jewelry-mozambique-ruby-rings',
    'ruby-jewelry-mens-ruby-rings',

    'emerald-jewelry-emerald-rings',
    'emerald-jewelry-engagement-emerald-rings',
    'emerald-jewelry-colombian-emerald-rings',
    'emerald-jewelry-zambian-emerald-rings',
    'emerald-jewelry-mens-emerald-rings'
  ],
  weddingBands: [
    'ruby-wedding-rings-bands',
    'emerald-wedding-rings-bands',
    'sapphire-wedding-rings-bands',
    'blue-sapphire-wedding-rings-bands',
    'blue-sapphire-diamond-wedding-rings-bands',
    'pink-sapphire-wedding-rings-bands',
    'pink-sapphire-diamond-wedding-rings-bands',
    'diamond-wedding-rings-bands'
  ],
  weddingBandsPlain: [
    'wedding-bands-without-gemstone',
    'plain-wedding-rings-bands'
  ]
}

export const stones = ['stonePairsList', 'stonesList']

export const settingsItems = [
  'Setting_Ring',
  'Setting_Earring',
  'Setting_Bracelet',
  'Setting_Pendant',
  'Setting_Necklace'
]

export const defaultAddresses = {
  shippingAddress: null,
  billingAddress: null
}

export const accountForm = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  mailingList: false,
  gender: null
}

export const profileForm = {
  billingAddress: {},
  email: null,
  firstName: null,
  gender: null,
  lastName: null,
  mailingList: true,
  phone: null,
  shippingAddress: {}
}

export const defaultQuickSearchValues = {
  stones: [],
  jewelry: [],
  pairs: [],
  bands: [],
  settings: []
}

export const defaultFiltersValues = {
  stoneShapesFilter: [],
  stoneOriginsFilter: [],
  priceRangeFilter: [],
  caratRangeFilter: [],
  lengthRangeFilter: [],
  widthRangeFilter: [],
  sortByWeight: 0,
  sortByPrice: 0,
  featuredFirst: 1,
  newFirst: 0
}

export const defaultJewelryFiltersValues = {
  metalTypesFilter: [],
  stoneShapesFilter: [],
  stoneOriginsFilter: [],
  priceRangeFilter: [],
  caratRangeFilter: [],
  enhancementsFilter: [],
  categoriesFilter: [],
  sortByWeight: 0,
  sortByPrice: 0,
  featuredFirst: 1,
  newFirst: 0
}

export const defaultWeddingBandsPlainFiltersValues = {
  metalTypesFilter: [],
  stylesFilter: [],
  sortBy: 'FeaturedFirst',
  sortOrder: 1
}

export const defaultWeddingBandsFiltersValues = {
  metalTypesFilter: [],
  stoneTypesFilter: [],
  stoneShapesFilter: [],
  sortBy: 'FeaturedFirst',
  sortOrder: 1
}

export const defaultSettingsFiltersValues = {
  metalTypesFilter: [],
  centerStoneShapesFilter: [],
  sideStoneShapesFilter: [],
  designStylesFilter: [],
  sortBy: 'FeaturedFirst',
  sortOrder: 1
}

export const defaultFiltersMap = {
  ringsList: defaultJewelryFiltersValues,
  stonesList: defaultFiltersValues,
  stonePairsList: defaultFiltersValues,
  earringsList: defaultJewelryFiltersValues,
  necklacesPendantsList: defaultJewelryFiltersValues,
  braceletsList: defaultJewelryFiltersValues,
  broochesList: defaultJewelryFiltersValues,
  cufflinksList: defaultJewelryFiltersValues,
  weddingBandsPlain: defaultWeddingBandsPlainFiltersValues,
  weddingBands: defaultWeddingBandsFiltersValues,
  ringSettings: defaultSettingsFiltersValues,
  earringSettings: defaultSettingsFiltersValues,
  braceletSettings: defaultSettingsFiltersValues,
  pendantSettings: defaultSettingsFiltersValues,
  necklaceSettings: defaultSettingsFiltersValues
}

export const defaultPageSize = 60
export const tabletPageSize = 60

export const defaultDesignersCollectionQuery = {
  pageNumber: 1,
  calculateCount: true,
  previousPages: false,
  pageSize: 12,
  pageCount: 1,
  query: {
    designerName: null
  }
}

export const defaultCustomDesignsListQuery = {
  pageNumber: 1,
  calculateCount: false,
  pageSize: 15,
  query: {
    preset: 0,
    settingId: null,
    metalType: [],
    category: [],
    origins: [],
    styles: [],
    centreStoneShapes: [],
    price: {
      min: null,
      max: null
    },
    weight: {
      min: null,
      max: null
    },
    enhancements: []
  }
}

// query template. Default values: defaultFiltersValues const
export const defaultGemListQuery = {
  pageNumber: 1,
  calculateCount: true,
  previousPages: false,
  pageSize: defaultPageSize,
  query: {
    preset: 0,
    id: null,
    settingId: null,
    shapes: [],
    clarity: [],
    intensity: [],
    labColors: [],
    labTypes: [],
    colorCategories: [],
    origins: [],
    enhancements: [],
    price: {
      min: null,
      max: null
    },
    weight: {
      min: null,
      max: null
    },
    width: {
      min: null,
      max: null
    },
    length: {
      min: null,
      max: null
    },
    cuttingStyles: [],
    stoneTypes: [],
    colors: [],
    isStar: 0,
    webCategory: 0,
    sortByWeight: 0,
    sortByPrice: 0,
    featuredFirst: 1,
    newFirst: 0
  }
}

// query template. Default values: defaultJewelryFiltersValues const
export const defaultJewelryListQuery = {
  pageNumber: 1,
  calculateCount: true,
  previousPages: false,
  pageSize: defaultPageSize,
  query: {
    preset: 0,
    id: null,
    dataType: 0,
    metals: [],
    categories: [],
    clarity: [],
    intensity: [],
    shapes: [],
    centerStoneShapes: [],
    sideStoneShapes: [],
    styles: [],
    cuttingStyles: [],
    labColors: [],
    labTypes: [],
    colorCategories: [],
    origins: [],
    price: {
      min: null,
      max: null
    },
    weight: {
      min: null,
      max: null
    },
    enhancements: [],
    stoneTypes: [],
    colors: [],
    sortByWeight: 0,
    sortByPrice: 0,
    featuredFirst: 1,
    newFirst: 0
  }
}

// query template. Default values: defaultWeddingBandsPlainFiltersValues const
export const defaultWeddingBandsPlainQuery = {
  pageNumber: 1,
  calculateCount: true,
  previousPages: false,
  pageSize: defaultPageSize,
  query: {
    preset: 0,
    id: null,
    categoryId: 1,
    metals: [],
    styles: [],
    stoneTypes: [],
    stoneShapes: [],
    bandWidth: {
      min: null,
      max: null
    },
    sortBy: 'FeaturedFirst',
    sortOrder: 1
  }
}

// query template. Default values: defaultWeddingBandsFiltersValues const
export const defaultWeddingBandsQuery = {
  pageNumber: 1,
  calculateCount: true,
  previousPages: false,
  pageSize: defaultPageSize,
  query: {
    preset: 0,
    id: null,
    categoryId: 2,
    metals: [],
    styles: [],
    stoneTypes: [],
    stoneShapes: [],
    bandWidth: {
      min: null,
      max: null
    },
    sortBy: 'FeaturedFirst',
    sortOrder: 1
  }
}

// query template. Default values: defaultSettingsFiltersValues const
export const defaultSettingsQuery = {
  pageNumber: 1,
  calculateCount: true,
  previousPages: false,
  pageSize: defaultPageSize,
  query: {
    preset: 0,
    category: 'ring',
    stoneId: null,
    metals: [],
    centerStoneShapes: [],
    sideStoneShapes: [],
    styles: [],
    sortBy: 'FeaturedFirst',
    sortOrder: 1
  }
}

export const defaultWeddingBandsSideStonesQuery = {
  bandId: null,
  bandWidth: 0
}

export const defaultShareLinkQuery = {
  stoneId: null,
  stoneSetId: null,
  settingId: null,
  bandId: null,
  jewelryItemId: null,
  finishedDesign: false,
  engraving: null,
  metalTypeCode: null,
  ringSize: null,
  width: null,
  finish: null,
  sideStones: []
}

export const defaultShareFormQuery = {
  senderName: null,
  senderEmail: null,
  message: null,
  recipientsEmail: [],
  receiveCopy: true,
  pageLink: null,
  options: {}
}

export const defaultWeddingBandsPlainDetails = {
  id: null,
  metalTypeCode: null,
  size: 0,
  width: 0,
  sideStones: []
}

export const defaultListItem = {
  stoneId: null,
  stoneSetId: null,
  jewelryItemId: null,
  bandId: null,
  settingId: null,
  isWax: false,
  finishedDesign: false,
  metalTypeCode: null,
  guid: null
}

export const itemsWithSelectedOptions = [
  'Ring',
  'Plain Band',
  'Wedding Band',
  'Setting_Ring',
  'Custom' // custom jewelry
]

export const itemIdByCategory = {
  Ring: 'jewelryItemId',
  Stone: 'stoneId',
  Pair: 'stoneSetId',
  Earring: 'jewelryItemId',
  Pendant: 'jewelryItemId',
  Necklace: 'jewelryItemId',
  Bracelet: 'jewelryItemId',
  Cufflink: 'jewelryItemId',
  Brooch: 'jewelryItemId',
  'Plain Band': 'bandId',
  'Wedding Band': 'bandId',
  Setting_Ring: 'settingId',
  Setting_Earring: 'settingId',
  Setting_Pendant: 'settingId',
  Setting_Necklace: 'settingId'
}

export const urlShapeToFilter = {
  emeraldCut: 'Emerald Cut',
  oval: 'Oval',
  asscher: 'Asscher',
  round: 'Round',
  cushion: 'Cushion',
  radiant: 'Radiant',
  heart: 'Heart'
}

export const urlOriginToFilter = {
  colombia: 'Colombia',
  zambia: 'Zambia',
  afghanistan: 'Afghanistan',
  ethiopia: 'Ethiopia',
  madagascar: 'Madagascar',
  pakistan: 'Pakistan',
  russia: 'Russia',
  brazil: 'Brazil',
  zimbabwe: 'Zimbabwe',
  mozambique: 'Mozambique',
  burmaMyanmar: 'Burma (Myanmar)',
  thailandSiam: 'Thailand (Siam)',
  ceylon: 'Ceylon',
  montana: 'Montana',
  nigeria: 'Nigeria',
  vietnam: 'Vietnam',
  tajikistan: 'Tajikistan',
  thailand: 'Thailand',
  tanzania: 'Tanzania',
  sriLankaCeylon: 'Sri Lanka (Ceylon)',
  cambodia: 'Cambodia',
  australia: 'Australia',
  kashmir: 'Kashmir'
}

export const stonesByCategory = ['Stone', 'Pair']

export const metalsFinishMap = {
  hammer: 'details/metal-finish/hammer@2x.jpg',
  hammerFull: 'details/metal-finish/hammerFull@2x.jpg',
  highPolish: 'details/metal-finish/highPolish@2x.jpg',
  highPolishFull: 'details/metal-finish/highPolishFull@2x.jpg',
  sandBlast: 'details/metal-finish/sandBlast@2x.jpg',
  sandBlastFull: 'details/metal-finish/sandBlastFull@2x.jpg',
  satin: 'details/metal-finish/satin@2x.jpg',
  satinFull: 'details/metal-finish/satinFull@2x.jpg',
  crossSatin: 'details/metal-finish/crossSatin@2x.jpg',
  crossSatinFull: 'details/metal-finish/crossSatinFull@2x.jpg',
  satinFinish: 'details/metal-finish/satinFinish@2x.jpg',
  satinFinishFull: 'details/metal-finish/satinFinishFull@2x.jpg',
  stoneFinish: 'details/metal-finish/stoneFinish@2x.jpg',
  stoneFinishFull: 'details/metal-finish/stoneFinishFull@2x.jpg',
  wireMatte: 'details/metal-finish/wireMatt@2x.jpg',
  wireMatteFull: 'details/metal-finish/wireMattFull@2x.jpg'
}

export const linksEmbedSocial = {
  ruby: {
    google:
      'https://www.google.com/search?q=The+Natural+Ruby+Company&sca_esv=f4dacfe81c780abe&sca_upv=1&rlz=1C5CHFA_enUS979US979&ei=JU77ZeW7Ebfl5NoP9LAF&ved=0ahUKEwil4qHp3YOFAxW3MlkFHXRYAQAQ4dUDCBA&uact=5&oq=The+Natural+Ruby+Company&gs_lp=Egxnd3Mtd2l6LXNlcnAiGFRoZSBOYXR1cmFsIFJ1YnkgQ29tcGFueTILEC4YgAQYxwEYrwEyBRAAGIAEMgQQABgeMgsQABiABBiKBRiGAzILEAAYgAQYigUYhgMyCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGAzILEAAYgAQYigUYhgMyGhAuGIAEGMcBGK8BGJcFGNwEGN4EGOAE2AECSNgHUPUDWLEGcAJ4AZABAJgBR6ABgQKqAQE0uAEDyAEA-AEBmAIGoAKXAsICChAAGEcY1gQYsAPCAg0QABiABBiKBRhDGLADwgIZEC4YgAQYigUYQxjHARjRAxjIAxiwA9gBAcICGRAuGIAEGIoFGEMYxwEYrwEYyAMYsAPYAQHCAgYQABgHGB7CAggQABgIGAcYHsICCBAAGAUYBxgewgIHEAAYgAQYDcICCBAAGAgYHhgNmAMAiAYBkAYNugYECAEYCLoGBggCEAEYFJIHATagB_wd&sclient=gws-wiz-serp#lrd=0x89c259ff56c27a51:0x25d7146796e74b2b,1,,,,`'
  },
  emerald: {
    google:
      'https://www.google.com/search?q=The+Natural+Emerald+Company&sca_esv=f4dacfe81c780abe&sca_upv=1&rlz=1C5CHFA_enUS979US979&ei=hk77ZdytF4Ln5NoPuZOimAk&ved=0ahUKEwjciMiX3oOFAxWCM1kFHbmJCJMQ4dUDCBA&uact=5&oq=The+Natural+Emerald+Company&gs_lp=Egxnd3Mtd2l6LXNlcnAiG1RoZSBOYXR1cmFsIEVtZXJhbGQgQ29tcGFueTIREC4YgAQYigUYkQIYxwEYrwEyBRAAGIAEMgUQABiABDIEEAAYHjIEEAAYHjILEAAYgAQYigUYhgMyCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGAzILEAAYgAQYigUYhgMyIBAuGIAEGIoFGJECGMcBGK8BGJcFGNwEGN4EGOAE2AEBSKcLUJ0FWKQKcAJ4AZABAJgBVqABjQSqAQE3uAEDyAEA-AEBmAIIoALbA8ICChAAGEcY1gQYsAPCAgYQABgHGB7CAgwQLhgHGB4YxwEY0QPCAggQABgIGAcYHsICDRAuGIAEGA0YxwEYrwHCAgcQABiABBgNwgIGEAAYHhgNwgIcEC4YgAQYDRjHARivARiXBRjcBBjeBBjgBNgBAZgDAIgGAZAGCLoGBggBEAEYFJIHATigB-dG&sclient=gws-wiz-serp#lrd=0x89c259b2fefa8ccf:0xe6f94715b4ceefe5,1,,,,'
  },
  gemstone: {
    google:
      'https://www.google.com/search?q=The+Natural+Gemstone+Company&sca_esv=f4dacfe81c780abe&sca_upv=1&rlz=1C5CHFA_enUS979US979&ei=ME77ZeW4Dbzl5NoPscKA2A0&ved=0ahUKEwjlkL3u3YOFAxW8MlkFHTEhANsQ4dUDCBA&uact=5&oq=The+Natural+Gemstone+Company&gs_lp=Egxnd3Mtd2l6LXNlcnAiHFRoZSBOYXR1cmFsIEdlbXN0b25lIENvbXBhbnkyBRAAGIAEMgsQABiABBiKBRiGAzILEAAYgAQYigUYhgNIiw1QmwRY4gpwAngBkAEAmAFboAGFBKoBATi4AQPIAQD4AQGYAgmgAt8DwgIKEAAYRxjWBBiwA8ICBhAAGAcYHsICDBAuGAcYHhjHARjRA8ICBxAAGIAEGA3CAggQABgIGAcYHsICDhAuGAgYHhgNGMcBGNEDwgIEEAAYHsICBhAAGAgYHpgDAIgGAZAGCJIHATmgB8ww&sclient=gws-wiz-serp#lrd=0x89c2599487e60395:0x71e2510a2611c840,1,,,,'
  },
  sapphire: {
    google:
      'https://www.google.com/search?q=The+Natural+Sapphire+Company&newwindow=1&sca_esv=f4dacfe81c780abe&sca_upv=1&rlz=1C5CHFA_enUS979US979&sxsrf=ADLYWII2QVIxn3pitMDhc9Hq75CQuThemg%3A1727794028002&ei=awv8Zt_iPMnzi-gPi9Dm-Q8&ved=0ahUKEwifxKKqtu2IAxXJ-QIHHQuoOf8Q4dUDCA8&uact=5&oq=The+Natural+Sapphire+Company&gs_lp=Egxnd3Mtd2l6LXNlcnAiHFRoZSBOYXR1cmFsIFNhcHBoaXJlIENvbXBhbnkyChAjGIAEGCcYigUyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyCxAAGIAEGJECGIoFMgUQABiABDIEEAAYHkj_LFCWFVi3JHAEeAGQAQCYAcMBoAG6CKoBAzAuOLgBA8gBAPgBAZgCC6ACmwfCAgoQABiwAxjWBBhHwgIGEAAYBxgewgIIEAAYBxgIGB7CAgcQABiABBgNwgIHECMYsAIYJ8ICCBAAGAUYBxgemAMAiAYBkAYIkgcDNC43oAfOTA&sclient=gws-wiz-serp'
  },
  verifiedReviews:
    'https://www.verified-reviews.com/reviews/thenaturalsapphirecompany.com'
}

export const linkMiningVideo = {
  ruby: 'https://video.thenaturalsapphirecompany.com/b3538598-4ce4-4464-9958-418277899a20/hls/fura-mozambique-ruby-ming.m3u8',
  emerald:
    'https://video.thenaturalsapphirecompany.com/aec6484b-db7d-4a42-8212-39f0aa6abb20/hls/fura-colombia-emerald-ming.m3u8'
}

export const linksCitiPay = {
  citiLogIn:
    'https://citiretailservices.citibankonline.com/RSauth/signon?pageName=signon&siteId=PLCN_CPAY&langId=en_US',
  citiRegister:
    'https://citiretailservices.citibankonline.com/RSenroll/registration?pageName=registration&siteId=PLCN_CPAY&langId=en_US#verify'
}
// recaptcha - a ip list of white office addresses
export const ipWhitelist = [
  '72.80.75.55',
  '124.43.71.139',
  '124.43.78.195',
  '124.43.78.194',
  '123.231.9.124',
  '124.43.17.214',
  '185.154.13.12',
  '72.80.117.138'
]
