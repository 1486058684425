import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import actions, { isInList } from '../_base/actions'
import LIST from './constants'
import { serializeOrderData } from '~~/utils/serializators'
import { normalizeOrder } from '~~/utils/normalizers/order'
import { serializeTaxesAddress } from '~~/utils/serializators/taxJar'
import {
  cookiesOpts,
  productListTypeByCategory
} from '~~/utils/definitions/defaults.js'

const metadataCache = 'metadata-cache'

export default {
  ...actions,

  updateCardPrice({ commit, getters }, params) {
    commit(LIST.UPDATE_CARD_PRICE, { ...params, totalPrice: getters.getTotal })
  },

  setShippingBillingCountry({ commit, rootState }) {
    commit(LIST.SET_SHIPPING_BILLING_ADDRESS, rootState.countryCode)
  },

  setTaxHash({ commit }, hash) {
    commit(LIST.SET_TAX_HASH, hash)
  },

  async calculateTax({ state, commit, getters }) {
    try {
      const tax = await this.$api.cart.taxes(
        serializeTaxesAddress(state, getters)
      )
      const st =
        state.metaData.states.us.find(
          (s) => s.key === getters.addressForm.shippingState
        ) || {}

      commit(LIST.SET_TAX, { ...tax, state: st })
    } catch (e) {
      commit(LIST.BACKEND_TAX_CALC_MODE, true)
    }
  },

  removeTax({ state, commit }) {
    if (!state.tax) return
    commit(LIST.REMOVE_TAX)
  },

  addExtend({ commit }, { item, plan }) {
    commit(LIST.ADD_EXTEND, { item, plan })
  },

  removeExtend({ commit }, { item }) {
    commit(LIST.REMOVE_EXTEND, { item })
  },

  async addToList(
    { state, commit, getters, rootState, dispatch },
    {
      item,
      serverSync = true,
      addSilent = false,
      removeSilent = false,
      extendPlan
    }
  ) {
    const addType = addSilent ? LIST.ADD_ITEM_SILENT : LIST.ADD_ITEM
    const removeType = removeSilent ? LIST.REMOVE_ITEM_SILENT : LIST.REMOVE_ITEM
    if (serverSync)
      await dispatch('addToServerList', { ...item, extendPlan, addSilent })
    if (isInList(getters.allListItems, item)) {
      if (rootState.auth.loggedIn) {
        await dispatch('removeFromServerList', { item, removeSilent })
      } else commit(removeType, { item })
      this.$cookies.set(
        `${state._namespace}Items`,
        Number(getters.allListItems.length),
        cookiesOpts
      )
      Vue.prototype.$bus.$emit('rj-remove-cart-item', item)
      if (!getters.allListItems.length)
        Vue.prototype.$bus.$emit('rj-clear-cart-data')
      await dispatch('getParamsHash')
      return
    }
    this.$cookies.set(
      `${state._namespace}Items`,
      Number(getters.allListItems.length) + 1,
      cookiesOpts
    )
    commit(addType, { item: { ...item, extendPlan } })
    Vue.prototype.$bus.$emit('rj-set-cart-item', item)
    await dispatch('getParamsHash')
  },

  async removeFromList({ state, commit, getters, dispatch, rootState }, item) {
    if (rootState.auth.loggedIn) {
      await dispatch('removeFromServerList', { item })
      Vue.prototype.$bus.$emit('rj-remove-cart-item', item)
      await dispatch('getParamsHash')
      return
    }
    const productListType = productListTypeByCategory[item.category]
    this.$cookies.set(
      `${state._namespace}Items`,
      Number(getters.allListItems.length) - 1,
      cookiesOpts
    )
    commit(LIST.REMOVE_ITEM, { item, type: productListType })
    Vue.prototype.$bus.$emit('rj-remove-cart-item', item)
    await dispatch('getParamsHash')
  },

  async updateListItem({ commit, rootState, dispatch }, { item, silent }) {
    if (rootState.auth.loggedIn) {
      await dispatch('addToServerListForce', { item, update: false })
    }
    if (silent) {
      Vue.prototype.$bus.$emit('rj-remove-cart-item', item)
      commit(LIST.UPDATE_ITEM_SILENT, { item })
      Vue.prototype.$bus.$emit('rj-set-cart-item', item)
      await dispatch('getParamsHash')
      return
    }
    Vue.prototype.$bus.$emit('rj-remove-cart-item', item)
    commit(LIST.UPDATE_ITEM, { item })
    Vue.prototype.$bus.$emit('rj-set-cart-item', item)
    await dispatch('getParamsHash')
  },

  async fetchMetaData({ commit, state }) {
    if (this.$cookies.get(metadataCache) && state.metaData.shippingRates) return
    const metaData = await this.$api.cart.fetchCartMetadata()
    this.$cookies.set(metadataCache, true, {
      maxAge: 60 * 60 * 4,
      ...cookiesOpts
    })
    commit(LIST.SET_META_DATA, metaData)
  },

  setDocumentationType({ commit }, type) {
    commit(LIST.SET_DOCUMENTATION_TYPE, type)
  },

  clearShippingRates({ commit }) {
    commit(LIST.CLEAR_SHIPPING_RATES)
  },

  confirmConversion({ commit }) {
    commit(LIST.CONFIRM_CONVERSION)
  },

  async postOrder(
    { commit, rootState, state, getters, dispatch },
    additionalData = {}
  ) {
    const orderData = {
      ...serializeOrderData(state, getters),
      ...additionalData
    }
    try {
      const orderType = rootState.auth.loggedIn ? 'Auth' : ''
      const response = await this.$api.cart[`make${orderType}Order`](orderData)

      if (await dispatch('areSubmitErrorsExist', response)) {
        return Promise.resolve(response)
      }

      if (typeof response === 'object' && response.id) {
        commit(LIST.RESET_CARD)
        dispatch('flushCart')
        commit(LIST.ORDER_SUCCESS, normalizeOrder(response))
        commit(LIST.SET_ORDER_RECEIVED, true)
        commit(LIST.SET_ORDER_CONFIRMED_STATE, false)
        commit(LIST.CLEAR_ALL_CREDIT_CARDS)
      }
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  areSubmitErrorsExist({ state }, result) {
    return !!result.error.statusCode
  },

  flushCart({ commit, getters, dispatch, rootState }) {
    if (rootState.auth.loggedIn) {
      cloneDeep(getters.items).forEach((item) => {
        dispatch('removeFromServerList', { item, removeSilent: true })
      })
    }
    commit(LIST.FLUSH_CART)
  },

  setFormValue({ commit, getters }, data) {
    commit(LIST.SET_FORM_VALUE, data)
  },

  setPaymentFormValue({ commit }, data) {
    commit(LIST.SET_PAYMENT_FORM_VALUE, data)
  },

  updateAddressForm({ commit }, formData) {
    commit(LIST.UPDATE_ADDRESS_FORM_VALUE, formData)
  },

  resetDirty({ commit }) {
    commit(LIST.SET_DIRTY, false)
  },

  addReplaceEditedCreditCard({ commit, getters }, card) {
    commit(LIST.ADD_REPLACE_EDITED_CREDIT_CARD, {
      card,
      totalPrice: getters.getTotal
    })
  },

  creditCardAddBtnStateChange({ commit }, value) {
    commit(LIST.CREDIT_CARD_ADD_BTN_STATE_CHANGE, value)
  },

  removeCreditCard({ commit, getters }, card) {
    commit(LIST.DELETE_CREDIT_CARD, {
      card,
      totalPrice: getters.getTotal
    })
  },

  clearAllCreditCards({ commit }) {
    commit(LIST.CLEAR_ALL_CREDIT_CARDS)
  },

  async sendShareCart({ commit }, formData) {
    try {
      const response = await this.$api.cart.sendShareCart(formData)
      if (response) {
        return response
      } else {
        throw new Error('Unexpected response: ' + response)
      }
    } catch (error) {
      console.error('Error in sendShareCart action:', error)
      throw error
    }
  }
}
