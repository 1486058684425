import cloneDeep from 'lodash/cloneDeep'
import actions from '../_base/actions'
import { ITEMS_LIST } from '../_base/constants'
import { normalizeItemsListData } from '~~/utils/normalizers'
import { serializeFilter } from '~~/utils/serializators'
import {
  defaultJewelryListQuery,
  defaultPageSize
} from '~~/utils/definitions/defaults'

function getCategories(listingType) {
  if (listingType === 'ringsList') return ['Ring']
  if (listingType === 'braceletsList') return ['Bracelet']
  if (listingType === 'broochesList') return ['Brooch']
  if (listingType === 'cufflinksList') return ['Cufflink']
  if (listingType === 'earringsList') return ['Earring']
  return ['Necklace', 'Pendant']
}

export default {
  ...cloneDeep(actions),

  async getPage(
    { commit, rootState, rootGetters, state, getters, dispatch },
    params
  ) {
    if (await dispatch('skipFetch', params)) return {}

    commit(ITEMS_LIST.SET_LOADING, true)
    commit(ITEMS_LIST.ADD_TO_LOADING_LIST, params.pageNumber)
    const query = serializeFilter(
      state._namespace,
      rootState.filters,
      params,
      rootGetters
    )

    const pageQuery = cloneDeep({
      ...cloneDeep(defaultJewelryListQuery),
      query,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize || defaultPageSize
    })

    if (params.preset) pageQuery.query.preset = params.preset

    // For specific origin listing pages
    if (params.origin) pageQuery.query.origins[0] = params.origin
    // For specific design listing pages
    if (params.style) pageQuery.query.styles[0] = params.style

    if (params.preset !== 3) {
      pageQuery.query.categories = getCategories(state._namespace)
    }

    const listData = await this.$api.listings.fetchJewelryList(pageQuery)

    if (params.beforeAddItemsCallback) params.beforeAddItemsCallback()

    const normalizedData = normalizeItemsListData(listData, params.pageNumber)

    if (params.force) commit(ITEMS_LIST.DROP_ITEMS_LIST)
    commit(ITEMS_LIST.GET_ITEMS_LIST, normalizedData)

    if (params.afterAddItemsCallback) params.afterAddItemsCallback()

    commit(ITEMS_LIST.SET_LOADING, false)
    commit(ITEMS_LIST.REMOVE_FROM_LOADING_LIST, params.pageNumber)

    return normalizedData
  }
}
