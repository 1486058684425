import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import flatten from 'lodash/flatten'
import { cookiesOpts } from '../../utils/definitions/defaults.js'
import { itemNameFormatterNoLocale } from '~~/utils/formatters'
import {
  serializeCustomItem,
  serializeCustomItemPrice,
  serializeCustomDmtoItemPrice,
  getCustomItem,
  getCustomItemWax,
  serializeRequestPreview,
  serializeCustomDesignsFilters
} from '~~/utils/serializators'
import { stonesByCategory, companyName } from '~~/utils/definitions/defaults'
import {
  normalizeSharedSelectedOptions,
  normalizeAvailableDesigns,
  normalizeFinalOptions,
  normalizeCustomDesignsFilters,
  normalizeCustomDesigns,
  normalizeCustomPrices
} from '~~/utils/normalizers'
import {
  getItemId,
  getPreferableRingSize,
  guid,
  getDataType
} from '~~/utils/utils'

export const CREATE_CUSTOM = {
  SET_DESIGNERS_ITEM: 'SET_DESIGNERS_ITEM',
  SET_CUSTOM_STONE: 'SET_CUSTOM_STONE',
  SET_CUSTOM_SETTING: 'SET_CUSTOM_SETTING',
  REMOVE_CUSTOM_STONE: 'REMOVE_CUSTOM_STONE',
  REMOVE_CUSTOM_SETTING: 'REMOVE_CUSTOM_SETTING',
  SET_FINAL_OPTIONS: 'SET_FINAL_OPTIONS',
  SET_FINAL_PRICE: 'SET_FINAL_PRICE',
  SET_SETTING_OPTIONS: 'SET_SETTING_OPTIONS',
  SET_SIDE_STONE_OPTIONS: 'SET_SIDE_STONE_OPTIONS',
  RESET_ITEM: 'RESET_ITEM',
  SET_SELECTED_OPTIONS: 'SET_SELECTED_OPTIONS',
  RESET_SELECTED_OPTIONS: 'RESET_SELECTED_OPTIONS',
  SET_START_FROM_STONE: 'SET_START_FROM_STONE',
  SET_CUSTOM_ITEM: 'SET_CUSTOM_ITEM',
  IS_DIRTY: 'IS_DIRTY',
  IS_ITEMS_MATCH: 'IS_ITEMS_MATCH',
  SET_AVAILABLE_DESIGNS: 'SET_AVAILABLE_DESIGNS',
  SET_AUTO_FETCH: 'SET_AUTO_FETCH',
  SET_ASK_DESIGNER_HOME: 'SET_ASK_DESIGNER_HOME',
  SET_ITEM_GUID: 'SET_ITEM_GUID',
  CONFIRM_RING_SIZE: 'CONFIRM_RING_SIZE',
  CONFIRM_RING_SIZE_RESET: 'CONFIRM_RING_SIZE_RESET',
  CUSTOM_DESIGNS_FILTERS: 'CUSTOM_DESIGNS_FILTERS',
  SET_CUSTOM_DESIGNS_FILTERS: 'SET_CUSTOM_DESIGNS_FILTERS',
  CLEAR_CUSTOM_DESIGNS_FILTERS: 'CLEAR_CUSTOM_DESIGNS_FILTERS',
  REMOVE_CUSTOM_DESIGNS_FILTER: 'REMOVE_CUSTOM_DESIGNS_FILTER',
  CUSTOM_DESIGNS: 'CUSTOM_DESIGNS',
  FLUSH_CUSTOM_DESIGNS: 'FLUSH_CUSTOM_DESIGNS',
  FETCHING_ITEM: 'FETCHING_ITEM',
  SET_ITEM_CATEGORY: 'SET_ITEM_CATEGORY',
  SET_EXCEEDED: 'SET_EXCEEDED'
}

const itemState = {
  ringSizeConfirmed: false,
  stateHash: null
}

export const state = () => ({
  fetchingItem: false,
  itemId: null,
  baseCustomDesignsItemId: null, // "Custom Designs Created With This Stone/Setting" feature. Here is ID of this Stone/Setting
  guid: null,
  isDirty: false, // true - if need to set default side stones
  category: 'Custom',
  customStone: {},
  customSetting: {},
  startFromStone: true,
  selectedOptions: {},
  finalPrice: {},
  sideStones: [],
  preview: {},
  // custom item state (hashes, selected rings and etc)
  itemState,
  dataType: null,
  changeStoneId: null,
  changeSettingId: null,
  settingMatch: true,
  isItemsMatch: true,
  availableDesigns: [],
  initialData: {},
  sourceCustomDesignsFilters: {},
  selectedCustomDesignsFilters: {},
  customDesigns: [],
  designer: {},
  askDesignerForm: {
    customerName: '',
    email: '',
    phone: '',
    message: '',
    pageLink: ''
  },
  itemTypeId: null,
  gemologistSuggestions: false,
  exceeded: false // backend limit for request preview
})

export const getters = {
  customStone: (store) => store.customStone,
  customSetting: (store) => store.customSetting,
  startFromStone: (store) => store.startFromStone,
  selectedOptions: (store) => store.selectedOptions,
  sideStones: (store) => store.sideStones,
  sideStonesGrades: (store) => (index, stoneTypeId) => {
    return (
      store.sideStones[index]?.grades?.filter(
        (o) => o.stoneTypeId === stoneTypeId
      ) || []
    )
  },
  sideStonesWeights: (store) => (index, gradeId, stoneTypeId) => {
    return (
      store.sideStones[index]?.weights?.filter(
        (o) => o.gradeId === gradeId && o.stoneTypeId === stoneTypeId
      ) || []
    )
  },
  finalPrice: (store) => store.finalPrice,
  itemId: (store) => store.itemId,
  countries: (store, getters, rootState) =>
    rootState.cart.metaData.countries || [],
  settingMatch: (store) => store.settingMatch,
  availableDesigns: (store) => store.availableDesigns,
  askDesignerForm: (store) => store.askDesignerForm,
  settingThumbnails: (store) => store.customSetting.thumbnails || [],
  previewThumbnails: (store) => store.preview?.thumbnails || [],
  ringSizeConfirmed: (store) => store.itemState.ringSizeConfirmed,
  isPreview: (store) => !isEmpty(store.preview),
  customItem: (store) => getCustomItem(cloneDeep(store)),
  customItemWax: (store, getters) => {
    return {
      ...getters.customItem,
      category: 'Wax'
    }
  },
  hasFiltersCustomDesigns: (state) => {
    const values = Object.values(state.selectedCustomDesignsFilters)
    return !!flatten(values).length
  }
}

export const actions = {
  fetchingItem({ commit }, state) {
    commit(CREATE_CUSTOM.FETCHING_ITEM, state)
  },

  async submitInquiryForm({ commit }, query) {
    await this.$api.customItem.inquiryCustomItem(query)
    // submit form
  },

  async fetchCustomStonesDesigns({ commit, rootState }, settingId) {
    const response = await this.$api.customItem.fetchCustomDesigns(
      serializeCustomDesignsFilters(rootState, settingId, 'stoneId')
    )
    commit(CREATE_CUSTOM.CUSTOM_DESIGNS, normalizeCustomDesigns(response))
  },

  async fetchCustomStonesDesignsFilters({ commit, state }, settingId) {
    if (state.baseCustomDesignsItemId === settingId) return
    const response = await this.$api.customItem.fetchCustomStoneDesignsFilters(
      settingId
    )
    commit(CREATE_CUSTOM.CUSTOM_DESIGNS_FILTERS, {
      filters: normalizeCustomDesignsFilters(response),
      itemId: settingId
    })
  },

  async fetchCustomSettingsDesigns({ commit, rootState }, settingId) {
    const response = await this.$api.customItem.fetchCustomDesigns(
      serializeCustomDesignsFilters(rootState, settingId, 'settingId')
    )
    commit(CREATE_CUSTOM.CUSTOM_DESIGNS, normalizeCustomDesigns(response))
  },

  async fetchCustomSettingsDesignsFilters({ commit, state }, settingId) {
    if (state.baseCustomDesignsItemId === settingId) return
    const response =
      await this.$api.customItem.fetchCustomSettingsDesignsFilters(settingId)
    commit(CREATE_CUSTOM.CUSTOM_DESIGNS_FILTERS, {
      filters: normalizeCustomDesignsFilters(response),
      itemId: settingId
    })
  },

  setCustomDesignsFilters({ commit }, { filterName, value }) {
    commit(CREATE_CUSTOM.SET_CUSTOM_DESIGNS_FILTERS, { filterName, value })
  },

  removeCustomDesignsFilter({ commit }, params) {
    commit(CREATE_CUSTOM.REMOVE_CUSTOM_DESIGNS_FILTER, params)
  },

  clearCustomDesignsFilters({ commit }) {
    commit(CREATE_CUSTOM.CLEAR_CUSTOM_DESIGNS_FILTERS)
  },

  clearCustomDesigns({ commit }) {
    commit(CREATE_CUSTOM.FLUSH_CUSTOM_DESIGNS)
  },

  async askDesignerSubmit({ commit, state }, data) {
    if (isEmpty(data)) return
    await this.$api.cart.askDesigner(data)
    commit(CREATE_CUSTOM.SET_ASK_DESIGNER_HOME, data)
  },

  confirmRingSize({ commit }) {
    commit(CREATE_CUSTOM.CONFIRM_RING_SIZE)
  },

  confirmRingSizeReset({ commit }) {
    commit(CREATE_CUSTOM.CONFIRM_RING_SIZE_RESET)
  },

  async submitRequestPreviewForm({ rootGetters, commit }, form) {
    const customItem = rootGetters['customItem/customItem']
    const response = await this.$api.cart.requestPreview(
      serializeRequestPreview({ form, customItem })
    )
    const paramExceeded = response.exceeded
    commit(CREATE_CUSTOM.SET_EXCEEDED, paramExceeded)
    this.$cookies.set('exceeded', paramExceeded, {
      path: '/',
      maxAge: 60 * 60 * 24 // 24 hours
    })
    const { previewRequestId, stoneId, settingId, metalTypeCode } = response
    // eslint-disable-next-line camelcase
    const event_label = [
      previewRequestId,
      stoneId,
      settingId,
      metalTypeCode
    ].join('-')
    const price = customItem.finalPrice.totalPrice
    Vue.$gtag.event('request_preview', {
      // eslint-disable-next-line camelcase
      event_label,
      currency: 'USD',
      value: price,
      items: [
        {
          id: customItem.id,
          name: itemNameFormatterNoLocale(customItem),
          category: customItem.category,
          brand: companyName[process.env.siteName],
          quantity: 1,
          price: String(price)
        }
      ]
    })
    return response
  },

  async getAvailableDesigns({ commit, getters }, route) {
    const settingId = getters.customSetting.id
    if (!settingId) {
      console.log('getAvailableDesigns action, settingId is undefined')
      console.log('current route is: ', route)
      return
    }
    const availableDesigns = await this.$api.customItem.fetchAvailableDesigns(
      settingId
    )
    commit(
      CREATE_CUSTOM.SET_AVAILABLE_DESIGNS,
      normalizeAvailableDesigns.call(this, availableDesigns)
    )
  },

  async fetchDesignerItem({ commit, dispatch, getters }, params) {
    const query = params || {
      ...serializeCustomItem({
        ...getters.customItem,
        preferableRingSizeId: getPreferableRingSize(this.$cookies)
      })
    }

    const response = await this.$api.dmto.fetchDesignerItem(query)
    commit(
      CREATE_CUSTOM.SET_FINAL_OPTIONS,
      normalizeFinalOptions({ ...response, dataType: 3 })
    )
    dispatch('setSelectedOptions')
  },

  async getFinalOptions({ commit, dispatch, getters }, params) {
    const query = params || {
      ...serializeCustomItem({
        ...getters.customItem,
        preferableRingSizeId: getPreferableRingSize(this.$cookies)
      })
    }

    try {
      const finalOptions = await this.$api.customItem.fetchFinalOptions(query)
      commit(
        CREATE_CUSTOM.SET_FINAL_OPTIONS,
        normalizeFinalOptions(finalOptions)
      )
      dispatch('setSelectedOptions')
    } catch (e) {
      console.log('getFinalOptions error: ', e)
      throw new Error(e)
    }
  },

  setSelectedOptions({ commit, state, getters }) {
    // START SET SELECTED OPTIONS
    const selectedOptions = getters.selectedOptions
    if (
      state.isDirty ||
      !selectedOptions.sideStoneOptions ||
      isEmpty(selectedOptions.sideStoneOptions)
    ) {
      // Set default side stones
      const sideStones = getters.sideStones

      const stonesParams = [] // Create default selection

      sideStones.forEach((stone, index) => {
        let grade = null
        let stoneType = null
        let weight = null

        const stoneTypeId = get(
          state,
          `initialData.sideStone[${index}].stoneTypeId`,
          null
        )
        const gradeId = get(
          state,
          `initialData.sideStone[${index}].gradeId`,
          null
        )
        const weightName = get(
          state,
          `initialData.sideStone[${index}].weight`,
          null
        ) // weight is decimal here
        if (gradeId) grade = stone.grades.find((g) => g.id === gradeId)
        if (stoneTypeId)
          stoneType = stone.stoneTypes.find((g) => g.id === stoneTypeId)
        if (weightName)
          weight = stone.weights.find((w) => Number(w.name) === weightName)

        grade = grade || stone.grades[0]
        stoneType = stoneType || stone.stoneTypes[0]
        weight = weight || getters.sideStonesWeights(index, grade.id)[0]

        stonesParams[index] = {
          position: stone.position,
          grade,
          stoneType,
          weight,
          clarity: stone.clarities[0] // always 0
        }
      })

      commit(CREATE_CUSTOM.SET_SETTING_OPTIONS, {
        sideStoneOptions: stonesParams
      })
    }

    const customSetting = getters.customSetting

    if (
      !selectedOptions.ringSize &&
      customSetting.sizes &&
      customSetting.category === 'Setting_Ring'
    ) {
      const ringSize =
        customSetting.sizes.find((s) => s.key === state.initialData.ringSize) ||
        customSetting.sizes[0]
      commit(CREATE_CUSTOM.SET_SETTING_OPTIONS, { ringSize }) // Set default ring size if not selected
    }

    if (customSetting.prongs) {
      const selectedProngId = get(selectedOptions, 'prong.id', null)
      const prong =
        customSetting.prongs.find(
          (p) => p.id === (selectedProngId || state.initialData.prongTypeId)
        ) || customSetting.prongs[0]
      commit(CREATE_CUSTOM.SET_SETTING_OPTIONS, { prong }) // Set default prong if not selected
    }

    if (!customSetting.prongs?.length) {
      commit(CREATE_CUSTOM.SET_SETTING_OPTIONS, { prong: null }) // For non prongs settings. Platinum metal have not prongs for example
    }

    const metalTypeIndex = customSetting.metalTypes.findIndex(
      (metal) => metal.key === customSetting.metalTypeCode
    )

    if (metalTypeIndex > -1)
      commit(CREATE_CUSTOM.SET_SETTING_OPTIONS, {
        metalTypeCode: customSetting.metalTypes[metalTypeIndex]
      }) // Set selected metal type code
    // STOP SET SELECTED OPTIONS
    commit(CREATE_CUSTOM.SET_SETTING_OPTIONS, {
      engraving: selectedOptions.engraving || ''
    })
    commit(CREATE_CUSTOM.IS_DIRTY, false)
  },

  async fetchPrice({ commit, state }, item = null) {
    let query = {}
    if (!item) {
      query = serializeCustomItemPrice(state)
    } else {
      query = serializeCustomItemPrice({
        customStone: item.customStone,
        customSetting: item.customSetting
      })
    }

    try {
      const finalPrice = await this.$api.customItem.fetchFinalPrice(query)
      commit(CREATE_CUSTOM.SET_FINAL_PRICE, finalPrice)
    } catch (e) {
      console.log('fetchPrice error: ', e)
      throw new Error(e)
    }
  },

  /**
   * Fetch custom item with minimum parameters for cart/favorites/compare lists
   * @param commit
   * @param state
   * @param item
   * @param selectedOptions
   */
  async fetchCustomItem({ commit, state, dispatch }, { item }) {
    dispatch('resetCustomItem')
    const query = serializeCustomItem(item)
    const finalOptions = await this.$api.customItem.fetchFinalOptions(query)
    commit(CREATE_CUSTOM.SET_FINAL_OPTIONS, normalizeFinalOptions(finalOptions))
    commit(CREATE_CUSTOM.SET_SELECTED_OPTIONS, item.selectedOptions)
    await dispatch('fetchPrice')
    commit(CREATE_CUSTOM.SET_ITEM_CATEGORY, item.category)
  },

  async fetchDmtoPrice({ commit, state }, item = null) {
    let query = {}
    if (!item) {
      query = serializeCustomDmtoItemPrice(state)
    } else {
      query = serializeCustomDmtoItemPrice({
        customStone: item.customStone,
        customSetting: item.customSetting
      })
    }
    const finalPrice = await this.$api.dmto.fetchFinalPrice(query)
    commit(CREATE_CUSTOM.SET_FINAL_PRICE, finalPrice)
  },

  async fetchItemsPrices({ commit }, items) {
    const prices = items.map((item) => {
      return serializeCustomItemPrice(item)
    })
    const finalPrices = await this.$api.customItem.itemsPrice(prices)
    return normalizeCustomPrices(finalPrices)
  },

  async checkSettingMatch({ commit, state, getters }, item) {
    if (getters.customItem.dataType === 3) {
      commit(CREATE_CUSTOM.IS_ITEMS_MATCH, true)
      return
    }

    let data = {}
    if (
      stonesByCategory.includes(item.category) &&
      !isEmpty(state.customSetting)
    ) {
      data = {
        settingId: state.customSetting.id,
        metalCode: state.customSetting.metalTypeCode,
        stoneId: item.id
      }
    }

    if (
      !stonesByCategory.includes(item.category) &&
      !isEmpty(state.customStone)
    ) {
      data = {
        settingId: item.id,
        metalCode: item.metalTypeCode,
        stoneId: state.customStone.id
      }
    }

    if (!isEmpty(data)) {
      const result = await this.$api.customItem.isStoneMatch(data)
      commit(CREATE_CUSTOM.IS_ITEMS_MATCH, result)
      return
    }

    commit(CREATE_CUSTOM.IS_ITEMS_MATCH, true)
  },

  async isStoneMatch({ commit }, data) {
    const result = await this.$api.customItem.isStoneMatch(data)
    commit(CREATE_CUSTOM.IS_ITEMS_MATCH, result)
    return result
  },

  async fetchSharedSelectedOptions({ commit }, { id }) {
    const sharedSelectedOptions = await this.$api.details.getProductLink(id)

    commit(
      CREATE_CUSTOM.SET_SETTING_OPTIONS,
      normalizeSharedSelectedOptions({ params: sharedSelectedOptions })
    )
  },

  // item - from serialized
  setCustomItem({ commit, dispatch }, item) {
    const product = cloneDeep(item)
    commit(CREATE_CUSTOM.SET_CUSTOM_ITEM, product)

    if (process.server) {
      dispatch('getFinalOptions')
      dispatch('fetchPrice')
    }
  },

  getCustomItem({ state }) {
    return getCustomItem(cloneDeep(state))
  },

  getCustomItemWax({ state }) {
    return getCustomItemWax(cloneDeep(state))
  },

  setItemGuid({ commit }, guid) {
    commit(CREATE_CUSTOM.SET_ITEM_GUID, guid)
  },

  resetCustomItem({ commit }) {
    commit(CREATE_CUSTOM.RESET_ITEM)
    commit(CREATE_CUSTOM.REMOVE_CUSTOM_STONE)
    commit(CREATE_CUSTOM.REMOVE_CUSTOM_SETTING)
  },

  resetSelectedOptions({ commit }) {
    commit(CREATE_CUSTOM.RESET_SELECTED_OPTIONS)
  },

  setSettingOptions({ commit, getters }, options) {
    commit(CREATE_CUSTOM.SET_SETTING_OPTIONS, options)
  },

  setSideStonesOptions({ commit, getters }, options) {
    commit(CREATE_CUSTOM.SET_SIDE_STONE_OPTIONS, options)
  },

  setStartFromStone({ commit }, val) {
    commit(CREATE_CUSTOM.SET_START_FROM_STONE, val)
  },

  setCustomStone({ commit, rootGetters, getters }, item) {
    commit(CREATE_CUSTOM.SET_CUSTOM_STONE, item)
    commit(CREATE_CUSTOM.SET_ITEM_GUID, guid())

    const indexCart = rootGetters['cart/items'].findIndex(
      (el) => el.customStone && getItemId(el.customStone) === getItemId(item)
    ) // try to find item in cart
    const indexWishlist = rootGetters['favorites/customItems'].findIndex(
      (el) => el.customStone && getItemId(el.customStone) === getItemId(item)
    ) // try to find item in wishlist
    if (indexCart === -1 && indexWishlist === -1) {
      // if item not found we need to fetch data from server
      commit(CREATE_CUSTOM.IS_DIRTY, true)
    }
  },

  setCustomSetting({ commit, rootGetters, getters }, item) {
    commit(CREATE_CUSTOM.SET_CUSTOM_SETTING, item)
    commit(CREATE_CUSTOM.SET_ITEM_GUID, guid())
    if (item.selectedOptions)
      commit(CREATE_CUSTOM.SET_SETTING_OPTIONS, item.selectedOptions)

    const itemsWithThisSettingInCart =
      rootGetters['cart/items'].filter(
        (el) =>
          el.customSetting && getItemId(el.customSetting) === getItemId(item)
      ) || [] // find all items with this setting in cart
    const inCart = itemsWithThisSettingInCart.length
      ? itemsWithThisSettingInCart.some(
          (el) => el.customStone.id === getters.customStone.id
        )
      : false

    const itemsWithThisSettingInWishlist =
      rootGetters['favorites/customItems'].filter(
        (el) =>
          el.customSetting && getItemId(el.customSetting) === getItemId(item)
      ) || [] // find all items with this setting in wishlist
    const inWishlist = itemsWithThisSettingInWishlist.length
      ? itemsWithThisSettingInWishlist.some(
          (el) => el.customStone.id === getters.customStone.id
        )
      : false
    if (!inCart && !inWishlist) {
      // if item not found in wishlist and cart need to fetch data from server
      commit(CREATE_CUSTOM.IS_DIRTY, true)
    }
  },

  removeCustomStone({ commit }) {
    commit(CREATE_CUSTOM.RESET_ITEM)
    commit(CREATE_CUSTOM.REMOVE_CUSTOM_STONE)
  },

  removeCustomSetting({ commit }) {
    commit(CREATE_CUSTOM.RESET_ITEM)
    commit(CREATE_CUSTOM.REMOVE_CUSTOM_SETTING)
  }
}

export const mutations = {
  [CREATE_CUSTOM.SET_ITEM_CATEGORY](state, category) {
    state.category = category
  },

  [CREATE_CUSTOM.FETCHING_ITEM](state, st) {
    state.fetchingItem = st
  },

  [CREATE_CUSTOM.CUSTOM_DESIGNS](state, designs) {
    state.customDesigns = designs
  },

  [CREATE_CUSTOM.FLUSH_CUSTOM_DESIGNS](state) {
    state.customDesigns = []
  },

  [CREATE_CUSTOM.REMOVE_CUSTOM_DESIGNS_FILTER](state, { filterName, value }) {
    if (state.selectedCustomDesignsFilters[filterName]) {
      if (/Range/.test(filterName)) {
        Vue.delete(state.selectedCustomDesignsFilters, filterName)
        return
      }
      const index = state.selectedCustomDesignsFilters[filterName].findIndex(
        (f) => f === value
      )
      if (index > -1) {
        state.selectedCustomDesignsFilters[filterName].splice(index, 1)
      }
    }
  },

  [CREATE_CUSTOM.CLEAR_CUSTOM_DESIGNS_FILTERS](state) {
    state.selectedCustomDesignsFilters = {}
  },

  [CREATE_CUSTOM.SET_CUSTOM_DESIGNS_FILTERS](state, { filterName, value }) {
    Vue.set(state.selectedCustomDesignsFilters, filterName, value)
  },

  [CREATE_CUSTOM.CUSTOM_DESIGNS_FILTERS](state, { filters, itemId }) {
    state.baseCustomDesignsItemId = itemId
    state.selectedCustomDesignsFilters = {}
    state.sourceCustomDesignsFilters = filters
  },

  [CREATE_CUSTOM.CONFIRM_RING_SIZE](state) {
    if (state.customSetting.category === 'Setting_Ring')
      state.itemState.ringSizeConfirmed = true
  },

  [CREATE_CUSTOM.CONFIRM_RING_SIZE_RESET](state) {
    state.itemState.ringSizeChanged = false
    state.itemState.ringSizeConfirmed = false
  },

  [CREATE_CUSTOM.SET_ASK_DESIGNER_HOME](state, data) {
    state.askDesignerForm = data
  },

  [CREATE_CUSTOM.SET_CUSTOM_STONE](
    state,
    item // there is a subscriber on this mutation. See layouts/default.vue
  ) {
    state.startFromStone = isEmpty(state.customSetting)
    state.customStone = item
    if (process.client) {
      sessionStorage.setItem('customStone', JSON.stringify(item))
      if (item.id) {
        this.$cookies.set('customStone', item.id, cookiesOpts)
      }
    }
  },

  [CREATE_CUSTOM.SET_CUSTOM_SETTING](
    state,
    item // there is a subscriber on this mutation. See layouts/default.vue
  ) {
    state.startFromStone = !isEmpty(state.customStone)
    state.customSetting = item
    if (process.client) {
      sessionStorage.setItem('customSetting', JSON.stringify(item))
      if (item.id) {
        this.$cookies.set('customSetting', item.id, cookiesOpts)
      }
    }
  },

  [CREATE_CUSTOM.SET_AVAILABLE_DESIGNS](state, designs) {
    state.availableDesigns = designs
  },

  [CREATE_CUSTOM.SET_SELECTED_OPTIONS](state, selectedOptions) {
    state.selectedOptions = selectedOptions
  },

  [CREATE_CUSTOM.RESET_SELECTED_OPTIONS](state) {
    state.selectedOptions = {}
  },

  [CREATE_CUSTOM.RESET_ITEM](state) {
    state.category = 'Custom'
    state.itemId = null
    state.guid = null
    state.selectedOptions = {}
    state.finalPrice = {}
    state.itemState = itemState
  },

  [CREATE_CUSTOM.IS_ITEMS_MATCH](state, result) {
    state.isItemsMatch = result
  },

  [CREATE_CUSTOM.SET_START_FROM_STONE](state, val) {
    state.startFromStone = val
  },

  [CREATE_CUSTOM.IS_DIRTY](state, val) {
    state.isDirty = val
  },

  [CREATE_CUSTOM.REMOVE_CUSTOM_STONE](state) {
    state.customStone = {}
    if (process.client) {
      sessionStorage.removeItem('customStone')
      this.$cookies.remove('customStone')
    }
  },

  [CREATE_CUSTOM.REMOVE_CUSTOM_SETTING](state) {
    state.customSetting = {}
    if (process.client) {
      sessionStorage.removeItem('customSetting')
      this.$cookies.remove('customSetting')
    }
  },

  [CREATE_CUSTOM.SET_ITEM_GUID](state, guid) {
    state.guid = guid
  },

  [CREATE_CUSTOM.SET_FINAL_OPTIONS](state, options) {
    state.itemTypeId = options.itemTypeId
    state.guid = state.guid || guid()
    state.dataType = getDataType(options)
    // state.dataType = options.dataType || 1 // 2 - MTO item, 3 - DMTO item
    state.initialData = options.initialData || {}
    state.designer = options.designer || {}
    state.preview = options.previewRequest || {}
    if (isEmpty(state.selectedOptions) && state.customSetting.selectedOptions)
      state.selectedOptions = state.customSetting.selectedOptions // save previous selected options
    state.customStone = options.pair || options.stone
    state.customSetting = options.setting
    state.gemologistSuggestions = options.gemologistSuggestions
    if (!isEqual(state.sideStones, options.sideStoneOptions))
      state.sideStones = options.sideStoneOptions
  },

  [CREATE_CUSTOM.SET_CUSTOM_ITEM](
    state,
    {
      id,
      guid,
      customStone,
      customSetting,
      selectedOptions,
      preview,
      finalPrice,
      sideStoneOptions
    }
  ) {
    state.category = 'Custom'
    state.itemId = id
    state.guid = guid
    state.customStone = customStone
    state.customSetting = customSetting
    state.selectedOptions = selectedOptions
    state.preview = preview || {}
    state.finalPrice = finalPrice
    state.sideStones = sideStoneOptions || []
  },

  [CREATE_CUSTOM.SET_FINAL_PRICE](state, price) {
    state.itemId = getItemId({
      customStone: state.customStone,
      customSetting: state.customSetting
    })
    state.finalPrice = price
  },

  [CREATE_CUSTOM.SET_SETTING_OPTIONS](state, options) {
    const keys = Object.keys(options)
    keys.forEach((key) => {
      Vue.set(state.selectedOptions, key, options[key])
      if (key === 'metalTypeCode') {
        Vue.set(state.customSetting, key, options[key].key)
        state.itemId = getItemId({
          customStone: state.customStone,
          customSetting: state.customSetting
        })
      }
    })
  },

  [CREATE_CUSTOM.SET_SIDE_STONE_OPTIONS](state, { index, value, type }) {
    if (!state.selectedOptions.sideStoneOptions)
      Vue.set(state.selectedOptions, 'sideStoneOptions', [])
    Vue.set(state.selectedOptions.sideStoneOptions[index], type, value)
  },

  [CREATE_CUSTOM.SET_EXCEEDED](state, value) {
    state.exceeded = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
